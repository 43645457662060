import Vue from 'vue';
import Vuex from 'vuex';

// extra modules
import app from "./modules/app";
import orders from "./modules/orders";
import planner from "@/store/modules/planner";
import snelstart from "./modules/snelstart";
import user from "./modules/user";
// import bestelling from "@/store/modules/bestelling";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    // bestelling,
    app,
    orders,
    planner,
    snelstart,
    user,
  },
  // state: {
  // },
  // mutations: {
  // },
  // actions: {
  // },

  // Strict mode gaat uit in production!
  strict: process.env.NODE_ENV !== 'production'
})
