<template>
  <v-navigation-drawer
      v-model="drawer"
      app
      clipped
  >
    <!--      expand-on-hover-->
    <v-list
        id="drawer-main"
        dense
        nav
    >

      <!--      Module naam-->
      <v-list-item
          id="bedrijf-naam"
          v-if="GET_IS_INGELOGD && comp_getKlantSubtitel"
      >
        <v-list-item-content>
          <v-list-item-title
              class="title pb-2"
          >{{ comp_getKlantSubtitel }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
          v-if="GET_IS_INGELOGD && deepGet(this.GET_CURRENT_PROFIEL, 'userprofile.profile_image', false)"
      >
        <v-img
            :src="deepGet(this.GET_CURRENT_PROFIEL, 'userprofile.profile_image')"
            :alt="GET_CURRENT_PROFIEL.username"
            width="100%"
            class="rounded my-2"
        ></v-img>
      </v-list-item>
      <!--      <v-divider></v-divider>-->

      <!--    Module kiezen   -->
      <!--      <v-list-item-->
      <!--          id="modules-naam"-->
      <!--          v-if="GET_IS_INGELOGD"-->
      <!--      >-->
      <!--        <v-list-item-content>-->
      <!--          <v-select-->
      <!--              class="mr-1"-->
      <!--              :items="GET_MODULES_LIST"-->
      <!--              item-text="omschrijving"-->
      <!--              item-value="id"-->
      <!--              label="Kies module"-->
      <!--              prepend-inner-icon="mdi-apps-box"-->
      <!--              solo-->
      <!--              dense-->
      <!--          ></v-select>-->
      <!--        </v-list-item-content>-->
      <!--      </v-list-item>-->

      <!--          Actie kiezen    -->

      <v-list-item
          v-if="GET_IS_INGELOGD && GET_DEV_MESSAGES && GET_CURRENT_OPERATORS.length > 0"
          id="operators-lijst"
      >
        <v-list-item-content>
          <v-select
              class="mr-1 caption"
              :items="GET_CURRENT_OPERATORS"
              :value="GET_CURRENT_OPERATORS[0].id"
              item-text="naam"
              item-value="id"
              label="Kies operator"
              prepend-inner-icon="mdi-cog-box"
              solo
              dense
          >
<!--        <template slot="selection" slot-scope="{ item }">-->
            <template v-slot:selection="{ item }">
              <!-- HTML that describe how select should render selected items -->
              {{ item.naam }}
            </template>

            <template v-slot:item="{ item }">
              <b>{{ item.id }}</b>: {{ item.naam }}
            </template>
          </v-select>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="GET_IS_INGELOGD"></v-divider>

      <!--    Zij Menu, nu van Router    -->

      <v-list-item
          id="router-menu"
          v-for="item in GET_ROUTES"
          :key="item.path"
          link
          :to="item.path"
          :disabled="item.meta.disabled"
          :color="item.meta.color"
      >
        <v-list-item-action>
          <v-icon>mdi-{{ item.meta.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!--      <v-list-item>-->
      <!--        <v-btn-->
      <!--            @click="settingDialog = true"-->
      <!--        >Settings</v-btn>-->
      <!--      </v-list-item>-->

    </v-list>

    <template
        v-slot:append
        id="bottom-block"
    >
      <div class="pa-2">
        <v-card
            flat
        >
          <!--            class="text-caption mx-0 my-2"-->

          <!--          <v-divider-->
          <!--              v-if="GET_IS_INGELOGD"-->
          <!--          ></v-divider>-->

          <v-card-actions
              v-if="GET_IS_STAFFUSER"
          >

            <v-spacer></v-spacer>

            <!--        Settings-->
            <v-badge
                v-model="GET_TIPS"
                content="Globaal instellingen"
                color="accent"
                offset-x="9em"
            >
              <v-btn
                  @click="settingsGlobalDialogModel = true"
                  id="global-instellingen-btn"
                  class=""
                  :small="GET_GUI_COMPACT"
                  icon
                  :disabled="settingsGlobalDialogModel"
                  color="accent"
              >
                <v-icon
                    :small="GET_GUI_COMPACT"
                >mdi-cog
                </v-icon>
              </v-btn>
            </v-badge>

          </v-card-actions>

          <v-btn
              id="logout"
              v-if="GET_IS_INGELOGD"
              block
              class="error"
              @click="$store.dispatch('user/USER_LOGOUT')"
          >
            Uitloggen
            <v-icon class="mx-4">mdi-exit-run</v-icon>
          </v-btn>
        </v-card>
      </div>
    </template>

    <!--      Instellingen  -->
    <global-settings v-model="settingsGlobalDialogModel"/>

  </v-navigation-drawer>
</template>

<script>

import {mapGetters} from "vuex";
import common from "../utils/common";
import GlobalSettings from "@/components/GlobalSettings";

export default {
  name: 'app-drawer',
  components: {GlobalSettings},
  // props: {
  // },
  data() {
    return {
      settingsGlobalDialogModel: false,
    }
  }, // data

  /**

   888b     d888 8888888888 88888888888 888    888  .d88888b.  8888888b.   .d8888b.
   8888b   d8888 888            888     888    888 d88P" "Y88b 888  "Y88b d88P  Y88b
   88888b.d88888 888            888     888    888 888     888 888    888 Y88b.
   888Y88888P888 8888888        888     8888888888 888     888 888    888  "Y888b.
   888 Y888P 888 888            888     888    888 888     888 888    888     "Y88b.
   888  Y8P  888 888            888     888    888 888     888 888    888       "888
   888   "   888 888            888     888    888 Y88b. .d88P 888  .d88P Y88b  d88P
   888       888 8888888888     888     888    888  "Y88888P"  8888888P"   "Y8888P"

   */
  methods: {
    addLogBook(text, level, titel, popUp = false) {
      common.fn.addMessage2Drawer(text, level, titel, 'AppDrawer', popUp)
      // this.$store.dispatch(
      //     "app/ADD_LOG_MESSAGE",
      //     new common.ErrorMessage(
      //         text,
      //         level,
      //         titel,
      //         `Login`,
      //     )
      // )
    },

    deepGet(obj, key, def=null) {
      return common.fn.deepGet(obj, key, def)
    }
  }, // methods

  /**

    .d8888b.   .d88888b.  888b     d888 8888888b.  888     888 88888888888 8888888888 8888888b.
   d88P  Y88b d88P" "Y88b 8888b   d8888 888   Y88b 888     888     888     888        888  "Y88b
   888    888 888     888 88888b.d88888 888    888 888     888     888     888        888    888
   888        888     888 888Y88888P888 888   d88P 888     888     888     8888888    888    888
   888        888     888 888 Y888P 888 8888888P"  888     888     888     888        888    888
   888    888 888     888 888  Y8P  888 888        888     888     888     888        888    888
   Y88b  d88P Y88b. .d88P 888   "   888 888        Y88b. .d88P     888     888        888  .d88P
    "Y8888P"   "Y88888P"  888       888 888         "Y88888P"      888     8888888888 8888888P"

   */

  computed: {
    ...mapGetters('app', [
      'GET_DRAWER_STATE',
      'GET_ROOT_API_URI',
      'GET_DEV_MESSAGES',
      'GET_TIPS',
      'GET_GUI_COMPACT',
      // 'GET_MENU_OLD',
    ]),

    ...mapGetters('user', [
      'GET_IS_INGELOGD',
      'GET_PATH_SET',
      'GET_IS_SUPERUSER',
      'GET_IS_STAFFUSER',
      'GET_MODULES_LIST',
      'GET_OPERATORS_LIST',
      'GET_KLANTGEGEVENS_LIST',
      'GET_CURRENT_PROFIEL',
      'GET_CURRENT_SNELSTART',
      'GET_CURRENT_OPERATORS',
      'GET_CURRENT_BEDRIJF_NAAM',
    ]),

    comp_getKlantSubtitel() {
      return common.fn.deepGet(this.GET_CURRENT_PROFIEL, 'userprofile.front_config.subtitel', '')
    },

    GET_ROUTES: function () {
      let routes
      console.log('$router.options', this.$router.options.routes)

      // Ingelogd
      if (this.GET_IS_INGELOGD) {
        console.log('- Ingelogd')
        // Superuser -> mag alles
        if (this.GET_IS_SUPERUSER) {
          console.log('- Superuser')
          routes = this.$router.options.routes
            .filter(el => el.meta.inlog === undefined || el.meta.inlog === true)
        } else {
          if (this.GET_IS_STAFFUSER) {
            console.log('- Staff')
          }
          console.log('Path set', this.GET_PATH_SET)

          routes = this.$router.options.routes
            .filter(
                el => this.GET_PATH_SET.has(el.path) &&
                    (el.meta.staff === undefined || el.meta.staff === this.GET_IS_STAFFUSER)
            )
        }
      } else {
        // Niet ingelogd
        routes = this.$router.options.routes
          .filter(el => el.meta.inlog === undefined || el.meta.inlog === false)
      }

      console.log('$router filtered', routes)
      return routes
    },

    drawer: {
      get() {
        // console.log("drawer.get => ", !!this.GET_DRAWER)
        return this.GET_DRAWER_STATE
      },
      set(val) {
        // console.log("drawer.set => ", !!val)
        this.$store.commit('app/SET_DRAWER', val)
      }
    }
  }, // computed

  // APP WATCHERS
  watch: {
    // GET_CURRENT_SNELSTART(administratie) {
    //
    //   if (this.GET_IS_INGELOGD && this.GET_CURRENT_SNELSTART) {
    //     this.addLogBook(administratie.naam, common.errorLevels.Info, 'Selectie van administratie')

    // Laad klantgegevens
    //   this.$store.dispatch("user/REFRESH_TOKEN").then(() => {
    //
    //     this.$store.dispatch("user/READ_KLANTGEGEVENS_LIST")
    //     this.$store.dispatch("user/READ_MODULES_LIST")
    //     this.$store.dispatch("user/READ_OPERATORS_LIST")
    //
    //     // this.$store.dispatch("user/READ_PROCESS_LIST")
    // })

    // }

    // }
  }, // watch
}
</script>
