import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{attrs:{"id":"portal"}},[_c('app-drawer'),_c('app-top-bar'),_c('app-error-drawer'),_c(VMain,{class:{'blur-content': _vm.GET_LOCK }},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1),_c(VSnackbar,{attrs:{"vertical":true,"timeout":_vm.snack.timeout,"color":_vm.snack.color,"top":_vm.snack.pos.v === 'top',"bottom":_vm.snack.pos.v === 'bottom',"right":_vm.snack.pos.h === 'right',"left":_vm.snack.pos.h === 'left'},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack.on = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snack.on),callback:function ($$v) {_vm.$set(_vm.snack, "on", $$v)},expression:"snack.on"}},[_c('h3',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.snack.titel))]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.multiline2HTML(_vm.snack.text))}})]),_c('app-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }