<template
    v-if="GET_IS_INGELOGD"
>
  <v-select
          v-if="GET_SNELSTART_LIST.length > 1"
          v-model="selectedSnelStart"
          class="mx-1 mt-7 shrink"
          :items="GET_SNELSTART_LIST"
          item-value="id"
          label="Kiez administratie"
          prepend-inner-icon="mdi-star"
          solo
          dense
  >
<!--    <template slot="selection" slot-scope="data">-->
    <template v-slot:selection="{ item }">
      <!-- HTML that describe how select should render selected items -->
      <v-icon
          v-if="item.actief_api"
          color="accent"
          class="mr-2"
      >mdi-pipe</v-icon>
      <v-icon
          v-else
          class="mr-2"
      >mdi-pipe-disconnected</v-icon>
      <span
          class="hidden-sm-and-down"
      >{{ item.naam }}</span>
    </template>

    <template v-slot:item="{ item }">
      <!-- HTML that describe how select should render items when the select is open -->
      <v-icon
          v-if="item.actief_api"
          color="accent"
          class="mr-2"
      >mdi-pipe</v-icon>
      <v-icon
          v-else
          class="mr-2"
      >mdi-pipe-disconnected</v-icon>
      {{ item.naam }}
    </template>

    <template v-slot:prepend-inner>
      <v-fade-transition leave-absolute>
        <v-progress-circular
            v-if="false"
            size="24"
            color="info"
            indeterminate
        ></v-progress-circular>
        <img
            v-else
            width="24"
            height="24"
            :src="require('@/assets/snelstart.svg')"
            alt="SnelStart"
        >
      </v-fade-transition>
    </template>

  </v-select>

</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "DropDownSnelStart",

    data: () => ({

    }),

    computed: {
      ...mapGetters('user', [
          'GET_SNELSTART_LIST',
          'GET_SELECTED_ADMINISTRATIE_ID',
          'GET_IS_INGELOGD'
      ]),

      selectedSnelStart: {
        get () {
          console.log("Getter: selectedSnelStart => ", this.GET_SELECTED_ADMINISTRATIE_ID)
          return this.GET_SELECTED_ADMINISTRATIE_ID
        },
        set (val) {
          // console.log("drawer.set => ", !!val)
          console.log("Setter: selectedSnelStart =>", val)
          this.$store.dispatch("user/SELECT_ADMINISTRATIE_FROM_ID", val, {root: true})
        }
      },
    }, // COMPUTED

    methods: {
      // ...mapActions( 'user', ['SELECT_ADMINISTRATIE']),

    }, // METHODS
  }
</script>

<style scoped>

</style>
