import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
// import underscore from 'vue-underscore'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import store from './store'
import router from './router'
import axios from 'axios'
import vue_moment from "vue-moment";

// require('moment/locale/nl')

import VueSignaturePad from 'vue-signature-pad';

// Print module
import VueHtmlToPaper from 'vue-html-to-paper';

Vue.config.productionTip = false;

// Datum bewerking module Moment.js
Vue.use(vue_moment);
// Lodash: https://lodash.com/
Vue.use(VueLodash, { name: 'custom' , lodash: lodash });
// Signature pad
Vue.use(VueSignaturePad);
// Print Window
Vue.use(VueHtmlToPaper);

export const mainApp = new Vue({
  vuetify,
  store,
  router,
  axios,
  render: h => h(App)
}).$mount('#app');
