import Vue from 'vue';
// import Vuetify, { VTextField } from 'vuetify/lib';
import Vuetify from 'vuetify/lib';

// import sfIcon from '../assets/logo.svg';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary:    "#2087c8",  // Dkg
        secondary:  "#1ead64",  // dkG
        accent:     "#4f9b98",  // dKg
        error:      "#f44336",
        warning:    "#ffc107",
        info:       "#00bcd4",
        success:    "#14c32b",
        extra:      "#ff5dd7",
      },
      dark: {
        primary:    "#0F4363",  // _D_kg
        secondary:  "#0E5631",  // dk_G_
        accent:     "#274D4B",  // d_K_g
        error:      "#79211A",
        warning:    "#7F6003",
        info:       "#005D69",
        success:    "#096115",
        extra:      "#bc459c",
      },
      coffee: {
        primary:    "#5B5F58",  // _D_kg
        secondary:  "#5B6C35",  // dk_G_
        accent:     "#6C6647",  // d_K_g
        error:      "#A54725",
        warning:    "#A97315",
        info:       "#50715C",
        success:    "#577421",
        extra:      "#7f2f66",
      }
    },
  }, // theme
  // icons: {
  //   iconfont: 'dkg',
  //   values: {
  //     dkgSvg: sfIcon,
  //   }
  // }, // icons
  // components: {
  //   VTextField
  // },
});
