<template>
  <v-app
      id="portal"
  >
    <app-drawer/>
    <app-top-bar/>
    <app-error-drawer />

    <v-main :class="{'blur-content': GET_LOCK }">
      <transition
          name="fade"
          mode="out-in"
      >
        <router-view ></router-view>
      </transition>
    </v-main>

    <v-snackbar
        v-model="snack.on"
        :vertical="true"
        :timeout="snack.timeout"
        :color="snack.color"
        :top="snack.pos.v === 'top'"
        :bottom="snack.pos.v === 'bottom'"
        :right="snack.pos.h === 'right'"
        :left="snack.pos.h === 'left'"
    >
      <h3 class="mb-2">{{ snack.titel }}</h3>

      <span v-html="multiline2HTML(snack.text)"></span>

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snack.on = false"
        >Close</v-btn>
      </template>
    </v-snackbar>
    <app-footer/>
  </v-app>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import AppFooter from "@/components/AppFooter";
  import AppDrawer from "@/components/AppDrawer";
  import AppTopBar from "@/components/AppTopBar";
  import AppErrorDrawer from "@/components/AppErrorDrawer";
  import common from "@/utils/common";

  export default {
    components: {
      AppDrawer,
      AppFooter,
      AppTopBar,
      AppErrorDrawer,
    },
    props: {
      source: String,
    },

    data: () => ({
      // Snack (popup info block)
      snack: {
        on: false,
        color: '',
        titel: 'Title',
        text: 'Message',
        timeout: 7000,
        pos: {
          v: 'top', // 'top' or 'bottom'
          h: '',    // 'left', 'right' or '' for center
        },
      },
    }),

    methods: {
      ...mapActions({
        TOGGLE_DRAWER: "app/TOGGLE_DRAWER"
      }),

      showMessageError(text, title) {
        this.showMessage(text, title, 'error')
      },

      showMessage(text, title, color = undefined, posV = 'bottom', posH = '') {
        this.snack.text = text;
        this.snack.titel = title;
        this.snack.color = color;
        this.snack.pos.v = posV;
        this.snack.pos.h = posH;
        this.snack.on = true;
      },

      multiline2HTML(text) {
        return common.fn.multiline2HTML(text)
      },
    },

    created() {
      // this.$vuetify.theme.dark = false
    },

    mounted() {
      // console.log(this.GET_MENU);
      // console.log(this.$store.getters.GET_MENU);
      // console.log(this.$store['app'].getters.GET_MENU);
      // this.showMessage('Hello !!!')
    }, // MOUNTED

    computed: {
      ...mapGetters('app', [
          'GET_DRAWER_STATE',
          'GET_LOG_BOOK',
          'GET_ERROR_COLORS',
          'GET_ERROR_LEVELS',
          'GET_LOCK',
      ]),
    }, // COMPUTED

    watch: {
      GET_LOG_BOOK: function() {
        let lastMessage = this.GET_LOG_BOOK[0]

        // console.log('LastMessage', lastMessage)

        if (lastMessage !== undefined && (lastMessage.level >= common.errorLevels.Warning || lastMessage.popUp)) {
          if (this.GET_ERROR_COLORS[lastMessage.level]) {
            this.showMessage(
                lastMessage.text,
                lastMessage.titel,
                this.GET_ERROR_COLORS[lastMessage.level],
            )
          } else {
            this.showMessage(
                lastMessage.text,
                lastMessage.titel,
            )
          }
        }
      } //GET_LOG_BOOK
    } // WATCH
  }
</script>

<style lang="scss">
  .blur-content{
    filter: blur(5px);
    //animation: linear 1s;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
    .component-fade-enter-active, .component-fade-leave-active {
      transition: opacity 1s ease;
    }
    .shiftx-enter-active {
      animation: shift-in 2s;
    }
    .shiftx-leave-active {
      animation: shift-in 2s reverse;
    }
    @keyframes shift-in {
      0%   {transform:rotateX(0deg);}
      25%  {transform:rotateX(90deg);}
      50%  {transform:rotateX(120deg);}
      75%  {transform:rotateX(180deg);}
      100% {transform:rotateX(360deg);}
    }
    .fade-enter-active, .fade-leave-active {
      transition: opacity .4s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .4s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
