import axios from 'axios'
import api from '../../utils/api'
import common from '../../utils/common'
import router from '../../router'
import lodash from 'lodash'

// GETTERS:
// GET_IS_INGELOGD
// GET_INGELOGD_REMIND

const profielSchema = {
  id: -1,

  username: '',     // User login
  first_name: '',   // Gebruikers naam
  last_name: '',    // Achternaam
  email: '',        // E-mail

  is_superuser: false,
  is_staff: false,
  is_active: false,

  groups: [],

  userprofile: {},

  selectedSnelStart: { bedrijf_info: {} },   // Selected Administratie
  selectedOperator: {},    // Selected Operator/actie
  selectedModule: {},      // Selected Module
  selectedKlantgegeven: {},// Selected Klantgegevens

  // relatieList: [],
  snelstartList: [],
  operatorList: [],

  modulesList: [],
  processList: [],
  klantgegevensList: [],

  access: undefined,
  refresh: undefined,
}

const state = {
  // Lijst met profielen
  profiles: [],

  // Link naar activeProfile
  currentProfileIndex: -1,

  // Actief account
  id: -1,          // Django user ID

  username: '',    // User login
  first_name: '',  // Gebruikers naam
  last_name: '',   // Achternaam
  email: '',       // E-mail

  is_superuser: false,
  is_staff: false,

  selectedSnelStart: {
    bedrijf_info: {},
  },   // Selected Administratie

  selectedOperator: {},    // Selected Operator/actie
  selectedModule: {},      // Selected Module
  selectedKlantgegeven: {},// Selected Klantgegevens

  snelstartList: [],
  operatorList: [],

  modulesList: [],
  processList: [],
  klantgegevensList: [],

  // Token schema:
  // exp: 1594394874,     // Expire UNIX time
  // jti: "7800540007984bb1b1b0839n583c9b4b",  // Token key
  // jwt: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNTk0Mzk0ODc0LCJqdGkiOiI3ODAwNTQwMDA3NTg0YmIxYjFiMDgzOWQ1ODNjOWI0YiIsInVzZXJfaWQiOjF9.rVJG3zcqG4Z2_cPVQaMI1Rv2clTOSSsG1BDJnlDwyOc",
  // time: "Date Fri Jul 10 2020 17:27:54 GMT+0200 (Midden-Europese zomertijd)", // Expire time, JavaScript Date()
  // token_type: "access",// JWT soort
  // user_id: 1,          // Django user ID

  access: {               // Access token
    exp: 0,               // Expire UNIX time
    jti: '',              // Token key
    jwt: '',
    time: null,           // Expire time, JavaScript Date()
    token_type: "access", // JWT soort
    user_id: null,        // Django user ID
  },

  refresh: {              // Refresh token
    exp: 0,               // Expire UNIX time
    jti: '',              // Token key
    jwt: '',
    time: null,           // Expire time, JavaScript Date()
    token_type: "refresh",// JWT soort
    user_id: null,        // Django user ID
  },
}

/**

 .d8888b.  8888888888 88888888888 88888888888 8888888888 8888888b.   .d8888b.
 d88P  Y88b 888            888         888     888        888   Y88b d88P  Y88b
 888    888 888            888         888     888        888    888 Y88b.
 888        8888888        888         888     8888888    888   d88P  "Y888b.
 888  88888 888            888         888     888        8888888P"      "Y88b.
 888    888 888            888         888     888        888 T88b         "888
 Y88b  d88P 888            888         888     888        888  T88b  Y88b  d88P
 "Y8888P88 8888888888     888         888     8888888888 888   T88b  "Y8888P"

 */

const getters = {
  //
  // GET: (state, getters, rootState, rootGetters) => {}
  //

  // GET_CURRENT_PROFIEL: (state) => state.currentProfile,
  GET_CURRENT_PROFIEL: (state) => state.currentProfileIndex >= 0 ? state : [],

  // GET_OPERATORS_LIST: (state) => common.fn.deepGet(state, 'currentProfile.operatorList', []),
  GET_OPERATORS_LIST: (state) => state.operatorList,

  // GET_MODULES_LIST: (state) => common.fn.deepGet(state, 'currentProfile.modulesList', []),
  GET_MODULES_LIST: (state) => state.modulesList,

  // Geselecteerde module
  GET_CURRENT_MODULE: (state) => state.selectedModule,

  // GET_KLANTGEGEVENS_LIST: (state) => common.fn.deepGet(state, 'currentProfile.klantgegevensList', []),
  GET_KLANTGEGEVENS_LIST: (state) => state.klantgegevensList,

  // GET_PROCESS_LIST: (state) => common.fn.deepGet(state, 'currentProfile.processList', []),
  GET_PROCESS_LIST: (state) => state.processList,

  // GET_USER_NAME: (state) => common.fn.deepGet(state, 'currentProfile.userName', undefined),
  GET_USER_NAME: (state) => state.username,

  // Geselecteerde administratie
  GET_SELECTED_ADMINISTRATIE_ID: (state) => common.fn.deepGet(state, 'selectedSnelStart.id', undefined),

  // Geselecteerde operator/actie
  GET_OPERATOR: (state) => state.selectedOperator,

  // GET_ACCESS_JWT: (state) => common.fn.deepGet(state, 'currentProfile.access', {}),
  GET_ACCESS_JWT: (state) => state.access,

  GET_REFRESH_JWT: (state) => state.refresh,

  // Check, is user ingelogd
  GET_IS_INGELOGD: (state, getters) => {
    return getters.GET_INGELOGD_TOT_TIJD !== 0 && getters.GET_INGELOGD_TOT_TIJD > (new Date()).getTime()
  },

  // Is Super User?
  GET_IS_SUPERUSER: (state) => state.is_superuser,

  // Is Staff User?
  GET_IS_STAFFUSER: (state) => state.is_staff,

  // console.log(common.fn.deepGet(state, 'refresh.time', 0))
  GET_INGELOGD_TOT_TIJD: (state) => common.fn.deepGet(state, 'refresh.time', 0),

  // console.log(common.fn.deepGet(state, 'access.time', 0))
  GET_ACCESS_TOKEN_TOT_TIJD: (state) => common.fn.deepGet(state, 'access.time', 0),

  GET_ACCESS_HEADERS: (state) => ({
    Authorization: 'JWT ' +
      common.fn.deepGet(state, 'access.jwt', '')
  }),

  GET_AXIOS_CONFIG: (state, getters, rootState, rootGetters) => (
    {
      headers: getters.GET_ACCESS_HEADERS,
      timeout: rootGetters["app/GET_API_TIMEOUT"]
    }
  ),

  // GET_SNELSTART_LIST: state => common.fn.deepGet(state, 'currentProfile.snelstartList', []),
  GET_SNELSTART_LIST: state => state.snelstartList,

  // GET_CURRENT_SNELSTART: (state) => common.fn.deepGet(state, 'currentProfile.selectedSnelStart', {}),
  GET_CURRENT_SNELSTART: (state) => state.selectedSnelStart || {},

  GET_CURRENT_BEDRIJF_NAAM: (state, getters) => common.fn.deepGet(getters.GET_CURRENT_SNELSTART, 'bedrijf_info.bedrijfsnaam', ''),

  // GET PATH LIST
  GET_PATH_SET: (state, getters) => {
    let s = new Set()
    // Home mag altijd
    s.add('/')

    const operators = getters.GET_OPERATORS

    // console.log('Operators:', operators)
    for (let op of Object.values(operators)) {
      if (common.fn.deepGet(op, 'front.urls') && Array.isArray(op.front.urls)) {
        op.front.urls.forEach(u => s.add(u))
      }
    }
    // console.log('Path set:', s)
    return s
  },

  // GET List met Current operators
  GET_CURRENT_OPERATORS: (state, getters, rootState, rootGetters) => {
    console.log("Get_Current_Operator")
    const alle_operators = getters.GET_OPERATORS
    const current_url = rootGetters['app/GET_URL']
    const selSnelStart = state.selectedSnelStart

    let cur_ops = []
    console.log('Path', current_url)

    if (alle_operators && current_url && selSnelStart) {
      for (const operator of Object.values(alle_operators)) {
        console.log('Operator', operator)
        if (Array.isArray(operator?.front?.urls)                    // urls bestaat
          && operator.front.urls.includes(current_url)            // current uls in urls
          && operator.eigen_administratie.id === selSnelStart.id  // current administratie = operator eigen adm.
        ) {
          console.log('SnelStart:', selSnelStart)
          cur_ops.push(operator)
        }
      }
    }
    return cur_ops
  },

  // Get current operator
  GET_SELECTED_OPERATOR: (state) => {
    return state.selectedOperator
  },

  // GET Object met alle operators
  GET_OPERATORS: (state, getters) => {
    const operatorList = getters.GET_OPERATORS_LIST
    const klantgegevensList = getters.GET_KLANTGEGEVENS_LIST
    const modulesList = getters.GET_MODULES_LIST
    const snelStartList = getters.GET_SNELSTART_LIST

    let opDict = {}

    if (!!operatorList.length && !!klantgegevensList.length && !!modulesList.length && !!snelStartList.length) {
      // OPERATORS
      for (const currentOperator of operatorList) {
        // console.log("Operator item =", currentOperator)

        if (!currentOperator.actief) {
          continue
        }

        // Operator dict
        let item = {}

        // Bewaar in de lijst van alle operators
        opDict[currentOperator.id] = item

        // Kopie property
        item.id = currentOperator.id         // id
        item.naam = currentOperator.naam     // Operator naam
        item.config = currentOperator.config // Configuratie
        item.params = currentOperator.params // Parameters

        // Object.assign(item, currentOperator)

        if ('bron_administratie_id' in currentOperator) {
          const bronAdministratie = snelStartList.find(i => i.id === currentOperator.bron_administratie_id)
          if (bronAdministratie) {
            item.bron_administratie = bronAdministratie
          }
        }

        if ('klant_gegevens_id' in currentOperator) {
          const klantGegevens = klantgegevensList.find(i => i.id === currentOperator.klant_gegevens_id)
          if (klantGegevens) {
            item.klant_gegevens = klantGegevens

            if ('eigen_administratie_id' in klantGegevens) {
              const eigenAdministratie = snelStartList.find(i => i.id === klantGegevens.eigen_administratie_id)
              if (eigenAdministratie) {
                item.eigen_administratie = eigenAdministratie
              }
            }

            if ('module_id' in klantGegevens) {
              const module = modulesList.find(i => i.id === klantGegevens.module_id)
              if (module) {
                item.module = module

                if (module.config.front) {
                  item.front = module.config.front
                }
              }
            }
          } // klantgegevens
        } // if klant_gegevens_id bestaat
      } // for alle operators
    } // alle data zijn klaar?

    return opDict
  }
} // ⬆ GETTERS

/**
 d8888  .d8888b.  88888888888 8888888  .d88888b.  888b    888  .d8888b.
 d88888 d88P  Y88b     888       888   d88P" "Y88b 8888b   888 d88P  Y88b
 d88P888 888    888     888       888   888     888 88888b  888 Y88b.
 d88P 888 888            888       888   888     888 888Y88b 888  "Y888b.
 d88P  888 888            888       888   888     888 888 Y88b888     "Y88b.
 d88P   888 888    888     888       888   888     888 888  Y88888       "888
 d8888888888 Y88b  d88P     888       888   Y88b. .d88P 888   Y8888 Y88b  d88P
 d88P     888  "Y8888P"      888     8888888  "Y88888P"  888    Y888  "Y8888P"

 **/

// ASYNC
const actions = {

  //    d8b          d8b 888                                                      888          888
  //    Y8P          Y8P 888                                                      888          888
  //                     888                                                      888          888
  //    888 88888b.  888 888888           8888b.  88888b.  88888b.            .d88888  8888b.  888888  8888b.
  //    888 888 "88b 888 888                 "88b 888 "88b 888 "88b          d88" 888     "88b 888        "88b
  //    888 888  888 888 888             .d888888 888  888 888  888          888  888 .d888888 888    .d888888
  //    888 888  888 888 Y88b.           888  888 888 d88P 888 d88P          Y88b 888 888  888 Y88b.  888  888
  //    888 888  888 888  "Y888 88888888 "Y888888 88888P"  88888P"  88888888  "Y88888 "Y888888  "Y888 "Y888888
  //                                              888      888
  //                                              888      888
  //                                              888      888

  INIT_APP_DATA: async (context) => {
    // console.log("Action: user.INIT_APP_DATA")

    context.dispatch("READ_SNELSTART_LIST")
    .catch(reason => {
      common.fn.addMessage2Drawer(
        `SnelStart gegevens lezen lukt niet: ${reason}`,
        common.errorLevels.Error,
        'Init SnelStart gegevens',
        'User',
        true,
      )
    })
    .then(() => {
        // console.log("then: SS ID", context.state.selectedSnelStart.id)
        Promise.all([
          context.dispatch("READ_MODULES_LIST"),
          context.dispatch("READ_KLANTGEGEVENS_LIST"),
          context.dispatch("READ_OPERATORS_LIST"),
        ])
        .then(() => {
          // Alle App data is geladen
          // console.log("INIT_APP_DATA: Promise all Then")

          // const path_set = context.getters.GET_PATH_SET
          // if (path_set.length > 1) {
          //   router.push({path: path_set[1]})
          // } else {
          //   router.push({path: '/'})
          // }

          // const operators = context.getters.GET_OPERATORS
          // const opKeys = Object.keys(operators)
          const pathSet = context.getters.GET_PATH_SET
          const routes = router.getRoutes()
          console.log('Path Set:', pathSet)
          console.log('Routes:', routes)

          for (let route of routes) {
            if (route.path.length > 1) {
              if (pathSet.has(route.path)) {
                console.log("Try redirect to:", route.path)

                router.push(route.path)
                  .then(value => {
                    const curOp = context.getters.GET_CURRENT_OPERATORS
                    console.log("Redirect to", value)
                    console.log("Operators", curOp)
                    if (Array.isArray(curOp) && curOp.length > 0) {
                      context.commit("SET_SELECTED_OPERATOR", curOp[0])
                    }
                  })
                  .catch(e => { console.error(`Redirect error: ${e}`) })
                break
              }
            }
          }
          // console.log('Operators:', operators)
          //
          // let urls = []
          //
          // for (let opKey of opKeys) {
          //   console.log("op", opKey)
          //
          //   const opUrls = operators[opKey].front.urls
          //   if (Array.isArray(opUrls)) {
          //     urls = urls.concat(opUrls)
          //   }
          // }
          //
          // console.log("URLS", urls)

          // if (opKeys.length > 0) {
          //   const operator = operators[opKeys[0]]
          //
          //   console.log('Operator.front', operator.front)
          //
          //   if (operator?.front?.urls?.length > 0) {
          //     context.commit("SET_SELECTED_OPERATOR", operator)
          //     router.push({path: operator.front.urls[0]})
          //   }
          // }
        })
        .catch(e => {
          console.error("Errors:", e)
          common.fn.addMessage2Drawer(
            `App begin data lezen lukt niet: ${e}`,
            common.errorLevels.Error,
            'Begin gebruiker gegeven',
            'User',
            true,
          )
        })
      } // THEN
    )
    // console.log("after: SS ID", context.state.selectedSnelStart.id)
  },

//                                             888                   d8b
//                                             888                   Y8P
//                                             888
// 888  888 .d8888b   .d88b.  888d888          888  .d88b.   .d88b.  888 88888b.
// 888  888 88K      d8P  Y8b 888P"            888 d88""88b d88P"88b 888 888 "88b
// 888  888 "Y8888b. 88888888 888              888 888  888 888  888 888 888  888
// Y88b 888      X88 Y8b.     888              888 Y88..88P Y88b 888 888 888  888
//  "Y88888  88888P'  "Y8888  888     88888888 888  "Y88P"   "Y88888 888 888  888
//                                                               888
//                                                          Y8b d88P
//                                                           "Y88P"

  USER_LOGIN: async (context, {username, password}) => {
    // console.log("Action: user.USER_LOGIN")

    const uri = context.rootGetters["app/GET_CREATE_JWT_URI"]
    // console.log("URI =>", uri)

    // const profiel = Object.assign({username}, state.profiles.find((i) => i.userName === username))
    const profiel = {...profielSchema}
    profiel.username = username

    // Login met JWT via api
    await axios.post(uri, {username, password})
    .then(result => {
      // console.log("Result =>")
      // console.dir(result)

      profiel.access = common.fn.parseJwt(result.data.access)
      profiel.refresh = common.fn.parseJwt(result.data.refresh)

      // console.log("Profiel:")
      // console.dir(profiel)

      // Django user list
      const userList = api.getAPIRequestMultipage({
          uri: context.rootGetters["app/GET_AUTH_USERS_API_URI"],
          token: profiel.access.jwt
        }
      )
      .then(usersListResponse => {
        console.log("getMultiToken() => ", usersListResponse)

        let findUser = usersListResponse.data.find(el => el.id === profiel.access.user_id)
        console.log("findUser =>", findUser)

        if (findUser) {

          if (findUser.username === username) {
            console.log("profiel: ", profiel)

            // Add 'findUser' to 'profiel'
            Object.assign(profiel, findUser)

            // COMMIT
            context.commit("SAVE_PROFIEL", profiel)

            // APP INIT
            context.dispatch("INIT_APP_DATA")

          } else {
            // Iets niet goed met naam
            common.fn.addMessage2Drawer(
              `API heeft andere naam: ${findUser.username}, dan je inlog naam ${username}`,
              common.errorLevels.Error,
              'login',
              'User',
              true,
            )
            // throw new Error(`API heeft andere naam: ${findUser.userName}, dan je inlog naam ${username}`)
          }
        } else {
          // Niet gevonden
          common.fn.addMessage2Drawer(
            `Gebruiker naam: ${username} is niet gevonden in backend`,
            common.errorLevels.Error,
            'login',
            'User',
            true,
          )
          // throw new Error(`Gebruiker naam: ${username} is niet gevonden in backend`)
        }
      })
      .catch(usersListError => {
        console.dir(usersListError)
      })
      .finally(() => {
        // context.dispatch("READ_SNELSTART_LIST")
        //   .then(() => context.dispatch("READ_MODULES_LIST")
        //   )
        // ...
      })
      console.dir("userList =>", userList)

    })
    .catch(error => {
      //
      // config: {…}
      // fileName: "http://localhost:8080/js/chunk-vendors.js line 442 > eval"
      // isAxiosError: true
      // message: "Request failed with status code 401"
      // request: XMLHttpRequest
      // response: {…}
      //  config: {…}
      //  data: {…}
      //    detail: "No active account found with the given credentials"
      //  headers: {…}
      //  request: XMLHttpRequest
      //  status: 401
      //  statusText: "Unauthorized"
      //
      let error_message
      if (error?.response?.status === 401 && error.response?.data?.detail) {
        error_message = error.response.data.detail
      } else {
        error_message = error.message
      }
      common.fn.addMessage2Drawer(
        error_message,
        common.errorLevels.Error,
        'Inloggen',
        'User',
        true,
      )
      // console.error('user.js => USER_LOGIN', error)
      throw error
    })
    console.log('user.js => USER_LOGIN: End')
  },

//                                             888                                     888
//                                             888                                     888
//                                             888                                     888
// 888  888 .d8888b   .d88b.  888d888          888  .d88b.   .d88b.   .d88b.  888  888 888888
// 888  888 88K      d8P  Y8b 888P"            888 d88""88b d88P"88b d88""88b 888  888 888
// 888  888 "Y8888b. 88888888 888              888 888  888 888  888 888  888 888  888 888
// Y88b 888      X88 Y8b.     888              888 Y88..88P Y88b 888 Y88..88P Y88b 888 Y88b.
//  "Y88888  88888P'  "Y8888  888     88888888 888  "Y88P"   "Y88888  "Y88P"   "Y88888  "Y888
//                                                               888
//                                                          Y8b d88P
//                                                           "Y88P"

  USER_LOGOUT: context => {
    let profiel = context.getters.GET_CURRENT_PROFIEL
    // profiel.access = null
    // profiel.refresh = null
    context.dispatch("snelstart/CANCEL_LOAD", {}, {root: true}).then(r => {
      console.log("dispatch => snelstart/CANCEL_LOAD", r)
    })

    context.commit("LOGOUT_PROFIEL", profiel)

    router.push({name: 'Home'}).then(r => {
      console.log("router => push to home", r)
    })
  },

//                   .d888                           888               888             888
//                  d88P"                            888               888             888
//                  888                              888               888             888
// 888d888  .d88b.  888888 888d888  .d88b.  .d8888b  88888b.           888888  .d88b.  888  888  .d88b.  88888b.
// 888P"   d8P  Y8b 888    888P"   d8P  Y8b 88K      888 "88b          888    d88""88b 888 .88P d8P  Y8b 888 "88b
// 888     88888888 888    888     88888888 "Y8888b. 888  888          888    888  888 888888K  88888888 888  888
// 888     Y8b.     888    888     Y8b.          X88 888  888          Y88b.  Y88..88P 888 "88b Y8b.     888  888
// 888      "Y8888  888    888      "Y8888   88888P' 888  888 88888888  "Y888  "Y88P"  888  888  "Y8888  888  888

  REFRESH_TOKEN: async context => {
    console.log("Action: user.REFRESH_TOKEN")
    if (context.getters.GET_IS_INGELOGD) {
      // TODO: DeepCopy -> common., shadow copy (up level copy) -> new = Object.assign({}, source)
      // Doet niet met Date() objecten!!
      // const profiel = JSON.parse(JSON.stringify(context.getters.GET_CURRENT_PROFIEL))

      let profiel = Object.assign({}, context.getters.GET_CURRENT_PROFIEL)

      const refUri = context.rootGetters["app/GET_REFRESH_JWT_URI"]
      const tokenData = await api.getRefreshToken(refUri, profiel.refresh)

      if (tokenData === null) {
        const errText = "Token is nul, probeer opnieuw inloggen"
        console.log(errText)
        throw new Error("user/REFRESH_TOKEN: " + errText)
      } else {
        // console.log("\tNieuw token:")
        // console.dir(tokenData)

        // profiel.access = common.fn.parseJwt(tokenData.access)
        const access = common.fn.parseJwt(tokenData.access)

        // Commit
        // context.commit("SAVE_PROFIEL", profiel)
        context.commit("SET_ACCESS_TOKEN", access)
      }
    }
  },

//                   888                   888                           888               d8b
//                   888                   888                           888               Y8P
//                   888                   888                           888
// .d8888b   .d88b.  888  .d88b.   .d8888b 888888           8888b.   .d88888 88888b.d88b.  888 88888b.
// 88K      d8P  Y8b 888 d8P  Y8b d88P"    888                 "88b d88" 888 888 "888 "88b 888 888 "88b
// "Y8888b. 88888888 888 88888888 888      888             .d888888 888  888 888  888  888 888 888  888
//      X88 Y8b.     888 Y8b.     Y88b.    Y88b.           888  888 Y88b 888 888  888  888 888 888  888
//  88888P'  "Y8888  888  "Y8888   "Y8888P  "Y888 88888888 "Y888888  "Y88888 888  888  888 888 888  888 88888888
//
//
//  .d888                                         d8b      888
// d88P"                                          Y8P      888
// 888                                                     888
// 888888 888d888  .d88b.  88888b.d88b.           888  .d88888
// 888    888P"   d88""88b 888 "888 "88b          888 d88" 888
// 888    888     888  888 888  888  888          888 888  888
// 888    888     Y88..88P 888  888  888          888 Y88b 888
// 888    888      "Y88P"  888  888  888 88888888 888  "Y88888

  SELECT_ADMINISTRATIE_FROM_ID: async (context, id) => {
    const snelstartList = context.getters.GET_SNELSTART_LIST

    console.log("Action: user.SELECT_ADMINISTRATIE_FROM_ID", id, snelstartList)

    if (Array.isArray(snelstartList) && snelstartList.length > 0) {
      const findSnelStart = snelstartList.find(el => el.id === id) || snelstartList[0]
      context.commit("SET_SELECTED_ADMINISTRATIE", findSnelStart)
    }
  },

  READ_SNELSTART_LIST: async context => {
    const uri = context.rootGetters["app/GET_SNELSTART_URI_LIST"]

    console.log("Action: user.READ_SNELSTART_LIST, uri =>", uri)

    const response = await api.getAPIRequestMultipage({uri})
    if (response.status === 200) {
      await context.commit('SET_SNELSTART_LIST', response.data)
      // await context.dispatch('SELECT_ADMINISTRATIE_FROM_ID', -1)
    }
  },

  READ_PROCESS_LIST: async context => {
    const processUriList = context.rootGetters["app/GET_PROCESS_URI_LIST"];
    const params = {snelstart_id: context.getters.GET_SELECTED_ADMINISTRATIE_ID}
    console.log("Action: READ_PROCESS_LIST, uri =>", processUriList, params)
    // TODO: incremental read
    const response = await api.getAPIRequestMultipage({
      uri: processUriList,
      params,
      maxCount: 300
    })
    if (response.status === 200) {
      console.log("Response:", response.data)
      context.commit('SET_PROCESS_LIST', response.data)
    }
  },

  READ_MODULES_LIST: async context => {
    const modulesUriList = context.rootGetters["app/GET_MODULES_URI_LIST"];
    // const params = {snelstart_id: context.getters.GET_SELECTED_ADMINISTRATIE_ID}

    console.log(
      "Action: user.READ_MODULES_LIST, uri =>",
      modulesUriList,
      // params,
    )

    const response = await api.getAPIRequestMultipage({
      uri: modulesUriList,
      // params,
    })
    if (response.status === 200) {
      context.commit('SET_MODULES_LIST', response.data)
    }
  },

  READ_KLANTGEGEVENS_LIST: async context => {
    const klantgegevensUriList = context.rootGetters["app/GET_KLANTGEGEVENS_URI_LIST"];
    // const params = {eigen_administratie_id: context.getters.GET_SELECTED_ADMINISTRATIE_ID}

    const response = await api.getAPIRequestMultipage({
      uri: klantgegevensUriList,
      // params,
    })

    if (response.status === 200) {
      // PATCH klantgegevens van klantprofiel

      const modules = context.getters.GET_MODULES_LIST
      // console.log('MODULES lijst:')
      // console.dir(Object.assign({}, modules))

      // Klantgegevens van gebruiker profiel
      const overwriting = common.fn.deepGet(
        context.getters.GET_CURRENT_PROFIEL,
        'userprofile.front_config.overwriting',
        {}
      )

      // console.log('PROFIEL klantgegevens:')
      // console.dir(Object.assign({}, overwriting))

      // console.log('KLANTGEGEVENS config:')
      // console.dir(Object.assign({}, response.data))

      for (let ovItemName in overwriting) {
        // console.log('Ov item:', ovItemName)
        const module = modules.find(m => m.omschrijving === ovItemName)

        if (module) {
          let klantgegevens_idx = response.data.findIndex(k => k.module_id === module.id)

          if (klantgegevens_idx !== -1) {
            // PATCH
            // bron bestaat
            // console.log('defaultsDeep:')
            // console.dir(overwriting[ovItemName])
            // console.dir(response.data[klantgegevens_idx])

            response.data[klantgegevens_idx] = lodash.defaultsDeep(
              overwriting[ovItemName],
              response.data[klantgegevens_idx],
            )
            // console.dir(response.data[klantgegevens_idx])
            // response.data[klantgegevens_idx] = Object.assign(response.data[klantgegevens_idx], overwriting[ovItemName])
          } else {
            console.error(`Klantgegevens voor patch ${ovItemName} is niet gevonden`)
          }
        } else {
          console.error(`Module patch ${ovItemName} is niet gevonden`)
        }
      }
      // console.log('SET_KLANTGEGEVENS_LIST', response.data)
      context.commit('SET_KLANTGEGEVENS_LIST', response.data)
    }
  },

  READ_OPERATORS_LIST: async context => {
    const operatorsUriList = context.rootGetters["app/GET_OPERATORS_URI_LIST"];
    // const params = {bron_administratie_id: context.getters.GET_SELECTED_ADMINISTRATIE_ID}

    // console.log("Action: user.READ_KLANTGEGEVENS_LIST, uri =>", operatorsUriList, params)

    const response = await api.getAPIRequestMultipage({
      uri: operatorsUriList,
      // params,
    })
    if (response.status === 200) {
      context.commit('SET_OPERATORS_LIST', response.data)
    }
  },

} // ⬆ Action

/**

 888b     d888 888     888 88888888888        d8888 88888888888 8888888  .d88888b.  888b    888  .d8888b.
 8888b   d8888 888     888     888           d88888     888       888   d88P" "Y88b 8888b   888 d88P  Y88b
 88888b.d88888 888     888     888          d88P888     888       888   888     888 88888b  888 Y88b.
 888Y88888P888 888     888     888         d88P 888     888       888   888     888 888Y88b 888  "Y888b.
 888 Y888P 888 888     888     888        d88P  888     888       888   888     888 888 Y88b888     "Y88b.
 888  Y8P  888 888     888     888       d88P   888     888       888   888     888 888  Y88888       "888
 888   "   888 Y88b. .d88P     888      d8888888888     888       888   Y88b. .d88P 888   Y8888 Y88b  d88P
 888       888  "Y88888P"      888     d88P     888     888     8888888  "Y88888P"  888    Y888  "Y8888P"

 */

// ⬇ SYNC ❗ MUTATIONS
const mutations = {
  SAVE_PROFIEL: (state, data) => {
    console.log("Commit: user.SAVE_PROFIEL, data = ", data)

    const indexProfiel = state.profiles.findIndex(el => el.id === data.id)

    if (indexProfiel === -1) {
      // Nieuw profiel
      state.profiles.push(data)
    } else {
      // Bestaande profiel
      state.profiles[indexProfiel] = data
    }

    state.currentProfileIndex = state.profiles.findIndex(el => el === data)
    Object.assign(state, data)

    // console.log('Profiel nieuw state:', state)
  },

  SET_ACCESS_TOKEN: (state, data) => {
    state.access = data
  },

  SET_REFRESH_TOKEN: (state, data) => {
    state.refresh = data
  },

  LOGOUT_PROFIEL: (state, data) => {
    console.log("Commit: user.LOGOUT_PROFIEL", data)

    // if (data) {
    //   // data.access = null
    //   //data.refresh = null
    //   mutations.SET_REFRESH_TOKEN(state, null)
    //   mutations.SET_ACCESS_TOKEN(state, null)
    // }
    // if (data === state.currentProfile) {
    //   state.currentProfile = null
    // }

    // Test
    Object.assign(state, profielSchema)
  },

  SET_SELECTED_ADMINISTRATIE: (state, snelstart) => {
    console.log("Commit: SET_SELECTED_ADMINISTRATIE", snelstart)
    state.selectedSnelStart = snelstart
  },

  SET_SNELSTART_LIST: (state, data) => {
    console.log('Commit: SET_SNELSTART_LIST(data)', data)
    state.snelstartList = data
    state.selectedSnelStart = common.fn.getFirstOfNull(data)
  },

  SET_PROCESS_LIST: (state, data) => {
    state.processList = data
  },

  SET_MODULES_LIST: (state, data) => {
    state.modulesList = data
    // state.selectedModule = common.fn.getFirstOfNull(data)
    state.selectedModule = null
  },

  SET_SELECTED_MODULE: (state, data) => {
    state.selectedModule = data
  },

  SET_SELECTED_OPERATOR: (state, data) => {
    state.selectedOperator = data
  },

  SET_KLANTGEGEVENS_LIST: (state, data) => {
    state.klantgegevensList = data
    // state.selectedKlantgegeven = common.fn.getFirstOfNull(data)
    state.selectedKlantgegeven = null
  },

  SET_OPERATORS_LIST: (state, data) => {
    state.operatorList = data
    // state.selectedOperator = common.fn.getFirstOfNull(data)
    state.selectedOperator = null
  },

} // Mutations

//                                             888
//                                             888
//                                             888
//  .d88b.  888  888 88888b.   .d88b.  888d888 888888
// d8P  Y8b `Y8bd8P' 888 "88b d88""88b 888P"   888
// 88888888   X88K   888  888 888  888 888     888
// Y8b.     .d8""8b. 888 d88P Y88..88P 888     Y88b.
//  "Y8888  888  888 88888P"   "Y88P"  888      "Y888
//                   888
//                   888
//                   888

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
