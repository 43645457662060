<template>
  <div
      id="profile"
      class="text-center"
  >
    <v-menu
        v-model="profile"
        :close-on-content-click="false"
        :nudge-width="180"
        offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            class="mx-5"
        >
          <!--            <v-icon>mdi-dots-vertical</v-icon>-->
          <!--                  size="40"-->
          <v-avatar>
            <!--            <img v-if="GET_CURRENT_PROFIEL"-->
            <!--                 :src="GET_CURRENT_PROFIEL.userprofile.profile_image"-->
            <!--                 :alt="GET_CURRENT_PROFIEL.first_name + ' ' + GET_CURRENT_PROFIEL.last_name"-->
            <!--            >-->
            <v-icon>mdi-account</v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img
                  v-if="userProfile.userprofile"
                  :src="userProfile.userprofile.profile_image"
                  :alt="userProfile.username"
              >
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ userProfile.first_name }} {{ userProfile.last_name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ userProfile.username }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ userProfile.email }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon
                     :class="profileFav ? 'red--text' : ''"
                     @click="profileFav = !profileFav"
              >
                <v-icon>mdi-heart</v-icon>
              </v-btn>
            </v-list-item-action>
            <!--            <pre>{{ parseInfo2Tree(userProfile, 1) }}</pre>-->
          </v-list-item>
<!--        </v-list>-->

<!--        <v-divider></v-divider>-->

<!--        <v-list>-->
<!--          <v-list-item class="justify-center">-->
<!--            <v-list-item-action class="mx-4">-->
<!--              <v-btn-->
<!--                  rounded-->
<!--              >-->
<!--                <v-icon class="mr-4">mdi-card-account-details-star-outline</v-icon>-->
<!--                Profile aanpassen-->
<!--              </v-btn>-->
<!--            </v-list-item-action>-->
<!--          </v-list-item>-->

<!--          <v-divider></v-divider>-->

<!--          <v-subheader>Profieles:</v-subheader>-->

<!--          <v-list-item @click="doeNiks('Ali Connors')">-->
<!--            <v-list-item-avatar>-->
<!--              <v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>-->
<!--            </v-list-item-avatar>-->

<!--            <v-list-item-content>-->
<!--              <v-list-item-title>Ali Connors</v-list-item-title>-->
<!--              <v-list-item-subtitle>Monsters Inc.</v-list-item-subtitle>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->

<!--          <v-list-item @click="doeNiks('Ranee Carlson')">-->
<!--            <v-list-item-avatar>-->
<!--              <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>-->
<!--            </v-list-item-avatar>-->

<!--            <v-list-item-content>-->
<!--              <v-list-item-title>Ranee Carlson</v-list-item-title>-->
<!--              <v-list-item-subtitle>EVIL Corporate</v-list-item-subtitle>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->

<!--          &lt;!&ndash;  + Andere Profiel&ndash;&gt;-->
<!--          <v-list-item @click="doeNiks('+ Andere profiel')">-->
<!--            <v-list-item-icon>-->
<!--              <v-icon>mdi-account-plus</v-icon>-->
<!--            </v-list-item-icon>-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>Andere profiel</v-list-item-title>-->
<!--              &lt;!&ndash;                  <v-list-item-subtitle>Coprporate</v-list-item-subtitle>&ndash;&gt;-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->

          <!--          <v-divider></v-divider>-->
          <!--          <v-list-item class="justify-center">-->
          <!--            <v-list-item-action class="mx-4">-->
          <!--              <v-btn-->
          <!--                  rounded-->
          <!--                  color="error"-->
          <!--                  @click="$store.dispatch('user/USER_LOGOUT')"-->
          <!--              >-->
          <!--                <v-icon class="mr-4">mdi-exit-run</v-icon>-->
          <!--                Uitloggen-->
          <!--              </v-btn>-->
          <!--            </v-list-item-action>-->
          <!--          </v-list-item>-->

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-action>
              <v-switch
                  v-model="profileHints"
                  :disabled="true"
                  color="secondary">
              </v-switch>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Hints aan</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch
                  v-model="prop_GUICompact"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Compact</v-list-item-title>
              <v-list-item-subtitle>Mobiel / PC interface</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch
                  v-model="prop_DevMessages"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Ontwikkelingsberichten</v-list-item-title>
              <v-list-item-subtitle>Aan/uit blokken met extra gegevens</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" rounded @click="profile = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PopupProfile",
  props: {
    userProfile: {
      type: Object,
      defaults: {
        first_name: 'Gebruiken',
        last_name: 'is niet ingelogd',
        userprofile: {
          profile_image: ''
        }
      }
    }
  },
  data() {
    return {
      profile: false, // Profile popup scherm on/off

      options: [],

      // profileMessage: false,  // Profile Switch: Enable messages
      profileHints: false,    // Profile Switch: Enable hints
      profileFav: false,      // Hartje
      profileItems: [
        {
          title: "Profile",
          action: () => {
            console.log('Call profile')
          },
        },
        {
          title: "Andere account",
          action: () => {
            console.log('Call Andere account')
          },
        },
        {
          title: "Log uit",
          action: () => {
            console.log('Call Log uit')
          },
        },
      ]
    }
  }, // data

  computed: {
    ...mapGetters({
      GET_DEV_MESSAGES: "app/GET_DEV_MESSAGES",
      // GET_CURRENT_PROFILE: "user/GET_CURRENT_PROFIEL"
    }),

    prop_DevMessages: {
      get() {
        return this.$store.getters["app/GET_DEV_MESSAGES"]
      },
      async set(val) {
        await this.$store.commit('app/SET_DEV_MESSAGES', val)
      }
    },

    prop_GUICompact: {
      get() {
        return this.$store.getters["app/GET_GUI_COMPACT"]
      },
      async set(val) {
        await this.$store.commit('app/SET_GUI_COMPACT', val)
      }
    }
  },  // computed

  methods: {
    ...mapActions({
          // SET_DEV_MESSAGES: "app/...",
        }
    ),

    doeNiks(message) {
      console.log(`${message}: Ik heb niks gedaan 😁`)
    },

    parseInfo2Tree(infoObject, maxDeep = 6, listObject = []) {
      if (infoObject instanceof Object) {
        for (const infoObjectElement of Object.keys(infoObject)) {
          if (maxDeep <= 0) {
            listObject.push(
                {
                  id: 'Deep limit',
                  name: '...',
                }
            )
            return listObject
          }
          if (infoObject[infoObjectElement] instanceof Object) {
            // console.log('Object item', infoObjectElement)

            listObject.push(
                {
                  id: infoObjectElement,
                  name: infoObjectElement,
                  children: this.parseInfo2Tree(infoObject[infoObjectElement], maxDeep - 1),
                }
            )
          } else {
            listObject.push(
                {
                  id: infoObjectElement,
                  name: infoObjectElement + ': ' + infoObject[infoObjectElement],
                }
            )
          }
        }
      }
      return listObject
    },  // parseInfo2Tree
  },
  watch: {
    userprofileImage: function () {
      if ('userprofile' in this.userProfile) {
        if ('profile_image' in this.userProfile.userprofile) {
          return this.userProfile.userprofile.profile_image
        }
      }
      return ''
    }
  }
} // Export default

</script>

// STYLE
<style scoped>

</style>
