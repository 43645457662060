import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

// import Home from '../views/Home.vue';
// import Login from '../components/Login';
// import CopyOrders from "../views/CopyOrders";
// import Abonnement from "../views/Abonnement";

Vue.use(VueRouter);

const routes = [
  {
    name: 'Home',
    path: '/',
    component: require("../views/Home").default,
    meta: {
      icon: 'home',
      disabled: false,
    }
  },
  {
    name: 'Abonnement',
    path: '/abonnement',
    component: require("../views/Abonnement").default,
    meta: {
      icon: 'update',
      disabled: false,
      inlog: true,
    }
  },
  {
    name: 'Bestelling',
    path: '/bestelling',
    component: require("../views/Bestelling").default,
    meta: {
      icon: 'cart',
      disabled: false,
      inlog: true,
    }
  }, {
    name: 'Order planner',
    path: '/planner',
    component: require("../views/OrderPlanner").default,
    meta: {
      icon: 'map-clock',
      disabled: false,
      inlog: true,
    }
  },
  {
    name: "Server logboek",
    path: '/process',
    component: require("../views/Process").default,
    meta: {
      icon: 'format-list-bulleted',
      disabled: false,
      inlog: true,
      staff: true,
    }
  },
  {
    name: "Info",
    path: '/dashboard',
    component: require("../views/Dashboard").default,
    meta: {
      icon: 'information-outline',  // 'view-dashboard',
      disabled: false,
      inlog: true,
    }
  },
  {
    name: 'Login',
    path: '/login',
    component: require("../views/Login").default,
    meta: {
      icon: 'login',
      // icon: 'card',
      inlog: false,
      disabled: false,
    },
  },
  {
    name: 'Logout',
    path: '/login',
    component: require("../views/Login").default,
    meta: {
      icon: 'logout',
      // icon: 'card',
      inlog: true,
      disabled: false,
    },
  },
]

// const routes1 = store.getters["app/GET_ROUTES"]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  if (from.path !== to.path) {
    store.commit('app/SET_URL', to.path)
    // const op = store.getters["user/GET_CURRENT_OPERATORS"]
  }
})

router.beforeEach((to, from, next) => {
  console.log("Router from =>", from)
  console.log("Router to =>", to)
  // TODO: Check for user permissions...
  const isIngelogd = store.getters["user/GET_IS_INGELOGD"]

  // Is ingelogd?
  if (to.meta.inlog && !isIngelogd) {
    console.log("Redirect naar Login")
    next({name: "Login"})

  } else if (isIngelogd) { // Ingelogd -> Ga door
    // Is super user?
    if (!store.getters["user/GET_IS_SUPERUSER"]) {
      const operatorDict = store.getters["user/GET_OPERATORS"]
      console.log('Operators lijst:', operatorDict)

      if (!store.getters["user/GET_PATH_SET"].has(to.path) && !routes.find(r => r.path === to.path && r.meta.inlog === isIngelogd)) {

        console.log('Route is niet gevonden')
        console.log('Path set', store.getters["user/GET_PATH_SET"])
        console.log('Path to: ', to.path)
        return
      }
    }
  }
  console.log('Redirect from:', from.name, ' to:', to.name)
  next()
})

export default router
