<template>
  <v-navigation-drawer
      v-model="errorDrawer"
      temporary
      app
      clipped
      right
      width="25wv"
      style="max-width:50%;"
  >
    <v-toolbar dense>
      <v-toolbar-items>
        <v-btn
            icon
            @click="errorDrawer = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-title>Berichten</v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="mx-1 caption">Niveau:</div>

      <!--  MENU: FOUT NIVEAU  -->
      <v-menu
          rounded="b-xl"
          offset-y
          small
      >
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip
              open-delay="1000"
              bottom
          >
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                  class="white--text mx-4"
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
              >
                <v-icon
                    :color="getErrorColors[errorLevel]"
                >{{ errorIcons[errorLevel] }}
                </v-icon>
              </v-btn>
            </template>
            <span>Fout niveau</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item
              v-for="(item, index) in errorTitels"
              :key="index"
              link
              @click="errorLevel = index"
          >
            <v-list-item-title
                v-html="multiline2HTML(item)"
                :class="getErrorColors[index]+'--text'"
            ></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn icon>
        <v-icon>mdi-delete</v-icon>
      </v-btn>

    </v-toolbar>

    <!--    ERROR LIST-->
    <!--    3-de design -->
    <v-list two-line>
      <!--        v-model="selected"-->
      <v-list-item-group
          active-class="disabled"
          multiple
      >
        <template v-for="(item, index) in GET_LOG_BOOK">
          <v-list-item :key="item.time"
              :class="[!item.hidden ? getErrorColors[item.level] + getColorTint : 'grey']"
          >
<!--            <template v-slot:default="{ action }">-->
            <template>
              <v-list-item-content
                  class="pa-2"
              >
                <v-list-item-title
                    class="mt-2 mb-4"
                >
                  <v-icon
                    :color="getErrorColors[item.level]"
                    class="float-right ml-2"
                  >{{ GET_ERROR_ICONS[item.level] }}
                  </v-icon>
                  {{ errorTitels[item.level] }}: {{ item.titel }}</v-list-item-title>

                <div
                    v-show="!item.hidden"
                    class="text--primary"
                    style="font-size: small"
                >
                  <span
                      v-html="multiline2HTML(item.text)"
                  ></span>
                </div>
                <v-list-item-subtitle
                    class="text--primary text-end grey--text"
                >[{{ item.module }}]</v-list-item-subtitle>
              </v-list-item-content>

<!--                  :class="getErrorColors[item.level] + getColorTint"-->
              <v-list-item-action
                  class="ml-0"
              >
                <v-list-item-action-text
                    class="font-weight-bold"
                >{{ item.time | moment('HH:mm:ss') }}
                </v-list-item-action-text>

                <v-btn
                    fab
                    v-if="!item.hidden"
                    small
                    @click="HIDE_SHOW_LOG_MESSAGE(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>

                <v-btn
                    fab
                    v-else
                    @click="HIDE_SHOW_LOG_MESSAGE(item)"
                    x-small
                    color="error"
                >
                  <v-icon>mdi-delete-restore</v-icon>
                </v-btn>

              </v-list-item-action>
            </template>
          </v-list-item>

          <!--            v-if="index < items.length - 1"-->
          <v-divider
              v-if="index < GET_LOG_BOOK.length - 1"
              :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>


    <!--    DEBUG-->
    <!--    <pre>{{ getErrorColors }}</pre>-->
  </v-navigation-drawer>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {errorIcons, errorLevels, errorTitels} from "@/utils/common";

import common from "../utils/common";

export default {
  name: "AppErrorDrawer",
  data() {
    return {
      errorLevel: errorLevels.Debug, // Debug (zie: utils/common.js)
      errorLevels,
      errorTitels,
      errorIcons,
    }
  },
  computed: {
    ...mapGetters('app', [
      'GET_ERROR_DRAWER_STATE',
      'GET_LOG_BOOK',
      'GET_ERROR_COLORS',
      'GET_ERROR_COLORS_DARK',
      'GET_ERROR_ICONS',
    ]),

    getColorTint() {
      return this.$vuetify.theme.dark ? " darken-4" : " lighten-5"
    },

    getErrorColors() {
      return this.$vuetify.theme.dark ? this.GET_ERROR_COLORS_DARK : this.GET_ERROR_COLORS
    },

    errorDrawer: {
      get() {
        return this.GET_ERROR_DRAWER_STATE
      },
      set(val) {
        this.$store.commit('app/SET_ERROR_DRAWER', val)
      }
    }
  },
  methods: {
    ...mapActions('app', [
        'HIDE_SHOW_LOG_MESSAGE',
    ]),

    multiline2HTML(text) {
      return common.fn.multiline2HTML(text)
    },
  },
}
</script>

<style scoped>

</style>
