import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"ma-4"},[_c(VToolbar,{attrs:{"color":"primary","dense":""}},[_c(VToolbarTitle,[_vm._v(" Server logboek [aantal: "),_c('b',[_vm._v(_vm._s(_vm.GET_PROCESS_LIST.length))]),_vm._v("] ")]),_c(VBtn,{staticClass:"mx-2",attrs:{"color":"secondary","loading":_vm.autoUpdate.isLoading},on:{"click":_vm.processListUpdate}},[_vm._v("Vernieuwen ")]),_c(VBtnToggle,{attrs:{"dense":"","group":"","multiple":""},model:{value:(_vm.autoUpdate.toggle),callback:function ($$v) {_vm.$set(_vm.autoUpdate, "toggle", $$v)},expression:"autoUpdate.toggle"}},[_c(VBtn,{attrs:{"value":_vm.autoUpdate.value}},[_c(VIcon,[_vm._v("mdi-refresh-circle")]),_vm._v(" Auto update ")],1)],1)],1),_c(VList,{attrs:{"subheader":""}},[_c('transition-group',{attrs:{"name":"list","tag":"span"}},_vm._l((_vm.GET_PROCESS_LIST),function(item){return _c(VListItem,{key:item.uuid,staticStyle:{"border-bottom":"solid 1px lightgrey"},attrs:{"name":"log-list-item"},on:{"click":function($event){{}}}},[_c(VListItemContent,[_c(VListItemTitle,[_c('b',[_vm._v(_vm._s(item.id)+":")]),_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"my-2"},[_c('b',[_vm._v("UUID:")]),_vm._v(" "+_vm._s(item.uuid)+" ")]),_c(VListItemSubtitle,{staticClass:"my-2"},[_c('b',[_vm._v("Started:")]),_vm._v(" "+_vm._s(_vm._f("moment")(item.started,'DD-MM-YYYY HH:mm:ss'))+" / "),_c('b',[_vm._v(" Stopped:")]),_vm._v(" "+_vm._s(_vm._f("moment")(item.stopped,'DD-MM-YYYY HH:mm:ss'))+" ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatLogMessage(item.result))}}),_c('br')],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }