<template>
  <v-dialog
      id="dialog-global-settings"
      v-model="dialog"
      @keydown.esc="closeDialog"
      max-width="1100"
      persistent
  >
    <v-card>
      <v-toolbar
          :color="$vuetify.theme.dark ? '#909090' : '#D0D0D0'"
      >
        <v-icon class="mr-2">mdi-cog</v-icon>

        <v-toolbar-title>App instellingen</v-toolbar-title>

        <v-spacer></v-spacer>

        <!--        <v-btn-->
        <!--            text-->
        <!--            outlined-->
        <!--            @click="closeDialog"-->
        <!--        >Annuleren-->
        <!--        </v-btn>-->

        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn
              icon
              @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>

        <!-- Tabs-->
        <template v-slot:extension>
          <v-tabs
              v-model="settingsActiefTab"
              align-with-title
              show-arrows
          >
            <!--  active-class="gray"  -->
            <v-tabs-slider color="secondary"></v-tabs-slider>
            <v-tab
                v-for="item in settingsTabItems"
                :key="item"
            >{{ item }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <!--  Inhoud van tabs -->

      <v-tabs-items v-model="settingsActiefTab" light>
        <!--  <v-theme-provider -->
        <v-tab-item>
          <!--  Tab One  -->
          <v-list :dark="theme_dark">
            <v-list-item>
              <v-toolbar
              >
                <!--  flat-->
                <!--  :disabled="settingsBedrijfDialog.readOnly"-->
                <v-toolbar-title class="mr-2">Theme:</v-toolbar-title>
                <v-col
                    cols="1"
                    style="min-width:8em;"
                >
                  <v-row>
                    <!--  max-width="8em"   -->
                    <v-icon
                        color="warning"
                    >mdi-brightness-7
                    </v-icon>
                    <v-switch
                        v-model="theme_dark"
                        class="ml-3"
                        hide-details
                        inset
                    ></v-switch>
                    <v-icon
                        class="ml-n3 mr-2"
                        color="grey"
                    >mdi-brightness-3
                    </v-icon>
                  </v-row>
                </v-col>

                <!--  <v-divider vertical></v-divider>    -->

                <v-btn
                    color="primary"
                    class="ml-4"
                    disabled
                >Standaard
                </v-btn>
                <v-btn
                    color="secondary"
                    class="ml-2"
                    disabled
                >Profile
                </v-btn>
              </v-toolbar>
            </v-list-item>

            <v-list-item>
              <v-container fluid>
                <v-row dense>
                  <v-col
                      v-for="card in colorsList"
                      :key="card.title"
                      :cols="card.flex || 4"
                  >
                    <v-card
                        v-if="card.type === 'Logo'"
                    >
                      <v-img
                          v-if="GET_IS_INGELOGD && GET_CURRENT_PROFIEL.userprofile"
                          :src="GET_CURRENT_PROFIEL.userprofile.profile_image"
                          :alt="GET_CURRENT_PROFIEL.username"
                          class="rounded"
                      >
                        <!--                        <v-card-title v-text="card.title"></v-card-title>-->
                      </v-img>

                      <v-card-text>
                        ...Logo instellingen, komt binnenkort... 😱
                      </v-card-text>
                    </v-card>

                    <v-card
                        v-else
                    >
                      <v-card-actions>
                        <v-btn
                            :color="$vuetify.theme.themes[theme_dark ? 'dark' : 'light'][card.value]"
                            class="white--text"
                            block
                        >{{ card.title }}
                        </v-btn>
                      </v-card-actions>

                      <v-card-text>
                        <v-color-picker
                            v-model="$vuetify.theme.themes[theme_dark ? 'dark' : 'light'][card.value]"
                        ></v-color-picker>
                        <!--  canvas-height="120"-->
                      </v-card-text>

                      <!--  </v-img>-->

                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-list-item>
          </v-list>
        </v-tab-item>
      </v-tabs-items>

      <!--  Dialog action   -->
      <v-divider></v-divider>

      <v-card-actions>

        <v-spacer></v-spacer>

        <v-btn
            color="primary"
            @click="m_saveDialogSettings"
        >
          Opslaan
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
// import BrightnessSwitch from "@/components/BrightnessSwitch";
import {mapGetters} from "vuex";

export default {
  name: 'GlobalSettings',
  components: {},
  // model: {
  //
  // },

  props: {
    value: Boolean,
    // GET_MENU: null,
    // drawer: null,
  },

  data() {
    return {
      // Settings dialog
      // settingsDialogModel: false,
      settingsActiefTab: 0,
      settingsTabItems: [
        'Colors',
        '...'
      ],
      // Colors
      colorsList: [
        {title: 'Primary', value: 'primary'},
        {title: 'Secondary', value: 'secondary'},
        {title: 'Accent', value: 'accent'},
        {title: 'Error', value: 'error'},
        {title: 'Info', value: 'info'},
        {title: 'Success', value: 'success'},
        {title: 'Warning', value: 'warning'},
        {title: 'Extra', value: 'extra'},
        {title: 'Logo', flex: 4, type: 'Logo'},
      ],

      theme_dark: false,

      // settingsBedrijfDialog: {
      //   // hideWidget: false,
      //   // options: [],
      //   // readOnly: false,
      //   selected: [],   // Lijst met UUID's
      // },
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },
    m_saveDialogSettings() {
      // Save settings
      this.dialog = false
    },
  },

  computed: {
    ...mapGetters({
      GET_IS_INGELOGD: "user/GET_IS_INGELOGD",
      GET_CURRENT_PROFIEL: "user/GET_CURRENT_PROFIEL",
    }),

    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
}
</script>