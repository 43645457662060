<template>
  <div>
    <!--  <v-toolbar>-->
    <!--    <v-toolbar-title id="order-planner"-->
    <!--                       class="hidden-lg-and-down"-->
    <!--      >Planner-->
    <!--      </v-toolbar-title>-->

    <!--      <v-divider-->
    <!--          class="mx-4 hidden-lg-and-down"-->
    <!--          vertical-->
    <!--      ></v-divider>-->
    <!--  </v-toolbar>-->

    <v-container
        id="order-planner-container"
        class="fill-height align-start"
        fluid
    >
      <v-toolbar id="main-toolbar"
                 :dense="appGuiCompact"
                 class="mt-2"
      >
        <v-toolbar-title id="order-planner"
                         class="hidden-lg-and-down"
        >Planner
        </v-toolbar-title>

        <v-divider
            class="mx-4 hidden-lg-and-down"
            vertical
        ></v-divider>
        <!--  Lees alles  -->
        <!--  Knop: Lees alles    -->
        <v-tooltip
            id="lees_alles"
            bottom
            open-delay="1000"
        >
          <template v-slot:activator="{ on, attrs}">
            <!--              TODO: data loader-->
            <!--                  @click="m_LoadSnelStartData"-->
            <!--                  :loading="comp_GlobalProgress"-->
            <!--                  :disabled="!user_GetCurrentSnelStart || comp_GlobalProgress"-->
            <v-btn
                :large="!appGuiCompact"
                :loading="ss_GetGlobalProgressFlag"
                :disabled="ss_GetGlobalProgressFlag"
                class="mx-2"
                color="secondary"
                v-bind="attrs"
                v-on="on"
                @click="m_LoadSnelStartData(true)"
            >
              <!--              @click="m_LoadSnelStartData"-->
              <v-icon :large="!appGuiCompact">mdi-database-refresh</v-icon>
            </v-btn>
          </template>
          <span>Lees alle orders opnieuw</span>
        </v-tooltip>

        <!--  GET ORDERS    -->
        <!--      <v-btn-->
        <!--          @click="m_updateBoardInfoAsync"-->
        <!--          :disabled="ss_GetGlobalProgressFlag"-->
        <!--          color="accent"-->
        <!--      >Herladen board</v-btn>-->

        <!--  Select Date   -->
        <!--      <v-col cols="1">-->
        <v-dialog id="date-dialog"
                  ref="dateDialog"
                  v-model="pickerOrderModal"
                  :return-value.sync="pickerOrderDate"
                  persistent
                  width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="pickerOrderDate"
                prepend-icon="mdi-calendar"
                readonly
                placeholder="Order datum"
                :dense="appGuiCompact"
                :loading="ss_GetGlobalProgressFlag"
                class="mt-6 mr-4 shrink"
                style="width:10em; min-width:10em;"
                v-bind="attrs"
                v-on="on"
            >
              <template v-slot:prepend-inner>
                <v-chip
                    small
                    color="primary"
                    v-if="pickerOrderDate"
                >{{ m_getLocalShortWeekday(pickerOrderDate) }}
                </v-chip>
              </template>
            </v-text-field>
            <!--                label="Picker in dialog"-->
          </template>

          <v-date-picker
              v-model="pickerOrderDate"
              :allowed-dates="isDatumAllowed"
              scrollable
              locale="nl-nl"
              first-day-of-week="1"
          >
            <!--          :min="_.min(comp_alleOrdersDatum)"-->
            <!--          :max="_.max(comp_alleOrdersDatum)"-->
            <v-spacer></v-spacer>
            <v-btn
                outlined
                rounded
                color="error"
                @click="pickerOrderModal = false"
            >Annuleren
            </v-btn>
            <v-btn
                rounded
                color="primary"
                @click="$refs.dateDialog.save(pickerOrderDate)"
            >OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <!--      </v-col>-->

        <span class="mx-2 hidden-lg-and-down text--secondary"
        >Status filter:</span>

        <v-btn-toggle id="process-status"
                      v-model="settingsProcessOrderEnabled"
                      multiple
                      class="hidden-md-and-down"
                      :shaped="!appGuiCompact"
                      :small="appGuiCompact"
        >
          <!--        <v-btn v-if="false" />-->
          <v-btn
              v-for="status in processStatusListEnabled"
              :key="status.id"
              :value="status.id"
              icon
              :small="appGuiCompact"
          >
            <!--            :disabled="[7, 8].includes(status.id)"-->
            <v-tooltip top>
              <template
                  v-slot:activator="{ on, attrs }"
              >
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :color="processStatusColorsList[status.id]"
                    :small="appGuiCompact"
                >{{ status.icon }}
                </v-icon>
              </template>
              <span>{{ status.text }}</span>
            </v-tooltip>
          </v-btn>
        </v-btn-toggle>

        <v-spacer></v-spacer>

        <span class="mx-2 hidden-lg-and-down text--secondary"
        >Kaart opties:</span>

        <!--    View Options    -->
        <v-btn-toggle id="show-options"
                      v-model="showToggle"
                      multiple
                      class="mr-4 hidden-md-and-down"
                      :shaped="!appGuiCompact"
                      :small="appGuiCompact"
        >
          <!--    // Euro   -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  :small="appGuiCompact"
                  v-bind="attrs"
                  v-on="on"
              >
                <!--              :disabled="!isShowOrderRegels"-->
                <v-icon
                    :small="appGuiCompact"
                >mdi-currency-eur
                </v-icon>
              </v-btn>
            </template>
            <span>Prijs tonen</span>
          </v-tooltip>

          <!--        // Adress   -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  :small="appGuiCompact"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon
                    :small="appGuiCompact"
                >mdi-postage-stamp
                </v-icon>
              </v-btn>
            </template>
            <span>Adres tonen</span>
          </v-tooltip>

          <!--        // Lijst    -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  :small="appGuiCompact"
                  icon
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon
                    :small="appGuiCompact"
                >mdi-format-list-bulleted
                </v-icon>
              </v-btn>
            </template>
            <span>Orderregels tonen</span>
          </v-tooltip>

          <!--  Show hidden    -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  :small="appGuiCompact"
                  icon
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon
                    :small="appGuiCompact"
                >{{ isShowAllOrders ? 'mdi-eye-outline' : 'mdi-eye-off-outline' }}
                </v-icon>
              </v-btn>
            </template>
            <span>Toon alle orders</span>
          </v-tooltip>

        </v-btn-toggle>

        <v-badge
            :content="comp_tasks4Update"
            :value="comp_tasks4Update !== 0"
            color="error"
            overlap
            offset-x="2em"
        >
          <v-btn id="route-bewaren"
                 @click="routeBewaren()"
                 color="accent"
                 :large="!appGuiCompact"
                 :loading="routeBewarenProgress"
                 :disabled="routeBewarenProgress || !pickerOrderDate || comp_tasks4Update === 0"
          >
            <v-icon
                :left="!$vuetify.breakpoint.lgAndDown"
                :large="!appGuiCompact"
            >mdi-send-clock-outline
            </v-icon>
            <span
                class="hidden-lg-and-down ml-2"
            >Route bewaren</span>
          </v-btn>
        </v-badge>
      </v-toolbar>

      <!--
      888888b.                                  888
      888  "88b                                 888
      888  .88P                                 888
      8888888K.   .d88b.   8888b.  888d888  .d88888
      888  "Y88b d88""88b     "88b 888P"   d88" 888
      888    888 888  888 .d888888 888     888  888
      888   d88P Y88..88P 888  888 888     Y88b 888
      8888888P"   "Y88P"  "Y888888 888      "Y88888
      -->

      <v-container id="main-board"
                   class="fill-height justify-start"
                   fluid
      >
        <!--  Kanban board  -->
        <v-sheet id="skeleton"
                 class="align-self-start d-flex flex-row"
                 v-if="boards.length === 0 || (boards.length === 1 && boards.tasks && boards.tasks.length < 2)"
        >
          <v-col
              v-for="i in 7"
              :key="i"
          >
            <v-skeleton-loader
                type="table-heading, list-item-two-line, image, table-tfoot"
            ></v-skeleton-loader>
          </v-col>

        </v-sheet>

        <v-row id="drag-panel"
               class="align-self-start"
               v-else
        >
          <!--        class="flex-nowrap"-->
          <v-col id="kanban-board"
                 v-for="(board, board_index) in boards"
                 :key="board.naam"
                 class="mx-1 my-4 pa-0 inset-shadow-1 flex-column flex-grow-0 flex-shrink-1"
                 :style="{ backgroundColor: board.color + settingsBoardColorAlpha }"
                 style="min-height:15em;"
          >
            <v-window id="kanban-titel"
                      class="ma-1"
                      flat
            >
              <!--  Board icon, TITEL en <->  -->

              <!--            <v-card-actions-->
              <!--                class="pa-0"-->
              <!--                v-if="board.uuid === null"-->
              <!--            >-->
              <!--              -->
              <!--            </v-card-actions>-->
              <v-card-actions
                  class="pa-0"
              >
                <!--              Board titel-->
                <!--          TODO: geen truck: truck-remove-outline  -->
                <v-icon
                    v-if="board.expanded && !isBoardEmpty(board)"
                    class="ml-1"
                    :color="board.color"
                >{{ board.uuid ? 'mdi-truck-outline' : 'mdi-file-multiple-outline' }}
                </v-icon>

                <v-card-subtitle
                    v-if="board.expanded && !isBoardEmpty(board)"
                    class="pa-2 text-no-wrap"
                    :class="board.uuid ? 'font-weight-light' : 'font-weight-medium'"
                >
                <span
                    :class="{
                      'text-decoration-line-through':
                      getKanbanBoarden.findIndex(b => b.uuid === board.uuid) === -1
                      && board.uuid !== null
                    }"
                >{{ board.naam }}</span>
                </v-card-subtitle>

                <v-spacer></v-spacer>

                <v-btn
                    id="board-expand"
                    icon
                    @click="board.expanded = !board.expanded"
                    v-if="board.uuid"
                    :disabled="isBoardEmpty(board)"
                >
                  <v-icon>mdi-arrow-left-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-window>

            <v-divider></v-divider>

            <div id="reduced-titel"
                 v-if="!board.expanded || isBoardEmpty(board)"
                 style="max-width:3em; min-height:5em;"
            >
              <v-card-text
                  class="text-center text-h6 px-0 pt-1"
              >{{ countTasksShow(board.tasks) }}
              </v-card-text>

              <div
                  style="transform:translate(0, 450%) rotateZ(-90deg);"
                  class="caption text-no-wrap"
                  :class="{'text-decoration-line-through':getKanbanBoarden.findIndex(b => b.uuid === board.uuid) === -1}"
              >
                {{ board.naam }}
              </div>
            </div>

            <!--    empty-insert-threshold="50"   -->
            <draggable id="drag-board-col"
                       v-if="board.expanded && !isBoardEmpty(board)"
                       v-model="board.tasks"
                       animation="150"
                       handle=".handle"
                       @change="m_dragUpdate"
                       @drag="m_dragTest"
                       ghost-class="ghost-card"
                       group="tasks"
                       class="d-flex flex-column fill-height"
            >

              <!--  BOARD HEADER-->
              <template id="kanban-header"
                        v-slot:header
              >
                <v-card
                    class="ma-1 pa-0"
                    style="min-width: 10em;"
                >
                  <v-card-actions>
                    <v-text-field
                        label="Aantal:"
                        :value="countTasksShow(board.tasks)"
                        style="max-width:2.5em; min-width:2.5em;"
                        class="ma-1"
                        readonly
                    ></v-text-field>

                    <!--              Orders zoeken   -->
                    <v-text-field id="order-zoeken"
                                  v-if="!board.uuid && countTasksShow(board.tasks) > 0"
                                  label="Zoeken"
                                  v-model.trim="orderFilterText"
                                  clearable
                                  class="ma-1"
                    >
                      <template v-slot:append>
                        <v-menu id="zoek-optie"
                                v-model="zoekOptionsMenuModel"
                                :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                            >mdi-format-list-bulleted-type
                            </v-icon>
                          </template>
                          <v-card>
                            <v-card-subtitle
                                class="pb-0"
                            >
                              Zoeken op volgende velden:
                            </v-card-subtitle>
                            <!--                          <v-card-text>-->
                            <v-list
                                dense
                                class="pt-0"
                            >
                              <v-list-item
                                  v-for="item in settingsOrderFiltersList"
                                  :key="item.field"
                                  class="px-4"
                              >
                                <v-list-item-action
                                    class="my-0 mr-0"
                                >
                                  <v-checkbox
                                      v-model="item.selected"
                                  >
                                    <template v-slot:label>
                                      <span
                                          class="caption ml-2"
                                      >{{ item.label }}</span>
                                    </template>
                                  </v-checkbox>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>

                            <v-divider></v-divider>

                            <v-card-actions>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                      outlined
                                      icon
                                      color="primary"
                                      class="mx-2"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="settingsOrderFiltersList.forEach(i => i.selected=true)"
                                  >
                                    <v-icon>mdi-check-all</v-icon>
                                  </v-btn>
                                </template>
                                <span>Alles aan</span>
                              </v-tooltip>
                              <v-spacer/>
                              <v-btn
                                  rounded
                                  color="secondary"
                                  @click="zoekOptionsMenuModel = false"
                              >Ok
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-text-field>

                    <v-spacer></v-spacer>

                    <!--  PRINT SHEET: BOOTSTRAP 5.2 STYLE   -->
                    <div v-if="board.uuid && countTasksShow(board.tasks) > 0">
                      <!--  PRINT BODY  -->
                      <div
                          v-if="pickerOrderDate"
                          style="display:none;"
                          :id="'printBoard' + board_index"
                      >
                        <div class="container-fluid">
                          <!--    FOR ORDERS    -->
                          <div
                              v-for="(task, task_index) in board.tasks.filter(t => isOrderShow(t))"
                              :key="task.uuid"
                              class="mb-4 border-bottom border-dark"
                              style="break-after: page; border-bottom-style: dashed!important;"
                          >
                            <!--    PAGE HEADER   -->
                            <header class="border-bottom d-flex flex-nowrap justify-content-between">
                              <span class="fs-2">
                                <b>{{ board.naam }}</b>
                              </span>
                              <span
                                  class="fs-2 text-capitalize d-flex justify-content-end align-items-center"
                                  v-html="m_getLocalDate(pickerOrderDate, {weekday: 'long', month: 'long', day: 'numeric'})">
                              </span>
                            </header>

                            <div
                                v-if="task.order"
                            >
                              <!--                              ORDER TITLE -->
                              <header class="text-bg-secondary rounded-3 my-4 d-flex justify-content-between">
                                <span class="fs-3 mx-2 pa-2 mb-1">Order: {{ task.order.nummer }}</span>
                                <span class="fs-3 mx-2 pa-2 mb-1 justify-content-end">Route # {{ task_index + 1 }}</span>
                              </header>

                              <div
                                  class="d-grid my-3"
                                  style="grid-template-columns: 3fr 1fr 3fr;"
                              >
                                <div class="p-1">
                                  <!--  MEMO -->
                                  <div
                                      v-if="task.order.memo"
                                      class="me-2 p-2 bg-light border"
                                      style="font-size: small;"
                                      v-html="multiline2HTML(task.order.memo)"
                                  ></div>
                                </div>

                                <!--  Google MAP  -->
                                <div
                                    v-if="task.order.relatie"
                                >
                                  <!--  QR Code  -->
                                  <a :href="makeGoogleMapLink(task.order.relatie)">
                                    <img
                                        :width="qrSettings.size"
                                        :height="qrSettings.size"
                                        :src="makeQRCodeLink(task.order.relatie)"
                                    />
                                  </a>
                                </div>
                                <!--  https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=Hello%20world -->
                                <div
                                    v-if="task.order.relatie"
                                    class="bg-light border rounded-3 p-1"
                                >
                                  <!--  KLANTCODE -->
                                  <div class="mx-2 fs-6">Klantcode: <b>{{ task.order.relatie.relatiecode }}</b></div>

                                  <!--  TELEFOON -->
                                  <div
                                      v-if="task.order.relatie.telefoon"
                                      class="mx-2 fs-6"
                                  >Tel: <b>{{ task.order.relatie.telefoon }}</b></div>

                                  <!--  RELATIENAAM -->
                                  <div class="mx-2 mt-2 fw-bold fs-5">{{ task.order.relatie.naam }}</div>

                                  <!--  CONTACT -->
                                  <div
                                      v-if="task.order.relatie.naam !== task.order.relatie.vestigings_contactpersoon"
                                      class="mx-2 fs-6 fw-semibold"
                                  >{{ task.order.relatie.vestigings_contactpersoon }}
                                  </div>

                                  <!--  STRAAT -->
                                  <div class="mx-2 fs-6">{{ task.order.relatie.vestigings_straat }}</div>

                                  <!--  POSTCODE, PLAATS -->
                                  <div class="mx-2 fs-6">
                                    <span class="fw-semibold pe-3">{{ task.order.relatie.vestigings_postcode }}</span>
                                    <span class="fw-bolder text-uppercase">{{
                                        task.order.relatie.vestigings_plaats
                                      }}</span>
                                  </div>
                                </div>
                              </div>

                              <div v-if="task.order">
                                <div v-if="task.order.relatie">
                                  <div v-if="task.order.regels">
                                    <table class="table table-striped">
                                      <thead>
                                      <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Artikel</th>
                                        <th scope="col" class="text-end">Aantal</th>
                                      </tr>
                                      </thead>
                                      <tbody class="table-group-divider">
                                      <tr
                                          v-for="(regel, i) in task.order.regels"
                                          :key="i"
                                      >
                                        <th
                                            scope="row"
                                            style="font-size:small;"
                                        >{{ i + 1 }}
                                        </th>
                                        <td
                                            v-text="regel.omschrijving"
                                            style="font-size:small;"
                                        ></td>
                                        <td
                                            class="fw-semibold text-end"
                                            style="font-size:small;"
                                        >{{ regel.aantal === 0 ? '' : regel.aantal }}
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                                v-else
                            >Geen order
                            </div>
                          </div>

                        </div>
                      </div>

                      <v-btn
                          @click="print('printBoard' + board_index)"
                          class="mx-2"
                          color="primary"
                          icon
                          outlined
                          :disabled="!pickerOrderDate"
                      >
                        <v-icon>mdi-printer-outline</v-icon>
                      </v-btn>
                    </div>

                    <div
                        v-if="countTasksShow(board.tasks) > 0 && isShowOrderRegels"
                        style="max-width:1em;"
                    >
                      <v-btn
                          icon
                          x-small
                          class="ma-0"
                      >
                        <v-icon>mdi-chevron-up</v-icon>
                      </v-btn>
                      <v-btn
                          icon
                          x-small
                          class="ma-0"
                      >
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </div>
                  </v-card-actions>
                  <!--                <v-card-subtitle>Aantal:&nbsp;<b>{{ countTasksShow(board.tasks) }}</b></v-card-subtitle>-->
                </v-card>
              </template>

              <!--  BOARD FOOTER-->
              <template id="kanban-footer"
                        v-slot:footer
                        v-if="countTasksShow(board.tasks) > 0"
              >
                <v-card class="ma-1 pa-0">
                  <v-card-text>
                    <v-simple-table id="total-table"
                                    dense
                                    class="small-padding"
                    >
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th>
<!--                        Sigma sign-->
                            <v-icon
                                x-large
                                class="ma-0"
                            >mdi-sigma
                            </v-icon>
                          </th>
                          <th
                              class="text-right"
                          >Artikelen
                          </th>
                          <th
                              v-if="isShowPrice"
                              class="text-right"
                          >Prijs ex. BTW
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td></td>
                          <td
                              class="text-right font-weight-black"
                          >{{ m_getBoardTotalArtikelen(board) }}
                          </td>
                          <td
                              v-if="isShowPrice"
                              class="text-right font-weight-medium"
                          >{{ m_getBoardTotalBedragEx(board) }}
                          </td>
                        </tr>
                        <tr
                            v-if="isShowPrice"
                        >
                          <td></td>
                          <td
                              class="text-right caption"
                          >BTW:
                          </td>
                          <td
                              class="text-right font-weight-medium"
                          >{{ m_getBoardTotalBedragBTW(board) }}
                          </td>
                        </tr>
                        <tr
                            v-if="isShowPrice"
                        >
                          <td></td>
                          <td
                              class="text-right caption"
                          >Totaal:
                          </td>
                          <td
                              class="text-right font-weight-black"
                          >{{ m_getBoardTotalBedragIncl(board) }}
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>

              </template>

              <!--  ORDER CARDS-->
              <span id="order-card"
                    v-for="(task) in board.tasks"
                    :key="task.uuid"
              >
              <v-card
                  v-if="isOrderShow(task)"
                  class="ma-1 pa-0"
                  :style="{opacity: settingsCardOpacity}"
                  :loading="task.progress"
              >
<!--                  :disabled="[7, 8].includes(task.order.order_status) || !task.movable"-->
                <template id="card-progress" v-slot:progress>
                  <v-progress-linear
                      color="error"
                      height="3"
                      indeterminate
                  ></v-progress-linear>
                </template>
                <!--  :class="{'order-last': task.type === 'Total'}"-->
                <!--  Empty order-->
                <span id="card-null-order"
                      v-if="task.order === null"
                >
                  <v-card-subtitle>Leeg order</v-card-subtitle>
                </span>

                <!--  Standaard Order Card  -->
                <span id="main-card"
                      v-else
                >
                  <!--  Card action -->
                  <v-card-actions id="card-titel">

                    <!--  Found Mark  -->
                    <v-btn id="found-mark"
                           v-show="task.found"
                           fab
                           dark
                           elevation="0"
                           :x-small="appGuiCompact"
                           :small="!appGuiCompact"
                           class="mr-2"
                           color="error"
                    >
<!--                          :small="appGuiCompact"-->
                      <v-icon
                      >mdi-check-outline</v-icon>
                    </v-btn>

                    <!--  Order process status icon -->
                    <v-tooltip id="process-status"
                               top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            :small="appGuiCompact"
                            class="mr-2"
                            :color="processStatusColorsList[task.order.order_status]"
                            icon
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            v-show="!task.found"
                        >
                          <v-icon :small="appGuiCompact">
                            {{ processStatusIconList[task.order.order_status] }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ processStatusSearchList[task.order.order_status] }}</span>
                    </v-tooltip>

                    <!--  SAVE INFO -->
                    <v-tooltip id="order-info"
                               top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-show="m_getTaskLog(task.uuid) && !task.update"
                            v-bind="attrs"
                            v-on="on"
                            :color="m_getProgressStatusColor(task)"
                            :large="!appGuiCompact"
                        >mdi-information-variant</v-icon>
                      </template>
                      <span v-html="m_getTaskLog(task.uuid)"></span>
                    </v-tooltip>

                    <!--  SAVE BUTTON -->
                    <v-tooltip id="save-order-btn"
                               top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-show="task.update || m_getTaskStatus(task) === 'error'"
                            :small="appGuiCompact"
                            :loading="task.progress"
                            class="mr-2"
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            outlined
                            @click="saveOrderExtravelden(board, task)"
                        ><v-icon
                            :small="appGuiCompact"
                        >mdi-content-save-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Route:
                        {{ m_getOrderHoofdExtraveld(task.order, settingsRoutenummerExtraVeld) }}
                        =>
                        {{ task.route }}
                      </span>
                    </v-tooltip>

                    <!--  Weekday   -->
                    <v-chip id="week-day"
                            v-if="!pickerOrderDate"
                            :x-small="appGuiCompact"
                            :small="!appGuiCompact"
                            color="primary"
                            class="mr-2"
                    >{{ m_getLocalShortWeekday(task.order.datum) }}</v-chip>

                    <!--  Datum-->
                    <span id="order-date"
                          v-if="!pickerOrderDate"
                          class="text-no-wrap"
                          v-html="m_getLocalDate(task.order.datum)"
                    ></span>

                    <v-spacer></v-spacer>

                    <!--  MEMO  -->
                    <v-tooltip id="memo-tip"
                               top
                               v-if="task.order.memo"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class=""
                            :x-small="appGuiCompact"
                            :small="!appGuiCompact"
                            color="warning"
                            icon
                        >
                          <v-icon
                              :small="!appGuiCompact"
                              :x-small="appGuiCompact"
                          >mdi-note-text-outline</v-icon>
                        </v-btn>
                      </template>
                      <!--                          v-html="m_memo2Multi(task.order.memo)"-->
                      <span
                          v-html="multiline2HTML(task.order.memo)"
                      ></span>
                    </v-tooltip>

                    <!--  PIN -->
                    <v-btn id="pin-order"
                           v-if="task.movable"
                           icon
                           @click="task.pinned = !task.pinned"
                    >
                      <v-icon
                          v-if="task.pinned"
                          color="error"
                      >mdi-pin-off-outline</v-icon>
                      <v-icon
                          v-else
                          color="success"
                      >mdi-pin-outline</v-icon>
                    </v-btn>

                    <!--                DISABLED HANDLE-->
                    <v-tooltip top
                               v-if="!pickerOrderDate && task.movable"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="warning"
                            class="not-allowed-cursor ml-1 mr-4"
                            :large="!appGuiCompact"
                            v-bind="attrs"
                            v-on="on"
                        >mdi-calendar-lock-outline</v-icon>
                      </template>
                      <span>Kies eerst orderdatum</span>
                    </v-tooltip>

                    <!--  DRAG HANDLE-->
                    <v-icon id="drag-handle"
                            v-else-if="!task.pinned && task.movable"
                            class="handle ml-1 mr-4 move-cursor"
                            color="gray"
                            :large="!appGuiCompact"
                    >mdi-cursor-move</v-icon>

                    <!--  POPUP MENU  -->
                    <v-menu id="move-popup"
                            v-if="task.movable"
                            offset-x
                    >
                      <template v-slot:activator="{ on, attrs }">
<!--                  Menu button -->
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            :disabled="boards.length < 2"
                            :small="appGuiCompact"
                            class="mx-n2"
                        >mdi-dots-vertical</v-icon>
                      </template>
                      <v-list>
                        <v-list-item class="caption">
                          <v-icon class="mr-2"
                          >mdi-file-move-outline</v-icon>
                          verplaatsen naar</v-list-item>
                        <v-divider></v-divider>
                        <div
                            v-for="(doelBoard, index) in boards"
                            :key="index"
                        >
                          <v-list-item
                              link
                              v-if="doelBoard.uuid !== board.uuid && (doelBoard.uuid === null || getKanbanBoarden.findIndex(b => b.uuid === doelBoard.uuid) !== -1)"
                              @click="moveOrder2Board(task.uuid, board.uuid, doelBoard.uuid)"
                              class="px-2"
                              style="border-left:solid 5px;"
                              :style="{
                                minHeight: appGuiCompact ? '32px' : '48px',
                                // 'min-height:32px;': appGuiCompact,
                                borderLeftColor: doelBoard.color,
                                backgroundColor: doelBoard.color + '18'
                              }"
                          >
                            <v-icon
                                class="pr-2"
                                small
                            >{{ doelBoard.uuid === null ? 'mdi-transfer-left' : 'mdi-transfer-right' }}</v-icon>
                            <v-list-item-title
                                :style="{'font-size:small;':appGuiCompact}"
                            >{{ doelBoard.naam }}</v-list-item-title>
                          </v-list-item>
                          <v-divider
                              v-if="doelBoard.uuid === board.uuid"
                          ></v-divider>
                          <!--                      <v-divider v-else></v-divider>  -->
                        </div>
                      </v-list>
                    </v-menu>

                    <!--  INFO: NIET VERPLAATSBAAR-->
                    <v-tooltip
                        v-else
                        top
                    >
                      <template v-slot:activator="{ on, attrs}">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="error"
                            :large="!appGuiCompact"
                        >mdi-information-variant</v-icon>
                      </template>
                      <span>Order is niet verplaatsbaar:
                        <span
                            v-if="!m_hasOrderAlleExtravelden(task.order)"
                        ><br/>Sjabloon heb geen <b>'route'</b> extravelden</span>
                        <span
                            v-if="[7, 8].includes(task.order.order_status)"
                        ><br/>Order heeft alleen lezen status <b>{{
                            processStatusSearchList[task.order.order_status]
                          }}</b></span>
                      </span>
                    </v-tooltip>

                  </v-card-actions>

                  <!--  Adres card-->
                  <v-sheet id="card-adres"
                           class="my-0 mx-1 py-1 inset-shadow-1"
                           color="#80808010"
                           v-if="isShowAdres && (m_isOrderRelatieNaam(task.order) || task.order.afleveradres_contactpersoon || task.order.afleveradres_straat || task.order.afleveradres_postcode || task.order.afleveradres_plaats)"
                  >
                    <v-tooltip id="adres-icon"
                               top
                               open-delay="1000"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="float-end pa-1"
                            color="blue-grey lighten-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="copyAdres(task)"
                        >mdi-postage-stamp</v-icon>
                      </template>
                      <span>Adres naar klembord kopiëren</span>
                    </v-tooltip>

                    <!--  Relatie   -->
                    <v-card-text id="adres-relatie"
                                 class="py-0 px-2 font-weight-bold"
                                 v-if="m_isOrderRelatieNaam(task.order)"
                                 v-text="m_getOrderRelatieNaam(task.order)"
                    ></v-card-text>

                    <!--  Contact   -->
                    <v-card-text id="adres-contact"
                                 class="py-0 px-2"
                                 v-if="!m_isOrderRelatieNaamEnContactSame(task.order) && task.order.afleveradres_contactpersoon"
                                 v-text="task.order.afleveradres_contactpersoon"
                    ></v-card-text>

                    <!--  Straat-->
                    <v-card-text id="adres-straat"
                                 class="py-0 px-2 font-weight-light"
                                 v-if="task.order.afleveradres_straat"
                                 v-text="task.order.afleveradres_straat"
                    ></v-card-text>

                    <!--  Postcode en Plaats-->
                    <v-card-actions id="adres-postcode"
                                    class="py-0 px-2"
                                    style="width: 100%;"
                    >
                      <v-card-text
                          class="pa-0 text-no-wrap"
                      >
                        <span
                            class="pr-1"
                            v-show="task.order.afleveradres_postcode"
                        >{{ task.order.afleveradres_postcode }} </span>
                        <span class="font-weight-black">{{ task.order.afleveradres_plaats.toUpperCase() }}</span>
                      </v-card-text>
                      <v-card-text
                          v-if="task.order.relatie"
                          class="pa-0 text-no-wrap text-right"
                      >
                        <v-chip :small="!appGuiCompact" :x-small="appGuiCompact"
                                color="secondary">{{ task.order.relatie.relatiecode }}</v-chip>
                      </v-card-text>
                      <!--                        style="max-width: 4em"-->
                    </v-card-actions>

                  </v-sheet>

                  <!--  Order expansion panel  -->
                  <v-expansion-panels
                      flat
                  >
<!--                      focusable-->
                    <v-expansion-panel>
                      <v-sheet
                          v-if="!isShowOrderRegels"
                          class="pa-2 justify-space-between"
                      >
                        <!--  Order #-->
                        <span
                            class="mr-2 caption"
                            v-if="task.order.nummer"
                        >#<b>{{ task.order.nummer }}</b>
                        </span>
                        <!--  Geen #-->
                        <span
                            v-else
                            class="mr-2"
                        >🚫</span>

                        <!--  Order omschrijving-->
                        <span
                            class="caption"
                            :style="'min-width:' + (appGuiCompact ? '12' : '18') + 'em;'"
                        >{{ task.order.omschrijving }}</span>

                      </v-sheet>

                      <v-expansion-panel-header
                          v-else
                          class="pa-2 justify-space-between"
                          expand-icon="mdi-menu-down"
                          style="min-height:16px;"
                          :disabled="false"
                      >
                        <!--  Order #-->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                                class="mr-2 caption"
                                v-if="task.order.nummer"
                                v-bind="attrs"
                                v-on="on"
                            >№&nbsp;<b>{{ task.order.nummer }}</b>
                            </span>
                            <!--  Geen #-->
                            <span
                                v-else
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                            >🚫</span>
                          </template>
                          <span>{{ task.sjabloon || 'Geen sjabloon' }}</span>
                        </v-tooltip>
                        <!---->
                        <!--  Order omschrijving-->
                        <span
                            class="caption"
                            :style="'min-width:' + (appGuiCompact ? '12' : '18') + 'em;'"
                        >{{ task.order.omschrijving }}</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content
                          v-if="isShowOrderRegels"
                      >
  <!--                  Order regel tabel -->
                        <v-simple-table dense class="small-font small-padding">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th class="text-left"
                                >Artikel</th>

                                <th class="text-right"
                                >Aantal</th>
                                <th
                                    v-if="isShowPrice"
                                    class="text-right"
                                >Prijs</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                  v-for="(regel, i) in task.order.regels"
                                  :key="i"
                                  style="font-size:75%;"
                              >
                                <td class="font-weight-medium">{{ i + 1 }}</td>
                                <td>{{ regel.omschrijving }}</td>
                                <td class="text-right">{{ regel.aantal === 0 ? '' : regel.aantal }}</td>
                                <td
                                    v-if="isShowPrice"
                                    class="text-right"
                                ><b>{{ regel.totaal | getEuro }}</b>
                                  <span
                                      v-if="regel.kortings_percentage > 0"
                                  >
                                    <br/>(-&nbsp;{{ regel.kortings_percentage }}%)
                                  </span>
                                  <span
                                      class="error--text"
                                      v-if="regel.kortings_percentage < 0"
                                  >
                                    <br/>(+&nbsp;{{ -regel.kortings_percentage }}%)
                                  </span>
                                </td>
                              </tr>
                              <tr
                                  v-if="isShowPrice && task.order.totaal_inclusief_btw"
                              >
                                <td></td>
                                <td
                                    v-if="!!task.order.totaal_exclusief_btw && !!(task.order.totaal_inclusief_btw - task.order.totaal_exclusief_btw)"
                                    class="text-right"
                                >
                                  {{ task.order.totaal_exclusief_btw | getEuro }}&nbsp;+
                                  {{ (task.order.totaal_inclusief_btw - task.order.totaal_exclusief_btw) | getEuro }}
                                </td>
                                <td v-else></td>
                                <td class="text-right">{{
                                    !!task.order.totaal_exclusief_btw && !!(task.order.totaal_inclusief_btw - task.order.totaal_exclusief_btw) ? "=" : ""
                                  }}</td>
                                <td class="text-right"><b>{{ task.order.totaal_inclusief_btw | getEuro }}</b></td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </span>

                <!--                <span v-else-if="task.type === 'Total'">-->
                <!--                  <v-card-subtitle>Totaal:</v-card-subtitle>-->
                <!--                  <v-card-text>...</v-card-text>-->
                <!--                </span>-->
              </v-card>
            </span>
              <!--            </transition-group>-->
            </draggable>
            <!--              empty-insert-threshold="200"-->
            <draggable id="drag-board-empty-end"
                       v-else
                       v-model="board.tasks"
                       animation="150"
                       handle=".handle"
                       @change="m_dragUpdate"
                       ghost-class="ghost-card"
                       group="tasks"
                       class="d-flex flex-column fill-height"
            ></draggable>
            <!--          </v-list-item-group>-->
          </v-col>
        </v-row>

        <!--DEBUG-->
        <v-row
            v-if="appDevMessages"
            id="preview-block"
        >
          <v-col id="order-preview" cols="6">
            <pre>Totaal orders: <b>{{ ss_GetVerkoopOrdersLijst.length }}</b></pre>
            <pre>Administratie orders: <b>{{ comp_VerkoopOrdersFromCurAdm.length }}</b></pre>
            <!--    <pre>Eerste order: {{ comp_VerkoopOrdersFromCurAdm[0] }}</pre>  -->

            <v-card
                elevation="2"
                class="mx-auto"
            >
              <v-virtual-scroll
                  bench="128"
                  :items="comp_VerkoopOrdersFromCurAdm"
                  height="512"
                  item-height="64"
              >
                <template v-slot:default="{ item }">
                  <v-list-item :key="item.id">
                    <v-btn
                        small
                        class="mr-2"
                    >
                      <v-icon>
                        {{ processStatusIconList[item.order_status] }}
                      </v-icon>
                      {{ processStatusSearchList[item.order_status] }}
                    </v-btn>

                    <v-list-item-action
                        class="mx-4"
                    >
                      <v-chip
                          small
                          color="primary"
                      >
                        {{ item.nummer }}
                      </v-chip>
                    </v-list-item-action>

                    <v-list-item-content>

                      <v-list-item-title>
                        <strong>{{ item.datum }}</strong>
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        <b>{{ item.totaal_exclusief_btw | getEuro }}</b> <i>{{ item.omschrijving }}</i>
                      </v-list-item-subtitle>

                    </v-list-item-content>

                    <v-list-item-action>
                      <!--                    <v-icon small>-->
                      <!--                      mdi-open-in-new-->
                      <!--                    </v-icon>-->
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider></v-divider>
                </template>
              </v-virtual-scroll>
            </v-card>

          </v-col>

          <v-col id="artikelen-preview" cols="6">
            <pre>Totaal artikelen: <b>{{ ss_GetArtikelenLijst.length }}</b></pre>
            <pre>Administratie artikelen: <b>{{ comp_ArtikelenFromCurAdm.length }}</b></pre>
            <!--          <pre>Eerste artikel: {{ comp_ArtikelenFromCurAdm[0] }}</pre>-->

            <v-card
                elevation="2"
                class="mx-auto"
            >
              <v-virtual-scroll
                  bench="128"
                  :items="comp_ArtikelenFromCurAdm"
                  height="512"
                  item-height="64"
              >
                <template v-slot:default="{ item }">
                  <v-list-item :key="item.id">
                    <v-list-item-action
                        style="min-width:128px;"
                    >
                      <v-chip
                          small
                          color="primary"
                      >
                        {{ item.artikelcode }}
                      </v-chip>
                    </v-list-item-action>

                    <v-list-item-content>

                      <v-list-item-title>
                        <strong>{{ item.verkoopprijs | getEuro }}</strong>
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        <b>{{ item.omzetgroep.omschrijving }}</b>: {{ item.omschrijving }}
                      </v-list-item-subtitle>

                    </v-list-item-content>

                    <v-list-item-action>
                      <!--                    <v-icon small>-->
                      <!--                      mdi-open-in-new-->
                      <!--                    </v-icon>-->
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider></v-divider>
                </template>
              </v-virtual-scroll>
            </v-card>

          </v-col>
          <v-card>
            <v-card-subtitle>boards</v-card-subtitle>
            <v-card-subtitle>Boards Set:</v-card-subtitle>
            <v-card-text>
              {{ boardsSet }}
            </v-card-text>
            <v-card-text
                v-for="o in boards"
                :key="o.uuid"
            >
              <!--            <pre>{{ o }}</pre>-->
            </v-card-text>
          </v-card>
          <v-col>
            <pre>{{ ss_GetVerkoopOrdersUpdateLog }}</pre>
          </v-col>
          <!--        <v-card>-->
          <!--          <v-card-subtitle>getKanbanBoarden</v-card-subtitle>-->
          <!--          <v-card-text-->
          <!--              v-for="o in getKanbanBoarden"-->
          <!--              :key="o.uuid"-->
          <!--          >-->
          <!--            <pre>{{ o }}</pre>-->
          <!--          </v-card-text>-->
          <!--        </v-card>-->
        </v-row>
      </v-container>

      <div style="display:none;" id="printMe2">
        <pre>{{ ss_GetVerkoopOrdersUpdateLog }}</pre>
      </div>

      <!--      <div style="display:none;" id="printTest">-->
      <!--        <h1>Title H1</h1>-->
      <!--        <p>Text</p>-->
      <!--      </div>-->

      <!--      <v-btn @click="print('printTest')">Print me!</v-btn>-->

      <v-snackbar
          v-model="showAdresCopyed"
          top
      >
        <!--        absolute    -->
        Adres is gekopieerd
      </v-snackbar>

    </v-container>
  </div>
</template>

<script lang="js">

import { mapGetters } from "vuex";
import draggable from "vuedraggable";

import {
  processStatusIconList, processStatusListEnabled,
  processStatusSearchList,
} from "../utils/constants";

import common from "../utils/common";

// import OrdersLijst from "./OrdersLijst.vue";
// import DataTableRowHandler from "@/components/DataTableRowHandler";

// declare interface Test {
//     cod: string,
//     param: string
// }

export default {
  name: "OrderPlanner",

  components: {
    // OrdersLijst,
    draggable,
    // DataTableRowHandler,
  },

  /**
   * 8888888b.         d8888 88888888888        d8888
   * 888  "Y88b       d88888     888           d88888
   * 888    888      d88P888     888          d88P888
   * 888    888     d88P 888     888         d88P 888
   * 888    888    d88P  888     888        d88P  888
   * 888    888   d88P   888     888       d88P   888
   * 888  .d88P  d8888888888     888      d8888888888
   * 8888888P"  d88P     888     888     d88P     888
   */

  data() {
    return {
      loadingInProgress: false,
      processStatusIconList,
      processStatusSearchList,
      processStatusListEnabled,
      processStatusColorsList: [
        '', // 0: Onbekend
        '', // 1: Order
        'primary',    // 2: Offerte
        'secondary',  // 3: Bevestiging
        'accent',     // 4: Werkbon
        'success',    // 5: Pakbon
        'warning',    // 6: Afhaalbon
        'extra',      // 7: Contantbon
        'error',      // 8: Factuur
      ],
      // test: new Array<Player>(),

      // SETTINGS
      // Naam van omzetgroep met transport artikelen
      settingsTransportOmzetGroep: 'Transport',
      settingsTransportExtraVeld: 'Transportnaam',
      settingsRoutenummerExtraVeld: 'Routenummer',
      settingsMandatoryExtravelden: [
        'Transportnaam', 'Routenummer',
        // this.data().settingsTransportExtraVeld,
        // this.data().settingsRoutenummerExtraVeld,
      ],
      // Default board struct
      settingsDefaultBoard: {
        uuid: null,             // Artikel UUID
        naam: "Orders",         // Naam van Board
        color: null,            // Custom Board color
        expanded: true,         // Is group expanded?
        snelstart_id: null,
        // tasks: [],              // Cards list -> Orders
        // tasksSet: new Set(),    // Set([uuid]) -> fast search
      },
      settingsDefaultTask: {
        // uuid: null,
        pinned: false,
        // expanded: false,
      },

      // Order filter
      // settingsOrderFiltersSelected: [],

      settingsOrderFiltersList: [
        {
          label: 'Ordernummer',
          field: 'nummer',
          selected: true,
        },
        {
          label: 'Omschrijving',
          field: 'omschrijving',
          selected: true,
        },
        {
          label: 'Order memo',
          field: 'memo',
          selected: false,
        },
        {
          label: 'Relatienaam',
          field: 'relatie.naam',
          selected: false,
        },
        {
          label: 'Relatiecode',
          field: 'relatie.relatiecode',
          selected: false,
        },
        {
          label: 'Contactpersoon',
          field: 'afleveradres_contactpersoon',
          selected: false,
        },
        {
          label: 'Straat',
          field: 'afleveradres_straat',
          selected: false,
        },
        {
          label: 'Postcode',
          field: 'afleveradres_postcode',
          selected: false,
        },
        {
          label: 'Plaats',
          field: 'afleveradres_plaats',
          selected: false,
        },

      ],

      orderFilterText: '',
      orderFilterTextLow: '',
      orderFilterOn: false,
      orderFilterTimerID: null,

      // Colors
      settingsCardOpacity: 0.85,
      settingsBoardColorExtraveldNaam: 'BoardColor',
      settingsBoardColorAlpha: '40',

      // Process Status Filter
      settingsProcessOrderEnabled: [1, 2, 3, 4, 5, 6],   // Zie: processStatusSearchList, ex bon en factuur
      // settingsProcessOrderSelected: [],

      // Hoofd kanban board
      boards: [],
      // Index -> Set
      boardsSet: new Set(),
      // Cache -> Dict
      boardsDict: {},

      // pickerOrderDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // pickerOrderDate: (new Date(Date.now())).toISOString().substr(0, 10),
      pickerOrderDate: null,
      // pickerOrderDateX: this.moment(),
      pickerOrderModal: false,

      // Price / Adres / Regels / Hide
      showToggle: [0, 1, 2, 3],

      // Show Copy tooltip
      showAdresCopyed: false,

      // Route bewaren in progress
      routeBewarenProgress: false,
      // showPrice: true,
      // showAdres: true,
      // showOrderRegels: true,

      // allowDrag: true,
      // selected: [],

      zoekOptionsMenuModel: false,

      printOptions: {
        name: '_blank',
        specs: [
          'fullscreen=no',
          'titlebar=yes',
          'scrollbars=yes',
          'top=100',
          'left=100',
          'width=1000',
          'height=600',
          // 'noreferrer=yes',
        ],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css',
          // 'https://unpkg.com/kidlat-css/css/kidlat.css',
          // 'https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.6.12/vuetify.min.js',
          // '../assets/print.css',
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: false, // if false, the window will not close after printing
        windowTitle: 'Route print:' + window.document.title, // override the window title
      },
      adresComponents: [
        'naam',
        'vestigings_straat',
        'vestigings_postcode',
        'vestigings_plaats',
      ],
      qrSettings: {
        size: 160,
        chartAPI: 'https://chart.googleapis.com/chart?',
      },
    } // return
  }, // data

  async mounted() {
    await this.m_LoadSnelStartData()
    await this.m_updateBoardInfoAsync()
  }, // MOUNTED

  computed: {
    ...mapGetters({
      appGuiCompact: "app/GET_GUI_COMPACT",
      appDevMessages: "app/GET_DEV_MESSAGES",
      ss_GetVerkoopOrdersLijst: "snelstart/GET_VERKOOP_ORDERS",
      ss_GetVerkoopOrdersUpdateLog: "snelstart/GET_VERKOOP_ORDERS_UPDATE_LOG",
      ss_GetSjablonenLijst: "snelstart/GET_ORDER_SJABLONEN",
      ss_GetArtikelenLijst: "snelstart/GET_ARTIKELEN",
      ss_GetKlantenLijst: "snelstart/GET_KLANTEN",

      ss_GetGlobalProgressFlag: "snelstart/GET_GLOBAL_PROGRESS_FLAG",
      user_GetCurrentSnelStart: "user/GET_CURRENT_SNELSTART",
      user_GetSelectedAdministratieID: "user/GET_SELECTED_ADMINISTRATIE_ID",
    }),

    isShowPrice() {
      return this.showToggle.includes(0)
    },
    isShowAdres() {
      return this.showToggle.includes(1)
    },
    isShowOrderRegels() {
      return this.showToggle.includes(2)
    },
    isShowAllOrders() {
      return this.showToggle.includes(3)
    },

    // TEST
    isChangeOrders() {
      return this.ss_GetVerkoopOrdersLijst.length * 11
          + this.ss_GetArtikelenLijst.length
    },

    getKanbanBoarden() {
      return this.ss_GetArtikelenLijst
      .filter(i =>
          (i.omzetgroep.omschrijving === this.settingsTransportOmzetGroep
              && i.snelstart_id === this.user_GetSelectedAdministratieID)
      )
    },

    // sampleOrderData: {
    //   get() {
    //     console.log('GET: sampleOrderData')
    //     return this.$store.getters["planner/GET_PLANNER_SAMPLE"]
    //   },
    //   set(value) {
    //     console.log('SET: sampleOrderData')
    //     this.$store.commit("planner/SET_PLANNER_SAMPLE", value)
    //   }
    // },

    comp_ArtikelenFromCurAdm() {
      return this.ss_GetArtikelenLijst
      .filter(art => art.snelstart_id === this.user_GetCurrentSnelStart.id)
      .filter(art => art.actief) || []
    },

    comp_VerkoopOrdersFromCurAdm() {
      return this.ss_GetVerkoopOrdersLijst
      .filter(order => order.snelstart_id === this.user_GetCurrentSnelStart.id)
      .filter(order => order.nummer !== null)
    },

    comp_alleOrdersDatum() {
      return new Set(this.comp_VerkoopOrdersFromCurAdm.map(x => x.datum))
    },

    comp_tasks4Update() {
      let countTasks = 0
      for (let b of this.boards) {
        countTasks += b.tasks.filter(t => t.update).length
      }
      return countTasks
    },

    // comp_boardFiltered() {
    //   return this.boards.map((b) => {
    //     return {
    //       uuid: b.uuid,
    //       naam: b.naam,
    //       color: b.color,
    //       expanded: b.expanded,
    //       snelstart_id: b.snelstart_id,
    //       tasks: b.tasks.filter((t) => !this.pickerOrderDate || t.order.datum === this.pickerOrderDate),
    //       tasksSet: b.tasksSet,
    //     }
    //   })
    // },

  }, // COMPUTED

  watch: {
    // Orders zijn aangepast
    isChangeOrders() {
      console.log('Change... isChangeOrders')
      this.m_updateBoardInfoAsync()
    },

    // Andere SnelStart Administratie is geselecteerd
    user_GetCurrentSnelStart() {
      // Reset Board
      this.boardsSet = new Set()
      this.boardsDict = {}
      this.boards = []

      // Reset Order Datum
      this.pickerOrderDate = null

      // Bouwen Board
      this.m_updateBoardInfoAsync()
    },

    orderFilterText() {
      this.orderFilterOn = (this.orderFilterText && this.orderFilterText.length > 0)
      // console.log('Filter aan:', this.orderFilterOn)

      if (this.orderFilterOn) {
        this.orderFilterTextLow = this.orderFilterText.toLowerCase()
      } else {
        this.orderFilterTextLow = ''
      }

      // console.log('Filter string:', this.orderFilterTextLow)

      if (this.orderFilterTimerID) {
        clearTimeout(this.orderFilterTimerID)
      }
      this.orderFilterTimerID = setTimeout(() => {
        this.m_updateFilterFlag()
      }, 1500)
    },

  }, // WATCH

  methods: {
    async print(el) {
      await this.$htmlToPaper(el, this.printOptions)
    },

    makeGoogleMapLink(relatie) {
      if (relatie) {
        const adres = []

        this.adresComponents.forEach(c => {
          if (Object.hasOwn(relatie, c)) {
            if (relatie[c]) {
              adres.push(relatie[c].trim().replaceAll(' ', '+'))
            }
          }
        })
        const adresString = adres.join(',')
        // console.log('Test:', adresString)
        return `https://www.google.com/maps/dir/?api=1&destination=${adresString}`
      } else {
        return null
      }
    },

    makeQRCodeLink(relatie) {
      if (relatie) {
        // TODO: str -> uri
        const qr = this.qrSettings
        // const uri = encodeURIComponent(this.makeGoogleMapLink(relatie))
        const uri = encodeURI(this.makeGoogleMapLink(relatie))
        let options = new URLSearchParams()
        options.append('cht', 'qr')
        options.append('chs', `${qr.size}x${qr.size}`)
        // options.append('chld', '0')
        options.append('chl', uri)

        // const qrl = `https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl=${uri}`
        const qrl = `${qr.chartAPI}${options.toString()}`
        // console.log("QR link:", qrl)
        return qrl
      } else {
        return null
      }
    },

    /**
     * Alle SnelStart gegevens voor bestel formulier laden
     *
     * @param forceLoad - altijd opnieuw lezen
     * @returns {Promise<void>}
     */
    async m_LoadSnelStartData(forceLoad = false) {

      if (this.inProgressLoad) {
        common.fn.addMessage2Drawer(
            `Operatie is nog in progress... Wacht`,
            common.errorLevels.Info,
            'SnelStart gegevens laden',
            true
        )
        return
      }

      this.inProgressLoad = true

      // this.resetSelected()
      // this.m_InitKlantSettingsGegevens()

      if (this.user_GetCurrentSnelStart) {
        const filteredArtikelsEmpty =
            this.ss_GetArtikelenLijst
            .filter(i => i.snelstart_id === this.user_GetCurrentSnelStart.id).length === 0

        const filteredSjablonenEmpty =
            this.ss_GetSjablonenLijst
            .filter(i => i.snelstart_id === this.user_GetCurrentSnelStart.id).length === 0

        const filteredKlantenEmpty =
            this.ss_GetKlantenLijst
            .filter(i => i.snelstart_id === this.user_GetCurrentSnelStart.id).length === 0

        const filteredVerkoopOrdersEmpty =
            // this.comp_FilteredVerkoopOrders.length === 0
            this.comp_VerkoopOrdersFromCurAdm.length === 0

        // console.log('filteredArtikelsEmpty', filteredArtikelsEmpty)
        // console.log('filteredSjablonenEmpty', filteredSjablonenEmpty)
        // console.log('filteredKlantenEmpty', filteredVerkoopOrdersEmpty)
        // console.log('filteredVerkoopOrdersEmpty', filteredVerkoopOrdersEmpty)

        if (forceLoad
            || filteredArtikelsEmpty
            || filteredSjablonenEmpty
            || filteredVerkoopOrdersEmpty
            || filteredKlantenEmpty
        ) {
          // Lees artikelen opnieuw
          // const artikelAPI = `${this.rootURI}api/artikelen/?snelstart_id=${this.user_GetCurrentOperator.id}`
          this.$store.dispatch("user/REFRESH_TOKEN").then(() => {

            // ARTIKELEN
            // Lees artikelen, als lijst leeg of force reload
            if (filteredArtikelsEmpty || forceLoad) {
              this.$store.dispatch(
                  "snelstart/READ_ARTIKELEN_LIST",
                  {snelstart_id: this.user_GetCurrentSnelStart.id}
              )
              .catch(e => {
                common.fn.addMessage2Drawer(
                    `Artikelen lezen fout ${e}`,
                    common.errorLevels.Warning,
                    'Artikelen lezen',
                    true
                )
              })
              .finally(() => {
                // this.inProgressSendOut()
                setTimeout(() => {
                  this.inProgressLoad = false
                }, 700)
              })
            }

            // KLANTEN
            // Lees klanten, als lijst leeg of force reload
            this.bedrijfLijstSelected = null

            if (filteredKlantenEmpty || forceLoad) {
              this.$store.dispatch(
                  "snelstart/READ_KLANTEN_LIST",
                  {snelstart_id: this.user_GetCurrentSnelStart.id}
              )
              .catch(e => {
                common.fn.addMessage2Drawer(
                    `Relaties lezen fout ${e}`,
                    common.errorLevels.Warning,
                    'Relaties lezen',
                    true
                )
              })
              .finally(() => {
                // this.inProgressSendOut()
                setTimeout(() => {
                  this.inProgressLoad = false
                }, 700)
              })
            }

            // SJABLONEN
            // Lees sjablonen, als lijst leeg of force reload
            if (filteredSjablonenEmpty || forceLoad) {
              this.$store.dispatch(
                  "snelstart/READ_ORDER_SJABLONEN",
                  {snelstart_id: this.user_GetCurrentSnelStart.id}
              )
              .catch(e => {
                common.fn.addMessage2Drawer(
                    `Order sjablonen lezen fout ${e}`,
                    common.errorLevels.Warning,
                    'Verkooporder sjablonen lezen',
                    true
                )
              })
              .finally(() => {
                // this.inProgressSendOut()
                setTimeout(() => {
                  this.inProgressLoad = false
                }, 700)
              })
            }

            // VERKOOPORDER
            // Lees VerkoopOrders, als lijst leeg of force reload
            if (filteredVerkoopOrdersEmpty || forceLoad) {
              this.$store.dispatch(
                  "snelstart/READ_VERKOOP_ORDERS",
                  {snelstart_id: this.user_GetCurrentSnelStart.id}
              )
              .catch(e => {
                common.fn.addMessage2Drawer(
                    `Verkooporders lezen fout ${e}`,
                    common.errorLevels.Warning,
                    'Verkooporders lezen',
                    true
                )
              })
              .finally(() => {
                // this.inProgressSendOut()
                setTimeout(() => {
                  this.inProgressLoad = false
                }, 700)
              })
            }

          }).catch(e => {
            common.fn.addMessage2Drawer(
                `Token refresh fout ${e}`,
                common.errorLevels.Error,
                'Access token refresh',
                true
            )
          }).finally(() => {
            // this.inProgressSendOut()
            setTimeout(() => {
              this.inProgressLoad = false
            }, 700)
          })
        }

        // preview optie uitschakelen
        this.previewMode = false
        this.currentSjabloonId = this.prop_CurrentSjabloonID

        // this.artikelenLocal = Object.assign([{basket: null}], this.artikelen.filter(i => i.snelstart_id === this.user_GetCurrentOperator.id))
        // // this.artikelenLocal.forEach(i => i.basket = null)
        // this.basketCalc()
      } else {
        common.fn.addMessage2Drawer(
            'SnelStart administratie in niet gekozen',
            common.errorLevels.Warning,
            'loadArtikelen',
            true
        )
      }
      // this.inProgressSendOut(700)
      setTimeout(() => {
        this.inProgressLoad = false
      }, 700)
    },

    moveOrder2Board(taskUUID, fromBoardUUID, toBoardUUID) {
      // console.log(taskUUID, fromBoardUUID, toBoardUUID)

      setTimeout(() => {
        const fromBoard = this.boardsDict[fromBoardUUID]
        const toBoard = this.boardsDict[toBoardUUID]
        const taskIndex = fromBoard.tasks.findIndex(i => i.uuid === taskUUID)
        toBoard.tasks.push(fromBoard.tasks[taskIndex])
        fromBoard.tasks.splice(taskIndex, 1)

        this.m_updateOrdersRoutenummer(toBoard)
        // fromBoard.tasksSet.delete(taskUUID)
        // toBoard.tasksSet.add(taskUUID)
      }, 0)
    },

    // tasksFiltered(board) {
    //   return board.tasks.filter((t) => !this.pickerOrderDate || t.order.datum === this.pickerOrderDate)
    // },

    m_memo2Multi(memo) {
      // Replace all \r\n -> <br/>
      return memo.replace(/\r?\n/g, "<br/>")
    },

    m_getLocalShortWeekday(date) {
      try {
        const date_parse = new Date(Date.parse(date))
        return date_parse.toLocaleDateString(
            'nl',
            {
              weekday: 'short',
            }
        ).toUpperCase()   // CAPS
      } catch {
        return ''
      }
    },

    m_getLocalDate(date, options = {day: 'numeric', month: 'short'}) {
      const yearNow = new Date().getFullYear()

      try {
        const date_parse = new Date(Date.parse(date))

        if (date_parse.getFullYear() !== yearNow && !Object.hasOwn(options, 'year')) {
          options.year = 'numeric'
        }
        return date_parse.toLocaleDateString('nl', options)
      } catch (e) {
        console.log(e)
        return '<span style="font-size: xx-small; color:red;">ongeldige datum</span>'
      }
    },

    m_dragUpdate(evt) {
      // console.log('Drag =>')
      // console.dir(evt)

      let uuid
      let board

      if (Object.hasOwn(evt, 'moved')) {
        uuid = evt.moved.element.uuid
        // console.log('=> MOVE ', uuid)

        board = this.m_searchBoardFromTaskList(uuid)
        if (!board) {
          console.error(`Board voor id=${uuid} is niet gevonden`)
          return
        }
        const board_len = this.countTasksShow(board.tasks)

        let from_ind = evt.moved.oldIndex
        let to_inx = evt.moved.newIndex

        if (to_inx < 0) {
          to_inx = 0
        } else if (to_inx >= board_len) {
          to_inx = board_len - 1
        }

        if (from_ind === to_inx) {
          // console.log('Item blijft op hetzelfde positie')
          return
        } else {
          console.log(`Item ${uuid} Moved from ${from_ind} to ${to_inx}`)
        }
      } // MOVED

      if (Object.hasOwn(evt, 'added')) {
        uuid = evt.added.element.uuid
        board = this.m_searchBoardFromTaskList(uuid)

        // console.log('++ ADDED ', uuid)
        // console.log(`+ Add to board ${board.naam}`)

        // this.m_searchBoardFromTaskList(uuid).tasksSet.add(uuid)

        // console.log(`Item ${uuid} Added to ${evt.added.newIndex}`)
      } // ADD

      if (Object.hasOwn(evt, 'removed')) {
        uuid = evt.removed.element.uuid
        board = this.m_searchBoardFromTaskList(uuid)

        // console.log('-- REMOVED ', uuid)
        // console.log(`- Removed from board ${board.naam}`)

        // this.m_searchBoardFromTaskList(uuid).tasksSet.delete(uuid)

        // console.log(`Item ${uuid} Removed from ${evt.removed.oldIndex}`)
      } // REMOVE

      this.m_updateOrdersRoutenummer(board)
    },

    m_dragTest(evn) {
      console.log('@Drag=>', evn)
    },

    m_updateOrdersRoutenummer(board) {
      // console.log(`Sort board ${board.naam}`)
      let task_inx = 1
      for (let t of board.tasks) {
        // console.log('Task', t)

        // Zet Nieuw routenummer
        if (board.uuid && this.isOrderShow(t)) {
          t.route = task_inx++
        }
        // Moet geupdate flag
        t.update = this.m_mustOrderUpdated(board, t)
      }
    },

    m_isOrderRelatieNaam(order) {
      return order.relatie && order.relatie.naam
    },

    m_getOrderRelatieNaam(order) {
      return this.m_isOrderRelatieNaam(order) ? order.relatie.naam : ''
    },

    m_isOrderRelatieNaamEnContactSame(order) {
      if (this.m_isOrderRelatieNaam(order)) {
        return (this.m_getOrderRelatieNaam(order) === order.afleveradres_contactpersoon)
      }
      return false
    },

    m_addBoard(board_obj) {
      // Naam zoeken
      if (!board_obj.naam) {
        const artikel = this.ss_GetArtikelenLijst.find(a => a.uuid === board_obj.uuid)
        if (artikel) {
          board_obj.naam = artikel.omschrijving
        }
      }
      // Add or update board
      if (this.boardsSet.has(board_obj.uuid)) {
        // UPDATE
        const board = this.boardsDict[board_obj.uuid]
        // Update naam
        if (board_obj.naam && (board.naam === board.uuid)) {
          board.naam = board_obj.naam
          board.color = board_obj.color
          return board
        } else {
          return null
        }
      } else {
        // ADD
        const new_board = {
          uuid: board_obj.uuid,
          naam: board_obj.naam || board_obj.uuid,
          expanded: true,
          color: board_obj.color,
          snelstart_id: board_obj.snelstart_id,
          tasks: [],
          // tasksSet: new Set(),
        }

        this.boardsSet.add(new_board.uuid)
        this.boardsDict[new_board.uuid] = new_board
        this.boards.push(new_board)

        // console.log('add board', new_board.naam)
        // Add empty order to board
        // this.m_addOrder2Board({}, new_board)

        return new_board
      }
    },

    m_searchBoardFromTaskList(taskUUID) {
      for (let b of this.boards) {
        const task = b.tasks.find(t => t.uuid === taskUUID)
        if (task) {
          return b
        }
      }
      return null
    },

    m_addOrder2Board(order, boardUUID) {
      // Board bestaat niet, voeg nieuw toe
      this.m_addBoard(
          {
            uuid: boardUUID,
            naam: null,
            snelstart_id: this.user_GetSelectedAdministratieID
          }
      )
      // Order -> board
      // Zoek in alle boarden
      let board = this.m_searchBoardFromTaskList(order.uuid)
      // Als niet bestaat, lees nieuw doel board
      if (board === null) {
        board = this.boardsDict[boardUUID]
      }

      const task = board.tasks.find(t => t.uuid === order.uuid)
      // Taak is al in de lijst
      if (task) {
        return task
      } else {
        // Nieuw taak maken
        const sjablonen = this.ss_GetSjablonenLijst
        const sjabloon = sjablonen.find(s => s.id === order.verkoopordersjabloon_id)

        const task = {
          uuid: order.uuid,
          // Order
          order: order,
          // Routenummer lezen
          route: Number(this.m_getOrderHoofdExtraveld(order, this.settingsRoutenummerExtraVeld) || 0),
          // Drag Pin
          pinned: false,
          // Kan move
          movable: this.m_hasOrderAlleExtravelden(order) && ![7, 8].includes(order.order_status),
          // Verkooporder sjabloon naam
          sjabloon: sjabloon ? sjabloon.omschrijving : null,
          // Laatste update status
          // status: 'ok', 'error', 'warning', 'none'
          status: 'none',
          // In progress?
          progress: false,
          // Moet geupdate?
          update: false,
          // Filter flag
          found: false,
        }
        // this.settingsDefaultTask,
        // )
        // board.tasksSet.add(order.uuid)
        board.tasks.push(task)

        this.m_sortTaskBoard(board.tasks)

        return task
      }
    },

    m_getProgressStatusColor(task) {
      const colors = {
        'ok': 'success',
        'warning': 'warning',
        'error': 'error',
        'none': 'gray',
      }
      const status = this.m_getTaskStatus(task)

      if (status) {
        return colors[status]
      } else return '';
    },

    m_sortTaskBoard(tasks) {
      tasks.sort((a, b) => a.route - b.route)
    },

    async m_updateBoardInfoAsync() {
      if (!this.loadingInProgress) {
        this.loadingInProgress = true
        setTimeout(() => {
          this.m_updateBoardInfo()
        }, 0)
      }
    },

    m_updateBoardInfo() {
      // this.boards = []
      // this.boardsSet = new Set()
      console.log('Call m_updateBoardInfo()')

      // Is geen board, maak default board "Orders" met uuid: null
      if (this.boards.length === 0) {
        // Add empty board
        this.m_addBoard({
          uuid: null,
          naam: "Orders",
          color: "#808080",
          snelstart_id: this.user_GetSelectedAdministratieID,
        })
      }

      // Voeg artikel naar board als niet bestaat
      this.getKanbanBoarden.forEach(el => {
        console.log('Update board', el.omschrijving)
        this.m_addBoard(
            {
              uuid: el.uuid,
              naam: el.omschrijving,
              snelstart_id: el.snelstart_id,
              color: el['extra_velden_' + this.settingsBoardColorExtraveldNaam]
            }
        )
      })

      const verkooporders = this.comp_VerkoopOrdersFromCurAdm

      for (let order of verkooporders) {
        // Transport extraveld
        let board_uuid = this.m_getOrderHoofdExtraveld(
            order,
            this.settingsTransportExtraVeld,
        )
        // if (this.isOrderShow(order)) {
        this.m_addOrder2Board(order, board_uuid)
        // }
      }

      this.loadingInProgress = false
      console.log('Exit m_updateBoardInfo()')
    },

    isOrderShow(task) {
      return (!this.pickerOrderDate || task.order.datum === this.pickerOrderDate)
          && this.settingsProcessOrderEnabled.includes(task.order.order_status)
          && (this.isShowAllOrders || task.movable)
          && (task.order.snelstart_id === this.user_GetCurrentSnelStart.id)

      // if (show) {
      //   // Filter is actief
      //   if (this.orderFilterOn) {
      //     const board = this.m_searchBoardFromTaskList(task.uuid)
      //     // Order board
      //     if (board && !board.uuid) {
      //       return this.isTaskFiltered(task)
      //     }
      //   }
      // }

      // return show
    },

    isTaskFiltered(task) {
      /**
       Past task in filter

       Return:
       - true    - Zit in filter criteria
       - false   -
       */
      // Alle filters
      for (let flt of this.settingsOrderFiltersList) {
        // Is filter actief
        if (flt.selected) {
          // Heeft order dit veld
          const fieldNames = flt.field.split('.')

          let field = task.order

          // console.dir(field)

          if (field) {
            for (let i of fieldNames) {
              if (Object.hasOwn(field, i)) {
                field = field[i]
                if (!field) {
                  field = ''
                  break
                }
              } else {
                field = ''
                break
              }
            }

            if (field) {
              field = String(field).toLowerCase()
              // Zoeken
              if (field.length > 0 && field.search(this.orderFilterTextLow) !== -1) {
                // console.log('Zoek', this.orderFilterText, 'in', field)
                return true
              }
            }
          }
        }
      }
      return false
    },

    m_updateFilterFlag() {
      if (this.orderFilterOn) {
        for (let board of this.boards) {
          board.tasks.forEach(task => {
            // setTimeout(() => {
            task.found = this.isTaskFiltered(task)
            // }, 0)
          })
        }
      } else {
        for (let board of this.boards) {
          board.tasks.forEach(task => {
            task.found = false
          })
        }
      }

    },


    countTasksShow(tasks) {
      return (tasks.filter((t) => this.isOrderShow(t))).length
    },

    m_hasOrderAlleExtravelden(order) {
      if ('extra_hoofd_velden' in order && Array.isArray(order.extra_hoofd_velden)) {
        for (let extraveld_naam of this.settingsMandatoryExtravelden) {
          const veld = order.extra_hoofd_velden.filter(i => i.omschrijving === extraveld_naam)
          if (veld.length !== 1) {
            return false
          }
        }
      }
      return true
    },

    m_getOrderHoofdExtraveld(order, extraveld_naam) {
      if ('extra_hoofd_velden' in order && Array.isArray(order.extra_hoofd_velden)) {
        const veld = order.extra_hoofd_velden.filter(i => i.omschrijving === extraveld_naam)
        if (veld.length === 1 && 'waarde' in veld[0]) {
          return veld[0].waarde
        }
      }
      return null
    },
    /**
     * Kopie afleveradres naar klembord
     *
     * @param task
     * @returns {Promise<void>}
     */
    async copyAdres(task) {
      const adres = []

      if (task.order) {
        // Copy tooltip
        setTimeout(() => {
          this.showAdresCopyed = true
        }, 0)

        const order = task.order

        // Relatie
        adres.push(this.m_isOrderRelatieNaam(order))
        // Contact
        if (!this.m_isOrderRelatieNaamEnContactSame(order)) {
          if (order.afleveradres_contactpersoon) {
            adres.push(order.afleveradres_contactpersoon)
          }
        }
        // Straat
        if (order.afleveradres_straat) {
          adres.push(order.afleveradres_straat)
        }
        // Postcode + Plaats
        const adres2 = `${order.afleveradres_postcode} ${order.afleveradres_plaats.toUpperCase()}`.trim()
        if (adres2) {
          adres.push(adres2)
        }
        // To clipboard
        await navigator.clipboard.writeText(adres.join('\r\n'))
      }
    },

    isDatumAllowed(val) {
      // comp_alleOrdersDatum
      // console.log(val)
      return this.comp_alleOrdersDatum.has(val)
    },

    isBoardEmpty(board) {
      return board.uuid && this.countTasksShow(board.tasks) === 0
    },

    // Remove item van autocomplete lijst
    // autocompleteRemove(id, selectedList) {
    //   return common.fn.autocompleteRemove(id, selectedList)
    // },

    async routeBewaren() {
      setTimeout(() => {
        this.routeBewarenProgress = true
      }, 0)

      let progress_timer_id = 0

      try {
        this.$store.dispatch("user/REFRESH_TOKEN").then(async () => {
          for (const board of this.boards) {
            for (const task of board.tasks) {
              if (task.update) {
                task.progress = true
                await this.$store.dispatch(
                    "snelstart/PUT_ORDER_EXTRAVELDEN",
                    {
                      snelstart_id: task.order.snelstart_id,
                      order_uuid: task.uuid,
                      extravelden_dict: {
                        [this.settingsRoutenummerExtraVeld]: task.route,
                        [this.settingsTransportExtraVeld]: board.uuid,
                      },
                      debug: true,
                    }
                ).then(() => {
                  console.log('routeBewaren(), Status:')

                  // const status = this.ss_GetVerkoopOrdersUpdateLog[task.uuid]
                  // console.dir(status)
                  // task.status =this.m_getShortStatus(status)

                  const order = this.ss_GetVerkoopOrdersLijst.find(o => o.uuid === task.uuid)

                  if (order) {
                    task.order = order
                  }
                  task.update = false
                })
                .catch(error => {
                  console.error('routeBewaren(): fout', error)
                  // task.status = 'error'
                })

                setTimeout(() => {
                  task.progress = false
                }, 1000)

                if (progress_timer_id > 0) {
                  clearTimeout(progress_timer_id)
                }
                progress_timer_id = setTimeout(() => {
                      this.routeBewarenProgress = false
                    },
                    2000
                )
              } // update
            } // for tasks
            // this.m_updateOrdersRoutenummer(board)
          } // for board
        }).catch(
            (e) => {
              this.routeBewarenProgress = false
              console.log('.catch()')
              console.error(e)
              common.fn.addMessage2Drawer(
                  `Fout: ${e}`,
                  common.errorLevels.Error,
                  'Order bewaren',
                  'Order planner',
                  true,
              )
            }
        ) // Token
      } catch (e) {
        this.routeBewarenProgress = false
        console.log('catch()')
        console.error(e)
        common.fn.addMessage2Drawer(
            `Fout: ${e}`,
            common.errorLevels.Error,
            'Order bewaren',
            'Order planner',
            true,
        )
      }
    },

    async saveOrderExtravelden(board, task) {
      setTimeout(() => {
        task.progress = true
      }, 0)

      this.$store.dispatch("user/REFRESH_TOKEN").then(async () => {
        await this.$store.dispatch(
            "snelstart/PUT_ORDER_EXTRAVELDEN",
            {
              snelstart_id: task.order.snelstart_id,
              order_uuid: task.uuid,
              extravelden_dict: {
                [this.settingsRoutenummerExtraVeld]: task.route,
                [this.settingsTransportExtraVeld]: board.uuid,
              },
              debug: true,
            }
        ).then(() => {
          console.log('saveOrderExtravelden, then(...)')

          // const status = this.ss_GetVerkoopOrdersUpdateLog[task.uuid]
          // console.log('Status', status)
          // task.status =this.m_getShortStatus(status)

          const order = this.ss_GetVerkoopOrdersLijst.find(o => o.uuid === task.uuid)

          if (order) {
            task.order = order
          }
          task.update = false
        })
        .catch(error => {
          console.error('ERROR: Order update fout', error)
        })
      })

      setTimeout(() => {
        task.progress = false
      }, 2000)
    },

    m_getTaskLog(uuid) {
      const logRecord = this.ss_GetVerkoopOrdersUpdateLog[uuid]
      if (logRecord) {
        if (logRecord.log) {
          return `<h3>${logRecord.message}</h3>` + this.multiline2HTML(logRecord.log)
        } else {
          return `<h3>Status code: ${logRecord.status} (${logRecord.statusText})</h3>` +
              logRecord.message
        }
      } else {
        return false
      }
    },

    m_getTaskStatus(task) {
      return this.m_getShortStatus(this.ss_GetVerkoopOrdersUpdateLog[task.uuid])
    },

    m_getShortStatus(status) {
      if (!status) {
        return 'none'
      } else if (status.result) {
        return status.result
      } else {
        return status.status < 300 ? 'ok' : (status.status >= 400 ? 'error' : 'warning')
      }
    },

    multiline2HTML(text) {
      return common.fn.multiline2HTML(text)
    },

    m_mustOrderUpdated(board, task) {
      const routeUpdate = task.route !== Number(this.m_getOrderHoofdExtraveld(task.order, this.settingsRoutenummerExtraVeld))
      const boardUpdate = board.uuid !== this.m_getOrderHoofdExtraveld(task.order, this.settingsTransportExtraVeld)
      return routeUpdate || boardUpdate
    },

    m_getBoardTotalArtikelen(board) {
      let aantal = 0.0
      for (let task of board.tasks) {
        if (this.isOrderShow(task)) {
          for (let regel of task.order.regels) {
            if (regel.aantal) {
              aantal += regel.aantal
            }
          }
        }
      }
      return aantal
    },

    m_getBoardTotalBedragEx(board) {
      let bedrag = 0.0
      for (let task of board.tasks) {
        if (this.isOrderShow(task)) {
          bedrag += task.order.totaal_exclusief_btw
        }
      }
      return common.euroFilter.format(bedrag) // '€' + bedrag.toFixed(2)
    },
    m_getBoardTotalBedragIncl(board) {
      let bedrag = 0.0
      for (let task of board.tasks) {
        if (this.isOrderShow(task)) {
          bedrag += task.order.totaal_inclusief_btw
        }
      }
      return common.euroFilter.format(bedrag) // '€' + bedrag.toFixed(2)
    },
    m_getBoardTotalBedragBTW(board) {
      let bedrag = 0.0
      for (let task of board.tasks) {
        if (this.isOrderShow(task)) {
          bedrag += task.order.totaal_inclusief_btw - task.order.totaal_exclusief_btw
        }
      }
      return common.euroFilter.format(bedrag) // '€' + bedrag.toFixed(2)
    },

  }, // METHODS

  filters: {  // GEEN 'this' in deze sectie‼
    // Convert cijfer naar Euro formaat: 1000.9500 -> '€ 1.000,95'
    getEuro: function (value) {
      value = parseFloat(value)
      return value && typeof (value) == 'number' ? common.euroFilter.format(value) : null
    },
  } // filters
}
</script>

<style scoped>

.inset-shadow-1 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2) inset,
  0 2px 2px 0 rgba(0, 0, 0, 0.14) inset,
  0 1px 5px 0 rgba(0, 0, 0, 0.12) inset;
}

.small-font.v-data-table td {
  font-size: 75%;
}

.small-padding.v-data-table td {
  padding: 0 4px;
}

.small-padding.v-data-table th {
  padding: 0 4px;
}

.ghost-card {
  opacity: 0.4;
}

.not-allowed-cursor {
  cursor: not-allowed;
}

.grab-cursor {
  cursor: grab;
}

.move-cursor {
  cursor: move;
}

</style>
