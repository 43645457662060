<template>
  <div>
    <!--      style="max-width:7em;"-->
    <v-col>
      <v-row>
        <!--      max-width="8em"-->
        <v-icon
            color="warning"
            @click="$vuetify.theme.dark=false"
            v-if="$vuetify.breakpoint.mdAndUp || $vuetify.theme.dark"
        >mdi-brightness-7
        </v-icon>
        <v-switch
            v-model="$vuetify.theme.dark"
            class="ml-3 hidden-sm-and-down"
            hide-details
            inset
        ></v-switch>
        <v-icon
            class="ml-n2 mr-2"
            color="grey"
            @click="$vuetify.theme.dark=true"
            v-if="$vuetify.breakpoint.mdAndUp || !$vuetify.theme.dark"
        >mdi-brightness-3
        </v-icon>
      </v-row>
    </v-col>
    <!--  </v-card>-->
  </div>
</template>

<script>
export default {
  name: 'brightness-switch',

  methods: {
    getPreferredColorScheme() {
      if (window.matchMedia) {
        if(window.matchMedia('(prefers-color-scheme: dark)').matches){
          return 'dark';
        } else {
          return 'light';
        }
      }
      return 'light';
    },

    registerMediaWatch() {
      if (window.matchMedia) {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener(
            'change',
            this.onColorSchemeChange
        )
      }
    },

    removeMediaWatch() {
      if (window.matchMedia) {
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener(
            'change',
            this.onColorSchemeChange
        )
      }
    },

    onColorSchemeChange() {
      this.$vuetify.theme.dark = (this.getPreferredColorScheme() === 'dark')
    },
  }, // methods

  mounted() {
    this.onColorSchemeChange()
    this.registerMediaWatch()
  },

  beforeDestroy() {
    this.removeMediaWatch()
  },
}
</script>