import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center",attrs:{"id":"profile"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":180,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mx-5",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VAvatar,[_c(VIcon,[_vm._v("mdi-account")])],1)],1)]}}]),model:{value:(_vm.profile),callback:function ($$v) {_vm.profile=$$v},expression:"profile"}},[_c(VCard,[_c(VList,[_c(VListItem,[_c(VListItemAvatar,[(_vm.userProfile.userprofile)?_c('img',{attrs:{"src":_vm.userProfile.userprofile.profile_image,"alt":_vm.userProfile.username}}):_vm._e()]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.userProfile.first_name)+" "+_vm._s(_vm.userProfile.last_name)+" ")]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.userProfile.username))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.userProfile.email))])],1),_c(VListItemAction,[_c(VBtn,{class:_vm.profileFav ? 'red--text' : '',attrs:{"icon":""},on:{"click":function($event){_vm.profileFav = !_vm.profileFav}}},[_c(VIcon,[_vm._v("mdi-heart")])],1)],1)],1),_c(VDivider),_c(VListItem,[_c(VListItemAction,[_c(VSwitch,{attrs:{"disabled":true,"color":"secondary"},model:{value:(_vm.profileHints),callback:function ($$v) {_vm.profileHints=$$v},expression:"profileHints"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Hints aan")])],1)],1),_c(VListItem,[_c(VListItemAction,[_c(VSwitch,{model:{value:(_vm.prop_GUICompact),callback:function ($$v) {_vm.prop_GUICompact=$$v},expression:"prop_GUICompact"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Compact")]),_c(VListItemSubtitle,[_vm._v("Mobiel / PC interface")])],1)],1),_c(VListItem,[_c(VListItemAction,[_c(VSwitch,{model:{value:(_vm.prop_DevMessages),callback:function ($$v) {_vm.prop_DevMessages=$$v},expression:"prop_DevMessages"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Ontwikkelingsberichten")]),_c(VListItemSubtitle,[_vm._v("Aan/uit blokken met extra gegevens")])],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","rounded":""},on:{"click":function($event){_vm.profile = false}}},[_vm._v("Ok")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }