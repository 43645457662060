<template>
  <v-list-item
      class="mx-8"
      :dense="GUI_COMPACT"
  >
    <v-list-item-icon class="mr-4">
      <v-icon
          v-if="link.icon"
          :large="!GUI_COMPACT"
          :color="link.color"
      >{{ link.icon }}
      </v-icon>
      <v-img
          v-else
          :src="link.svg"
          contain
          aspect-ratio="1"
          :max-width="GUI_COMPACT ? 24 : 36"
      />
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-text="link.text"></v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'HomeMenuItem',
  props: {
    link: {}
  },
  computed: {
    ...mapGetters({
      GUI_COMPACT: "app/GET_GUI_COMPACT",
    })
  }
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42B983;
}

</style>