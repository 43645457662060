// GLOBAL instellingen
import common from '../../utils/common';

/**

 .d8888b.  88888888888        d8888 88888888888 8888888888
d88P  Y88b     888           d88888     888     888
Y88b.          888          d88P888     888     888
 "Y888b.       888         d88P 888     888     8888888
    "Y88b.     888        d88P  888     888     888
      "888     888       d88P   888     888     888
Y88b  d88P     888      d8888888888     888     888
 "Y8888P"      888     d88P     888     888     8888888888

 */

const state = {
  // App Version
  appVersion: require('../../../package.json').version,

  // Drawer: zij panel aan/uit
  drawer: true,
  tips: false,
  lock: false,
  errorDrawer: false,

  // API link
  apiRootURI: 'https://portal.dkg.nl/',
  apiRootURIDebug: 'http://localhost:8000/',

  /** Create JWT: POST
   * { "username": "",
   *   "password": "" }
   * response => access, refresh JWT tokens https://jwt.io/
   */
  apiCreateJWT: 'auth/jwt/create',
  apiRefreshJWT: 'auth/jwt/refresh',

  // auth -> api, API heeft extra profile info: profile_image "uri", relatie [RelID,]
  apiUsersJWT: 'api/users/',

  uriProcess: 'api/achtergrond/',
  uriArtikelen: 'api/artikelen/',
  uriInzending: 'api/inzending/',
  uriKlantgegevens: 'api/klantgegevens/',
  uriModules: 'api/modules/',
  uriOperators: 'api/operator/',
  uriRelations: 'api/relatie/',
  uriSnelStart: 'api/snelstart/',
  uriVerkoopSjablonen: 'api/verkoopsjabloon/',
  uriVerkoopOrders: 'api/verkooporders/',
  uriVerkoopOrderExtravelden: 'api/rpc_verkooporder_extravelden_update/',

  // Global API timeout
  apiTimeout: 60000,

  logBook: [],
  // Logboek structuur
  logBookSchema: {
    title: 'Message title', // Titel
    text: 'Message',        // Message
    level: 0,               // 0-Debug, 1-Info, 2-Warning, 3-Error, 4-Fatal => app/GET_ERROR_LEVEL
    hidden: false,          // Hidden from drawer
    module: 'Module name',  // Message source
    time: new Date(),       // Create time
  },
  appURL: undefined,

  opties: {
    devMessages: false,
    compact: false,
  }
}

/**

 .d8888b.  8888888888 88888888888 88888888888 8888888888 8888888b.   .d8888b.
d88P  Y88b 888            888         888     888        888   Y88b d88P  Y88b
888    888 888            888         888     888        888    888 Y88b.
888        8888888        888         888     8888888    888   d88P  "Y888b.
888  88888 888            888         888     888        8888888P"      "Y88b.
888    888 888            888         888     888        888 T88b         "888
Y88b  d88P 888            888         888     888        888  T88b  Y88b  d88P
 "Y8888P88 8888888888     888         888     8888888888 888   T88b  "Y8888P"

 */

// getters
const getters = {
  GET_URL: () => state.appURL,

  GET_LOG_BOOK: (state) => state.logBook,

  GET_LOG_BOOK_COUNT: (state) => state.logBook.filter(i => !i.hidden).length,

  GET_ERROR_LEVELS: () => common.errorLevels,

  GET_ERROR_COLORS: () => common.errorColors,

  GET_ERROR_COLORS_DARK: () => common.errorColorsDark,

  GET_ERROR_ICONS: () => common.errorIcons,

  GET_DRAWER_STATE: (state) => state.drawer,

  GET_TIPS: (state) => state.tips,

  GET_LOCK: (state) => state.lock,

  GET_ERROR_DRAWER_STATE: (state) => state.errorDrawer,

  GET_ROOT_API_URI: (state, getters) => getters.GET_IS_DEBUG ? state.apiRootURIDebug : state.apiRootURI,

  // TODO: DEBUG ONLY, moet weg
  GET_WEBORDER_OPERATOR_ID: (state, getters) => getters.GET_IS_DEBUG ? 10 : 11,

  GET_CREATE_JWT_URI: (state, getters) => getters.GET_ROOT_API_URI + state.apiCreateJWT,

  GET_URI_INZENDING: (state, getters) => getters.GET_ROOT_API_URI + state.uriInzending,

  GET_REFRESH_JWT_URI: (state, getters) => getters.GET_ROOT_API_URI + state.apiRefreshJWT,

  GET_AUTH_USERS_API_URI: (state, getters) => getters.GET_ROOT_API_URI + state.apiUsersJWT,

  GET_SNELSTART_URI_LIST: (state, getters) => getters.GET_ROOT_API_URI + state.uriSnelStart,

  GET_PROCESS_URI_LIST: (state, getters) => getters.GET_ROOT_API_URI + state.uriProcess,

  GET_MODULES_URI_LIST: (state, getters) => getters.GET_ROOT_API_URI + state.uriModules,

  GET_KLANTGEGEVENS_URI_LIST: (state, getters) => getters.GET_ROOT_API_URI + state.uriKlantgegevens,

  GET_OPERATORS_URI_LIST: (state, getters) => getters.GET_ROOT_API_URI + state.uriOperators,

  GET_RELATIONS_URI_LIST: (state, getters) => getters.GET_ROOT_API_URI + state.uriRelations,

  GET_ARTIKELEN_URI_LIST: (state, getters) => getters.GET_ROOT_API_URI + state.uriArtikelen,

  GET_VERKOOP_SJABLOON_URI_LIST: (state, getters) => getters.GET_ROOT_API_URI + state.uriVerkoopSjablonen,

  GET_VERKOOP_ORDERS_URI_LIST: (state, getters) => getters.GET_ROOT_API_URI + state.uriVerkoopOrders,

  GET_VERKOOP_ORDER_EXTRAVELDEN_PUT: (state, getters) => getters.GET_ROOT_API_URI + state.uriVerkoopOrderExtravelden,

  GET_API_TIMEOUT: (state) => state.apiTimeout,

  GET_APP_VERSION: (state) => state.appVersion,

  GET_IS_DEBUG: () => {
    try {
        return !(window.location.hostname.indexOf('dkg.nl') >= 0)
    } catch {
        return false
    }
  },

  GET_DEV_MESSAGES: (state) => state.opties.devMessages,

  GET_GUI_COMPACT: (state) => state.opties.compact,

}

/**
       d8888  .d8888b.  88888888888 8888888  .d88888b.  888b    888  .d8888b.
      d88888 d88P  Y88b     888       888   d88P" "Y88b 8888b   888 d88P  Y88b
     d88P888 888    888     888       888   888     888 88888b  888 Y88b.
    d88P 888 888            888       888   888     888 888Y88b 888  "Y888b.
   d88P  888 888            888       888   888     888 888 Y88b888     "Y88b.
  d88P   888 888    888     888       888   888     888 888  Y88888       "888
 d8888888888 Y88b  d88P     888       888   Y88b. .d88P 888   Y8888 Y88b  d88P
d88P     888  "Y8888P"      888     8888888  "Y88888P"  888    Y888  "Y8888P"

 */

// actions, async
const actions = {
  ADD_LOG_MESSAGE: (context, payload) => {
    // console.log("app/ADD_LOG_MESSAGE", payload)
    context.commit('SET_LOG_MESSAGE', payload)
  },

  TOGGLE_DRAWER: context => {
    context.commit('SET_DRAWER', !context.getters.GET_DRAWER_STATE)
  },

  TOGGLE_ERROR_DRAWER: context => {
    context.commit('SET_ERROR_DRAWER', !context.getters.GET_ERROR_DRAWER_STATE)
  },

  HIDE_SHOW_LOG_MESSAGE: async (context, payload) => {
    console.log('HIDE_SHOW_LOG_MESSAGE() payload = ', payload)
    context.commit('SET_HIDE_SHOW_LOG_MESSAGE', payload)
  },

  // checkout ({ commit, state }, products) {
  //   const savedCartItems = [...state.items]
  //   commit('setCheckoutStatus', null)
  //   // empty cart
  //   commit('setCartItems', { items: [] })
  //   shop.buyProducts(
  //     products,
  //     () => commit('setCheckoutStatus', 'successful'),
  //     () => {
  //       commit('setCheckoutStatus', 'failed')
  //       // rollback to the cart saved before sending the request
  //       commit('setCartItems', { items: savedCartItems })
  //     }
  //   )
  // },

  // addProductToCart ({ state, commit }, product) {
  //   commit('setCheckoutStatus', null)
  //   if (product.inventory > 0) {
  //     const cartItem = state.items.find(item => item.id === product.id)
  //     if (!cartItem) {
  //       commit('pushProductToCart', { id: product.id })
  //     } else {
  //       commit('incrementItemQuantity', cartItem)
  //     }
  //     // remove 1 item from stock
  //     commit('products/decrementProductInventory', { id: product.id }, { root: true })
  //   }
  // }
}

/**

888b     d888 888     888 88888888888        d8888 88888888888 8888888  .d88888b.  888b    888
8888b   d8888 888     888     888           d88888     888       888   d88P" "Y88b 8888b   888
88888b.d88888 888     888     888          d88P888     888       888   888     888 88888b  888
888Y88888P888 888     888     888         d88P 888     888       888   888     888 888Y88b 888
888 Y888P 888 888     888     888        d88P  888     888       888   888     888 888 Y88b888
888  Y8P  888 888     888     888       d88P   888     888       888   888     888 888  Y88888
888   "   888 Y88b. .d88P     888      d8888888888     888       888   Y88b. .d88P 888   Y8888
888       888  "Y88888P"      888     d88P     888     888     8888888  "Y88888P"  888    Y888

 */

// mutations, sync
const mutations = {
  SET_URL: (state, url) => {
    state.appURL = url
  },

  SET_LOG_MESSAGE: (state, logMessage) => {
    state.logBook.unshift(logMessage)
  },

  SET_DRAWER: (state, data) => {
    state.drawer = data
  },

  SET_TIPS: (state, data) => {
    state.tips = data
  },

  SET_LOCK: (state, data) => {
    state.lock = data
  },

  SET_ERROR_DRAWER: (state, data) => {
    state.errorDrawer = data
  },

  SET_HIDE_SHOW_LOG_MESSAGE: (state, data) => {
    let item = state.logBook.find(i => i === data)

    console.log('SET_HIDE_SHOW_LOG_MESSAGE: item=', item)

    if (item) {
      data.hidden = !data.hidden
      Object.assign(item, data)
    }
  },

  SET_DEV_MESSAGES: (state, data) => {
    state.opties.devMessages = data
  },

  SET_GUI_COMPACT: (state, data) => {
    state.opties.compact = data
  },

  // pushProductToCart (state, { id }) {
  //   state.items.push({
  //     id,
  //     quantity: 1
  //   })
  // },

  // incrementItemQuantity (state, { id }) {
  //   const cartItem = state.items.find(item => item.id === id)
  //   cartItem.quantity++
  // },

  // setCartItems (state, { items }) {
  //   state.items = items
  // },

  // setCheckoutStatus (state, status) {
  //   state.checkoutStatus = status
  // }
}

/**

8888888888 Y88b   d88P 8888888b.   .d88888b.  8888888b.  88888888888
888         Y88b d88P  888   Y88b d88P" "Y88b 888   Y88b     888
888          Y88o88P   888    888 888     888 888    888     888
8888888       Y888P    888   d88P 888     888 888   d88P     888
888           d888b    8888888P"  888     888 8888888P"      888
888          d88888b   888        888     888 888 T88b       888
888         d88P Y88b  888        Y88b. .d88P 888  T88b      888
8888888888 d88P   Y88b 888         "Y88888P"  888   T88b     888

 */

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
