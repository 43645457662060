import axios from "axios";
import api from "../../utils/api.js";
import common from "../../utils/common";
// import store from "../index";

//     .d8888b.  88888888888        d8888 88888888888 8888888888
//   d88P  Y88b     888           d88888     888     888
//   Y88b.          888          d88P888     888     888
//    "Y888b.       888         d88P 888     888     8888888
//       "Y88b.     888        d88P  888     888     888
//         "888     888       d88P   888     888     888
//   Y88b  d88P     888      d8888888888     888     888
//    "Y8888P"      888     d88P     888     888     8888888888

const state = {
  abonnementen: [],

  artikelen: [],
  progressArtikelen: {
    name: 'Artikelen',
    loading: false,   // Bezig met laden:           true - laden in progress/ false - geen operatie
    apiTotal: null,   // Totaal records in api:     null - niet gelezen / 0 - geen data
    apiCount: null,   // All gelezen records:       null - niet gelezen / 0 - geen data
    procent: 0,       // Procent gelezen            0-100 %
    cancel: false,    // Moet geannuleerd:          true - operatie moet afbreken
    status: null,     // Laatste http status code:  200 - ok
    statusText: '',   // Laatste fout bericht van API of netwerk
  },

  order_sjablonen: [],

  progressSjablonen: {
    name: 'Order Sjablonen',
    loading: false,   // Bezig met laden:           true - laden in progress/ false - geen operatie
    apiTotal: null,   // Totaal records in api:     null - niet gelezen / 0 - geen data
    apiCount: null,   // All gelezen records:       null - niet gelezen / 0 - geen data
    procent: 0,       // Procent gelezen            0-100 %
    cancel: false,    // Moet geannuleerd:          true - operatie moet afbreken
    status: null,     // Laatste http status code:  200 - ok
    statusText: '',   // Laatste fout bericht van API of netwerk
  },

  verkoop_orders: [],

  verkoop_orders_update_log: {},

  progressVerkoopOrders: {
    name: 'Verkooporders',
    loading: false,   // Bezig met laden:           true - laden in progress/ false - geen operatie
    apiTotal: null,   // Totaal records in api:     null - niet gelezen / 0 - geen data
    apiCount: null,   // All gelezen records:       null - niet gelezen / 0 - geen data
    procent: 0,       // Procent gelezen            0-100 %
    cancel: false,    // Moet geannuleerd:          true - operatie moet afbreken
    status: null,     // Laatste http status code:  200 - ok
    statusText: '',   // Laatste fout bericht van API of netwerk
  },

  relaties: [],

  progressRelatie: {
    name: 'Relatie',
    loading: false,   // Bezig met laden:           true - laden in progress/ false - geen operatie
    apiTotal: null,   // Totaal records in api:     null - niet gelezen / 0 - geen data
    apiCount: null,   // All gelezen records:       null - niet gelezen / 0 - geen data
    procent: 0,       // Procent gelezen            0-100 %
    cancel: false,    // Moet geannuleerd:          true - operatie moet afbreken
    status: null,     // Laatste http status code:  200 - ok
    statusText: '',   // Laatste fout bericht van API of netwerk
  },

  total: null,
  progress: null,
  error: null,
  errorMessage: null,
  operator: null,

  constants: {
    ARTIKELEN_PER_REQ: 100,
    KLANTEN_PER_REQ: 100,
    ORDERS_PER_REQ: 25,
    SJABLONEN_PER_REQ: 10,
  },
};

//    .d8888b.  8888888888 88888888888 88888888888 8888888888 8888888b.   .d8888b.
//   d88P  Y88b 888            888         888     888        888   Y88b d88P  Y88b
//   888    888 888            888         888     888        888    888 Y88b.
//   888        8888888        888         888     8888888    888   d88P  "Y888b.
//   888  88888 888            888         888     888        8888888P"      "Y88b.
//   888    888 888            888         888     888        888 T88b         "888
//   Y88b  d88P 888            888         888     888        888  T88b  Y88b  d88P
//    "Y8888P88 8888888888     888         888     8888888888 888   T88b  "Y8888P"

const getters = {
  GET_ABO_PLUS_ARTIKEL: (state, getters) => {

    // Empty list => return []
    if (!state.abonnementen || !state.artikelen)
      return []

    // Copy abos
    let abonnementen = [...getters.GET_ABONNEMENTEN]
    let artikelen = state.artikelen
    let abo;
    let art;

    // Artikelen array 2 object
    let artikelenObject = {}
    for (art of artikelen) {
      artikelenObject[art['artikelcode']] = art
    }

    // console.log(artikelenObject)

    if (artikelen.length > 0) {
      for (abo of abonnementen) {
        if (abo.extra_velden) {
          // Artikel met ...CodeExtra bestaat??
          if (artikelenObject[abo.extra_velden.ArtikelCodeExtra]) {
            abo.artikel = artikelenObject[abo.extra_velden.ArtikelCodeExtra]
            // console.log("Artikel gekoppeld => ", abo)
            // if (!abo.extra_velden.Bedrag) {
            //   abo.extra_velden.Bedrag = abo.artikel.verkoopprijs
            // }
          } else {
            abo.artikel = {}
          }
        }
      }
    }
    return abonnementen
  },

  GET_ABONNEMENTEN: (state) => {
    const abo = state.abonnementen || []
    return abo.map(i => {
      i.artikel = i.artikel || {}
      return i
    })
  },

  GET_ARTIKELEN: (state) => {
    return state.artikelen || []
  },

  ABO_ERR: (state) => state.errorMessage,

  ABO_PROGRESS: (state) => state.progress,

  GET_ORDER_SJABLONEN: (state) => state.order_sjablonen || [],

  GET_VERKOOP_ORDERS: (state) => state.verkoop_orders || [],

  GET_VERKOOP_ORDERS_UPDATE_LOG: (state) => state.verkoop_orders_update_log,

  GET_RELATIES: (state) => state.relaties || [],

  GET_ARTIKELEN_PROGRESS: (state) => Object.assign({}, state.progressArtikelen),

  GET_RELATIES_PROGRESS: (state) => Object.assign({}, state.progressRelatie),

  GET_SJABLONEN_PROGRESS: (state) => Object.assign({}, state.progressSjablonen),

  GET_VERKOOP_PROGRESS: (state) => Object.assign({}, state.progressVerkoopOrders),

  GET_GLOBAL_PROGRESS_FLAG: (state) => (
    state.progressArtikelen.loading
    || state.progressRelatie.loading
    || state.progressSjablonen.loading
    || state.progressVerkoopOrders.loading
  ),

  GET_KLANTEN: (state) => state.relaties.filter(i => i.relatiesoort === 1) || [],

  GET_LEVERANCIERS: (state) => state.relaties.filter(i => i.relatiesoort === 2) || [],

  // ABO_INFO_COUNT: (state) => {
  //   return state.count
  // }

  // cartTotalPrice: (state, getters) => {
  //   return getters.cartProducts.reduce((total, product) => {
  //     return total + product.price * product.quantity
  //   }, 0)
  // }
};

// actions, async
const actions = {
  PUT_ORDER_EXTRAVELDEN: async (context, {snelstart_id, order_uuid, extravelden_dict, debug = false}) => {
    // let config = context.rootGetters["user/GET_AXIOS_CONFIG"]
    const uri = context.rootGetters["app/GET_VERKOOP_ORDER_EXTRAVELDEN_PUT"]

    // console.log('PUT_ORDER_EXTRAVELDEN')
    // console.log('snelstart_id', snelstart_id)
    // console.log('order_uuid', order_uuid)
    // console.log('extravelden_dict', extravelden_dict)
    // console.log('uri', uri)

    let repeats = 3
    const data = {
        order_uuid,
        extra_hoofd_velden: extravelden_dict,
        debug,
    }

    let log = {
      id: snelstart_id,
      uuid: order_uuid,
      extravelden_dict,
    }

    do {
      try {
        await axios.put(
          `${uri}${snelstart_id}/`,
          data,
          context.rootGetters["user/GET_AXIOS_CONFIG"]
        )
          .then(order_put => {
              repeats = 0

            log = Object.assign(
                log,
                order_put.data,
                {
                  status: order_put.status,
                  statusText: order_put.statusText,
                }
            )
            // TODO: Update order
            // console.log('Put is gelukt')
            // console.dir(order_put)

            context.commit('SET_VERKOOPORDER_EXTRAVELDEN_UPDATE', order_put)
          })
      } catch (error) {
        repeats--

        log = Object.assign(
          log,
          {
            message: error.message,
            status: error.response.status,
            statusText: error.response.statusText,
          },
          error.response.data
        )
        // console.log("Catch error =>")
        // console.dir(error)
        if (error.response.status === 429) {
          // Quota
          // Wait 2 sec
          await new Promise(r => setTimeout(r, 2000))
        } else {
          break
        }
      } // catch error
    } while (repeats > 0)

    context.commit('SET_VERKOOP_ORDERS_UPDATE_LOG', log)
  },

  RUN_OPERATOR: async (context, {uri, test, termijn, factuurDate}) => {
    const perioden = [
      0,  // Auto
      1,  // Maand
      3,  // Kwartaal
      6,  // Halfjaar
      12  // Jaar
    ]
    const config = context.rootGetters["user/GET_AXIOS_CONFIG"]

    try {
      // Lees operator met GET
      let operator = await axios.get(uri, config)

      // Parse datum
      let fDate = new Date(factuurDate).toISOString().substr(0, 10)
      console.log("Action: RUN_OPERATOR:")
      console.log("\tOp =>", operator)
      console.log("\tTermijn =>", termijn)
      console.log("\tData =>", factuurDate)
      console.log("\tData conv =>", fDate)

      // Pas operator
      if (operator.status === 200 && perioden.includes(termijn)) {
        // Check flag
        operator.data.params.check_only = test
        // Abo period: 0,1,3,6,12
        operator.data.params.period = termijn
        operator.data.params.boek_datum = fDate
        operator.data.run_once = true

        console.dir(operator.data)

        // Run operator!!
        let operator_put = await axios.put(uri, operator.data, config)

        if (operator_put.status === 200) {
          console.log("\tOperator success")
        }
      } else {
        console.error("\tOperator status is:", operator.statusText)
      }
    } catch (error) {
      // console.error("Op error =>", error)
      common.fn.addMessage2Drawer(
        `Operator fout: ${error}`,
        common.errorLevels.Error,
        'operator',
        'snelstart',
        true,
      )
    }
  },

  READ_ABO: async (context, {uri}) => {
    let resultData = []
    let respData
    const config = context.rootGetters["user/GET_AXIOS_CONFIG"]

    // console.log("GET_ABO:")
    // console.log(uri)
    // console.log(auth)

    let firstRead = true

    // Abo's beginnen te laden
    context.commit("SET_ABO_PROGRESS", true)

    do {
      try {

        respData = await axios.get(uri, config)

        console.log("READ_ABO: response data => ", respData)

      } catch (error) {
        // Error 😨
        console.log("READ_ABO: ERROR:", error)
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log("Response data:", error.response.data);
          console.log("Response status:", error.response.status);
          console.log("Response header:", error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log("Response request =>", error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error: ', error.message);
        }
        console.log('Error obj =>', error);
        context.commit("SET_ABO_ERROR", true);
        context.commit("SET_ABO_PROGRESS", false)
        context.commit("SET_ABO_ERROR_MESSAGE", error);
        return
      }

      if (respData.status === 200) {
        // Success 🎉
        resultData = resultData.concat(respData.data.results)
        uri = respData.data.next

        context.commit('SET_ABO', resultData)

        if (firstRead) {
          context.commit("SET_ABO_TOTAL", respData.data.count)
          firstRead = false
        }
      } else {
        console.error("resultData = ", resultData)
        context.commit("SET_ABO_ERROR", true)
        context.commit("SET_ABO_PROGRESS", false)
        context.commit("SET_ABO_ERROR_MESSAGE", resultData);
        return
      }
    } while (uri)

    // Abo's is geladen
    context.commit("SET_ABO_PROGRESS", null)
  },

  READ_ARTIKELEN_LIST: async (context, {snelstart_id = 0}) => {
    const artikelenUriList = context.rootGetters["app/GET_ARTIKELEN_URI_LIST"]

    // API parameters
    const params = {
      snelstart_id,
      limit: context.state.constants.ARTIKELEN_PER_REQ,
    }

    console.log("READ_ARTIKELEN_LIST: Action, uri =>", artikelenUriList, params)

    await api.getAPIRequestMultipage(
      {
          uri: artikelenUriList,
          params,
          commitTo: 'snelstart/SET_ARTIKELEN',
          progressGetter: 'snelstart/GET_ARTIKELEN_PROGRESS',
          progressSetter: 'snelstart/SET_ARTIKELEN_PROGRESS',
      }
    )

    // const response = await api.getAPIRequestMultipage({uri})
    // console.log("response", response)
    // if (response.status === 200) {
    //   context.commit('SET_ARTIKELEN', response.data)
    // }
  },

  WRITE_ARTIKEL_BUSKET_VALUE: async (context, {id, value}) => {
    // console.log('id', id)
    // console.log('value', value)
    // console.log('artikelen', context.getters.GET_ARTIKELEN)

    const artikel_index = context.getters.GET_ARTIKELEN.findIndex((art) => art.id === id)

    // console.log('Artikel index', artikel_index)

    if (artikel_index >= 0) {
      // console.log('Value', value)
      context.commit('SET_ARTIKEL_BUSKET_OP_INDEX', {artikel_index, value})
    }
  },

  READ_ORDER_SJABLONEN: async (context, {snelstart_id = 0}) => {
    const sjablonenUriList = context.rootGetters["app/GET_VERKOOP_SJABLOON_URI_LIST"]

    console.log('READ_ORDER_SJABLONEN: uri', sjablonenUriList)
    console.log('READ_ORDER_SJABLONEN: snelstart_id', snelstart_id)

    const params = {
      snelstart_id,
      limit: context.state.constants.SJABLONEN_PER_REQ,
    }

    await api.getAPIRequestMultipage({
      uri: sjablonenUriList,
      params,
      commitTo: "snelstart/SET_ORDER_SJABLONEN",
      progressGetter: 'snelstart/GET_SJABLONEN_PROGRESS',
      progressSetter: 'snelstart/SET_SJABLONEN_PROGRESS'
    })
  },

  // READ_VERKOOP_ORDERS: async (context, {snelstart_id=0}) => {
  //   let uri = context.rootGetters["app/GET_VERKOOP_ORDERS_URI_LIST"]
  //
  //   // console.log('snelstart_id', snelstart_id)
  //   if (snelstart_id > 0) {
  //     uri += `?snelstart_id=${snelstart_id}`
  //   }
  //
  //   const response = await api.getAPIRequestMultipage({uri})
  //
  //   console.log("READ_VERKOOP_ORDERS: response", response)
  //
  //   if (response.status === 200) {
  //     context.commit('SET_VERKOOP_ORDERS', response.data)
  //   }
  // },

  READ_VERKOOP_ORDERS: async (context, {snelstart_id = 0}) => {
    const uri = context.rootGetters["app/GET_VERKOOP_ORDERS_URI_LIST"]

    // API parameters
    const params = {
      snelstart_id,
      limit: context.state.constants.ORDERS_PER_REQ,
    }

    const response = await api.getAPIRequestMultipage({
      uri,
      params,
      commitTo: 'snelstart/SET_VERKOOP_ORDERS',
      progressGetter: 'snelstart/GET_VERKOOP_PROGRESS',
      progressSetter: 'snelstart/SET_VERKOOP_PROGRESS'
    })

    // const response = await api.getAPIRequestMultipage({uri})

    console.log("READ_VERKOOP_ORDERS: response", response)
  },

  READ_KLANTEN_LIST: async (context, {snelstart_id = 0}) => {
    const relatiesUriList = context.rootGetters["app/GET_RELATIONS_URI_LIST"]

    // API parameters
    const params = {
      snelstart_id,
      relatiesoort: 1,    // 1 - Klanten / 2 - Leverancier
      limit: context.state.constants.KLANTEN_PER_REQ,
    }

    console.log("READ_KLANTEN_LIST: Action, uri =>", relatiesUriList, params)

    // const response =
    await api.getAPIRequestMultipage({
      uri: relatiesUriList,
      params,
      commitTo: 'snelstart/SET_KLANTEN_LIST',
      progressGetter: 'snelstart/GET_RELATIES_PROGRESS',
      progressSetter: 'snelstart/SET_RELATIES_PROGRESS'
    })
    // if (response.status === 200) {
    //   context.commit('SET_KLANTEN_LIST', response.data)
    // }
  },

  CANCEL_LOAD: async (context) => {
    context.commit('SET_LOAD_CANCEL')
  },

  // checkout ({ commit, state }, products) {
  //   const savedCartItems = [...state.items]
  //   commit('setCheckoutStatus', null)
  //   // empty cart
  //   commit('setCartItems', { items: [] })
  //   shop.buyProducts(
  //     products,
  //     () => commit('setCheckoutStatus', 'successful'),
  //     () => {
  //       commit('setCheckoutStatus', 'failed')
  //       // rollback to the cart saved before sending the request
  //       commit('setCartItems', { items: savedCartItems })
  //     }
  //   )
  // },

  // addProductToCart ({ state, commit }, product) {
  //   commit('setCheckoutStatus', null)
  //   if (product.inventory > 0) {
  //     const cartItem = state.items.find(item => item.id === product.id)
  //     if (!cartItem) {
  //       commit('pushProductToCart', { id: product.id })
  //     } else {
  //       commit('incrementItemQuantity', cartItem)
  //     }
  //     // remove 1 item from stock
  //     commit('products/decrementProductInventory', { id: product.id }, { root: true })
  //   }
  // }
};

// ****************************    MUTATIONS    ****************************** //

// mutations, sync
const mutations = {
  SET_ABO: (state, data) => {
    state.abonnementen = data
  },

  SET_LOAD_CANCEL: (state) => {
    state.progressArtikelen.cancel = state.progressArtikelen.loading
    state.progressRelatie.calcel = state.progressRelatie.loading
    state.progressSjablonen.calcel = state.progressSjablonen.loading
    state.progressVerkoopOrders.calcel = state.progressVerkoopOrders.loading
  },

  SET_ARTIKEL_BUSKET_OP_INDEX: (state, {artikel_index, value}) => {
    // state.artikelen[idx].busket = value
    // console.log('art_index', artikel_index)
    // console.log('value', value)

    // console.log('artikel', state.artikelen[artikel_index])
    let newArt = Object.assign({}, state.artikelen[artikel_index])
    newArt.basket = value

    // console.log('new artikel', newArt)
    state.artikelen.splice(artikel_index, 1, newArt)
  },

  SET_ARTIKEL_BUSKET_OP_ID: (state, {artikel_id, value}) => {
    const artikel_index = state.artikelen.findIndex((art) => art.id === artikel_id)
    const newArt = Object.assign({}, state.artikelen[artikel_index])
    newArt.basket = value
    state.artikelen.splice(artikel_index, 1, newArt)
  },

  HULP_REPLACE_OP_SNELSTART_ID: (state, {data, bron, newObj = null}) => {
    console.log('data => ', data)
    const SnelStartIDList = []
    data.forEach((value) => {
      if (SnelStartIDList.indexOf(value.snelstart_id) === -1) {
        SnelStartIDList.push(value.snelstart_id)
      }
    })
    console.log('data', data)
    // Oude artikelen van andere (niet in data) administratie
    let newList = bron.filter((val) => SnelStartIDList.indexOf(val.snelstart_id) === -1)
    console.log('newList 1', newList)

    data.forEach((item) => {
      newList.push(newObj === null ? item : Object.assign(newObj, item))
    })
    console.log('newList 2', newList)

    return newList
  },

  SET_ARTIKELEN: (state, data) => {
    // const SnelStartIDSet = data.filter((val, idx, arr) => arr.indexOf(val) === idx)  // Allen unique ID
    const SnelStartIDSet = new Set()
    data.forEach((value) => {
      SnelStartIDSet.add(value.snelstart_id)
    })
    // console.log("SET_ARTIKELEN, Unique IDs", SnelStartIDSet)

    // Oude artikelen van andere (niet in data) administratie
    let newArtikelenList = state.artikelen.filter((val) => !SnelStartIDSet.has(val.snelstart_id))

    // console.log("SET_ARTIKELEN, Na filtering", newArtikelenList)

    data.forEach((item) => {
      let item_copy = Object.assign({basket: null}, item)

      // Unpack extravelden
      if (item_copy.extra_velden !== undefined) {
        const extra_velden = Object.assign({}, item_copy.extra_velden)

        for (const [key, value] of Object.entries(extra_velden)) {
          // console.log(`${key}: ${value}`);
          item_copy['extra_velden_' + key] = value
        }

        delete item_copy.extra_velden
      }

      newArtikelenList.push(item_copy)
    })

    state.artikelen = newArtikelenList
//    state.artikelen = this.HULP_REPLACE_OP_SNELSTART_ID(state, {data, bron: state.artikelen, newObj:{ basket:null }})
  },

  SET_ORDER_SJABLONEN: (state, data) => {
    // const SnelStartIDList = data.filter((val, idx, arr) => arr.indexOf(val) === idx)  // Allen unique ID
    const SnelStartIDList = []
    data.forEach((value) => {
      if (SnelStartIDList.indexOf(value.snelstart_id) === -1) {
        SnelStartIDList.push(value.snelstart_id)
      }
    })
    // console.log("SET_ARTIKELEN, Unique IDs", SnelStartIDList)

    // Oude artikelen van andere (niet in data) administratie
    let newOrderSjablonenList = state.order_sjablonen.filter(
      (val) => SnelStartIDList.indexOf(val.snelstart_id) === -1
    )

    // console.log("SET_ARTIKELEN, Na filtering", newArtikelenList)

    // newOrderSjablonenList.concat(data)
    data.forEach((item) => {
      newOrderSjablonenList.push(item)
    })

    state.order_sjablonen = newOrderSjablonenList
//    state.order_sjablonen = this.HULP_REPLACE_OP_SNELSTART_ID(state, data, state.order_sjablonen)
  },

  SET_VERKOOPORDER_EXTRAVELDEN_UPDATE: (state, responseData) => {
    const order = responseData.data.order
    const newVerkoopOrders = [...state.verkoop_orders]

    // console.log('SET_VERKOOPORDER_EXTRAVELDEN_UPDATE')
    // console.log('New order')
    // console.dir(order)

    const old_order_idx = newVerkoopOrders.findIndex(o => o.uuid === order.publicIdentifier)

    if (old_order_idx !== -1) {
      // console.log(`Order gevonden met index ${old_order_idx}`)

      const copy_order = Object.assign({}, newVerkoopOrders[old_order_idx])
      // console.log('Old order')
      // console.dir(copy_order)

      // Copy item
      copy_order.extra_hoofd_velden = order.extraHoofdVelden

      // Set new item
      newVerkoopOrders[old_order_idx] = copy_order

      // Commit array
      state.verkoop_orders = newVerkoopOrders

      // console.log('SETTER: Gedaan')
    }
  },

  SET_VERKOOP_ORDERS_UPDATE_LOG: (state, log) => {
    state.verkoop_orders_update_log[log.uuid] = log
  },

  SET_VERKOOP_ORDERS: (state, data) => {
    // const SnelStartIDSet = data.filter((val, idx, arr) => arr.indexOf(val) === idx)  // Allen unique ID
    const SnelStartIDSet = new Set()
    data.forEach((value) => {
      if (!SnelStartIDSet.has(value.snelstart_id)) {
        SnelStartIDSet.add(value.snelstart_id)
      }
    })

    // Oude artikelen van andere (niet in data) administratie
    let newVerkoopOrdersList = state.verkoop_orders.filter(
      (val) => !SnelStartIDSet.has(val.snelstart_id)
    )

    // console.log("SET_ARTIKELEN, Na filtering", newArtikelenList)

    // newOrderSjablonenList.concat(data)
    data.forEach((item) => {
      newVerkoopOrdersList.push(Object.assign({}, item))
    })

    state.verkoop_orders = newVerkoopOrdersList
//    state.order_sjablonen = this.HULP_REPLACE_OP_SNELSTART_ID(state, data, state.order_sjablonen)
  },

  SET_ABO_TOTAL: (state, data) => {
    state.total = data
  },
  SET_ABO_PROGRESS: (state, data) => {
    state.progress = data
  },
  SET_ABO_ERROR: (state, data) => {
    state.error = data
  },
  SET_ABO_ERROR_MESSAGE: (state, data) => {
    state.errorMessage = data
  },
  SET_KLANTEN_LIST: (state, data) => {
    state.relaties = data
  },
  SET_ARTIKELEN_PROGRESS: (state, data) => {
    state.progressArtikelen = Object.assign({}, data)
  },
  SET_RELATIES_PROGRESS: (state, data) => {
    state.progressRelatie = Object.assign({}, data)
  },
  SET_SJABLONEN_PROGRESS: (state, data) => {
    state.progressSjablonen = Object.assign({}, data)
  },
  SET_VERKOOP_PROGRESS: (state, data) => {
    state.progressVerkoopOrders = Object.assign({}, data)
  },

  // pushProductToCart (state, { id }) {
  //   state.items.push({
  //     id,
  //     quantity: 1
  //   })
  // },

  // incrementItemQuantity (state, { id }) {
  //   const cartItem = state.items.find(item => item.id === id)
  //   cartItem.quantity++
  // },

  // setCartItems (state, { items }) {
  //   state.items = items
  // },

  // setCheckoutStatus (state, status) {
  //   state.checkoutStatus = status
  // }
};  // Mutations

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
