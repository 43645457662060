<template>
  <v-footer app
            padless
  >
    <a
        href="https://dkg.nl/"
        target="_blank"
        class="text-decoration-none pl-2 hidden-md-and-down"
        v-if="!GET_IS_SUPERUSER || !appDevMessages"
    >
      <v-card flat class="px-2">
        <span class="caption">© 1996 - {{ localYear }}
          <span class="font-weight-black">
            <span :style="{color: $vuetify.theme.themes.light.primary}"
            >D</span>
            <span :style="{color: $vuetify.theme.themes.light.accent}"
            >K</span>
            <span :style="{color: $vuetify.theme.themes.light.secondary}"
            >G</span>
          </span>
          <span class="font-weight-light"
          > Automatisering</span>
        </span>
      </v-card>
    </a>

    <v-divider
        v-if="GET_IS_SUPERUSER && appDevMessages"
        vertical class="mx-2"
    ></v-divider>

    <v-card
        class="text-caption mx-0 px-2"
        v-if="GET_IS_SUPERUSER && appDevMessages"
    >API uri: {{ ROOT_API_URI }}
    </v-card>

    <v-card
        v-if="GET_IS_SUPERUSER && appDevMessages"
        class="text-caption mx-1 px-2"
        :class="{warning: ingelogdResidualTime < 300000}"
    >Ingelogd: {{ ingelogdResidual }}
    </v-card>

    <v-card
        v-if="GET_IS_SUPERUSER && appDevMessages"
        class="text-caption mx-1 px-2"
        :class="{
          'error--text': accessResidualTime < 0,
          warning: accessResidualTime> 0 && accessResidualTime < 60000
        }"
    >Access: {{ accessResidual }}
    </v-card>

    <v-spacer></v-spacer>

    <v-card  id="bluur-card"
        v-if="GET_IS_INGELOGD"
        flat
        class="mx-2"
    >
      <v-badge
          v-model="GET_TIPS"
          content="Lock"
          color="warning"
          offset-x="4em"
      >
        <v-btn id="global-bluur-btn"
            @click="appLock = true"
            class="mx-1"
            x-small
            icon
            color="warning"
        >
          <v-icon
              x-small
          >mdi-monitor-lock
          </v-icon>
        </v-btn>
      </v-badge>
    </v-card>

    <v-divider
        v-if="GET_IS_INGELOGD"
        vertical
        class="hidden-sm-and-down"
    ></v-divider>

    <!--  LOCK dialog -->
    <v-dialog
        v-model="appLock"
        transition="dialog-bottom-transition"
        max-width="20%"
    >
      <!--        hide-overlay-->
      <!--        fullscreen-->
      <v-card>
        <v-toolbar
            color="grey"
        >
          <v-toolbar-title>Scherm verbergen</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                icon
                dark
                @click="appLock = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!--            <v-btn-->
            <!--              dark-->
            <!--              text-->
            <!--              @click="appLock = false"-->
            <!--            >-->
            <!--              Close-->
            <!--            </v-btn>-->
          </v-toolbar-items>
        </v-toolbar>

        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Hulp:</v-list-item-title>
              <v-list-item-subtitle>Druk
                <v-chip
                    small
                    class="rounded-tl-0"
                    @click="appLock = false"
                >Esc
                </v-chip>
                om terug te gaan...
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <v-card
        id="version"
        flat
        class="pointer text-caption px-2 ma-2 hidden-sm-and-down"
    >Ver:
    <span
        class="font-weight-light"
    >{{ $store.getters["app/GET_APP_VERSION"] }}</span>
    </v-card>

    <v-card
        id="clock"
        class="pointer text-caption mr-2 px-2 d-flex"
        @click="clockDialog=true"
        flat
    >
      <div style="width:1em; height:1em; margin-top:0.15em; margin-right:0.3em">
        <svg viewBox="-1 -1 386 386"
             id="dkg-clock"
             :fill="$vuetify.theme.dark ? '#007B91F0' : '#00BCD4F0'"
             stroke="black"
        >
          <defs>
            <filter id='svg-shadow' color-interpolation-filters="auto">
              <feDropShadow
                  :flood-color="$vuetify.theme.dark ? 'green' : 'black'"
                  dx="0" dy="0" stdDeviation="1"/>
            </filter>
            <!--            <filter id="svg-blur">-->
            <!--              <feGaussianBlur in="SourceGraphic" stdDeviation="50" />-->
            <!--            </filter>-->
          </defs>

          <circle
              :fill="$vuetify.theme.dark ? '#4F5555E0' : '#E6F0F0E0'"
              class="clock-board" cx="192" cy="192" r="192"
          />

          <text font-family="Roboto" font-size="60" font-weight="bold" filter="url(#svg-shadow)">
            <tspan text-anchor="middle" x="192" y="160" stroke-width="0">
              <tspan fill="#2087c880">D</tspan>
              <tspan fill="#4f9b9880">K</tspan>
              <tspan fill="#1ead6480">G</tspan>
            </tspan>
          </text>

          <text
              font-family="script, cursive"
              font-size="8"
              stroke-width="0"
              :fill="$vuetify.theme.dark ? '#FFFFFF50' : '#00000030'"
              filter="url(#svg-shadow)">
            <tspan text-anchor="middle" x="192" y="320">🤓 Evgeny Vladimircev</tspan>
          </text>

          <path class="dkg-clock-frame"
                stroke-width="0"
                filter="url(#svg-shadow)"
                d="M192,0C85.961,0,0,85.961,0,192s85.961,192,192,192s192-85.961,192-192S298.039,0,192,0zM315.037,315.037c-9.454,9.454-19.809,17.679-30.864,24.609l-14.976-25.939l-10.396,6l14.989,25.964c-23.156,12.363-48.947,19.312-75.792,20.216V336h-12v29.887c-26.845-0.903-52.636-7.854-75.793-20.217l14.989-25.963l-10.393-6l-14.976,25.938c-11.055-6.931-21.41-15.154-30.864-24.608s-17.679-19.809-24.61-30.864l25.939-14.976l-6-10.396l-25.961,14.99C25.966,250.637,19.017,224.846,18.113,198H48v-12H18.113c0.904-26.844,7.853-52.634,20.216-75.791l25.96,14.988l6.004-10.395L44.354,99.827c6.931-11.055,15.156-21.41,24.61-30.864s19.809-17.679,30.864-24.61l14.976,25.939l10.395-6L110.208,38.33C133.365,25.966,159.155,19.017,186,18.113V48h12V18.113c26.846,0.904,52.635,7.853,75.792,20.216l-14.991,25.965l10.395,6l14.978-25.942c11.056,6.931,21.41,15.156,30.865,24.611c9.454,9.454,17.679,19.808,24.608,30.863l-25.94,14.976l6,10.396l25.965-14.99c12.363,23.157,19.312,48.948,20.218,75.792H336v12h29.887c-0.904,26.845-7.853,52.636-20.216,75.792l-25.964-14.989l-6.002,10.396l25.941,14.978C332.715,295.229,324.491,305.583,315.037,315.037z"/>

          <line class="dkg-clock-hour-hand"
                id="dkg-clock-hour-hand"
                :stroke="$vuetify.theme.dark ? '#FFFFFFD0' : '#000000D0'"
                fill="none"
                stroke-width="14"
                stroke-linecap="round"
                stroke-miterlimit="10"
                x1="192" y1="192" x2="192" y2="120"/>

          <line class="dkg-clock-minute-hand"
                id="dkg-anim-clock-minute-hand"
                :stroke="$vuetify.theme.dark ? '#FFFFFFE0' : '#000000E0'"
                fill="none"
                stroke-width="8"
                stroke-linecap="round"
                stroke-miterlimit="10"
                x1="192" y1="192" x2="192" y2="54"/>

          <g filter="url(#svg-shadow)">
            <g class="dkg-clock-second-hand"
               id="dkg-anim-clock-second-hand"
               :fill="$vuetify.theme.dark ? $vuetify.theme.themes.dark.success : $vuetify.theme.themes.light.error"
            >
              <!--               :fill="$vuetify.theme.dark ? '#5DE249' : '#d53a1f'"-->
              <path id="second-2"
                    stroke="none"
                    stroke-width="1px"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-opacity="1"
                    fill-opacity="1"
                    d="m 192,25 1,12 1,151 v 14 h 1 v 25 h -6 v -25 h 1 v -14 l 1,-151 z"
              />
              <circle
                  class="dkg-clock-second-hand-axis"
                  stroke="None"
                  cx="192" cy="192" r="4.5"/>
            </g>
          </g>
          <defs>
            <animateTransform
                type="rotate"
                fill="remove"
                restart="always"
                calcMode="linear"
                accumulate="none"
                additive="sum"
                xlink:href="#dkg-anim-clock-hour-hand"
                repeatCount="indefinite"
                dur="43200s"
                to="360 192 192"
                from="0 192 192"
                attributeName="transform"
                attributeType="xml">
            </animateTransform>

            <animateTransform
                type="rotate"
                fill="remove"
                restart="always"
                calcMode="linear"
                accumulate="none"
                additive="sum"
                xlink:href="#dkg-anim-clock-minute-hand"
                repeatCount="indefinite"
                dur="3600s"
                to="360 192 192"
                from="0 192 192"
                attributeName="transform"
                attributeType="xml">
            </animateTransform>

            <animateTransform
                type="rotate"
                fill="remove"
                restart="always"
                calcMode="linear"
                accumulate="none"
                additive="sum"
                xlink:href="#dkg-anim-clock-second-hand"
                repeatCount="indefinite"
                dur="60s"
                to="360 192 192"
                from="0 192 192"
                attributeName="transform"
                attributeType="xml">
            </animateTransform>
          </defs>
        </svg>
      </div>

      <span class="d-inline-flex">
        <span class="font-weight-light px-1" style="font-family: 'Verdana', sans-serif;">{{ getTime }}</span>
      </span>

<!--      ICON CLOCK - DEBUG-->
<!--      <v-fade-transition>-->
<!--        <v-icon :key="'1' + getTime[0]" dense style="width:6px">mdi-numeric-{{ getTime[0] }}</v-icon>-->
<!--      </v-fade-transition>-->
<!--      <v-fade-transition>-->
<!--        <v-icon :key="'2' + getTime[1]" dense style="width:6px">mdi-numeric-{{ getTime[1] }}</v-icon>-->
<!--      </v-fade-transition>-->
<!--      <v-fade-transition>-->
<!--        <v-icon key="33" dense size="8px" style="width:5px">mdi-dots-vertical</v-icon>-->
<!--      </v-fade-transition>-->
<!--      <v-fade-transition>-->
<!--        <v-icon :key="'4' + getTime[3]" dense style="width:6px">mdi-numeric-{{ getTime[3] }}</v-icon>-->
<!--      </v-fade-transition>-->
<!--      <v-fade-transition>-->
<!--        <v-icon :key="'5' + getTime[4]" dense style="width:6px">mdi-numeric-{{ getTime[4] }}</v-icon>-->
<!--      </v-fade-transition>-->
    </v-card>

    <v-bottom-sheet
        v-model="clockDialog"
        hide-overlay
        inset
        content-class="elevation-0 mb-8"
    >
      <svg height="22em" width="22em" class="pa-4"
           :class="{'dark-shadow': $vuetify.theme.dark, 'shadow': !$vuetify.theme.dark}"
      >
        <use href="#dkg-clock"></use>
      </svg>
    </v-bottom-sheet>

  </v-footer>
</template>

<script>
import {mapGetters} from "vuex";
import {mixin as VueTimers} from "vue-timers";
import common from "../utils/common";

export default {
  data() {
    return {
      // Update via Timer: runClockUpdate
      localYear: 2023,
      time: "--:--",
      ingelogdResidualTime: 0,
      ingelogdResidual: "",
      accessResidualTime: 0,
      accessResidual: "",
      clockDialog: false,
    }
  },
  name: 'app-footer',

  mixins: [VueTimers],

  // vue-timers module https://www.npmjs.com/package/vue-timers
  timers: {
    runClockUpdate: {
      time: 1000,
      repeat: true,
      autostart: true,
    }
  },

  mounted() {
    // this.dateObj = new Date()
    this.m_analogClockUpdate()
  },

  methods: {
    // ...mapActions({
    //       SET_LOCK: "app/SET_LOCK",
    // }),

    // Update timer: VueTimers mixin
    runClockUpdate() {
      const localTime = new Date()
      this.localYear = localTime.getFullYear()
      this.time = localTime.toLocaleTimeString(
          'nl-NL',
          {hour: '2-digit', minute: '2-digit'}
      )

      this.ingelogdResidualTime = this.GET_INGELOGD_TOT_TIJD - localTime
      this.ingelogdResidual = common.fn.msToHuman(this.ingelogdResidualTime)

      this.accessResidualTime = this.GET_ACCESS_TOKEN_TOT_TIJD - localTime
      this.accessResidual = common.fn.msToHuman(this.accessResidualTime)

      // if (localTime.getSeconds() === 0) {
      //   TODO: Reset animation rules
      // this.m_analogClockUpdate()
      // }
    },

    m_analogClockUpdate() {
      const date = new Date;
      let seconds = date.getSeconds();
      let minutes = date.getMinutes();
      let hours = date.getHours();

      hours = (hours > 12) ? hours - 12 : hours;
      minutes = (minutes * 60) + seconds;
      hours = (hours * 3600) + minutes;

      document.querySelector('.dkg-clock-second-hand')
        .setAttribute('transform', 'rotate(' + 360 * (seconds / 60) + ',192,192)');
      document.querySelector('.dkg-clock-minute-hand')
        .setAttribute('transform', 'rotate(' + 360 * (minutes / 3600) + ',192,192)');
      document.querySelector('.dkg-clock-hour-hand')
        .setAttribute('transform', 'rotate(' + 360 * (hours / 43200) + ',192,192)');
    },
  },

  computed: {

    getTime() {
      return this.time
    },

    ...mapGetters({
      ROOT_API_URI: "app/GET_ROOT_API_URI",
      appDevMessages: "app/GET_DEV_MESSAGES",
      GET_TIPS: "app/GET_TIPS",
      GET_LOCK: "app/GET_LOCK",
    }),

    ...mapGetters('user', [
      'GET_IS_INGELOGD',
      'GET_INGELOGD_TOT_TIJD',
      'GET_IS_SUPERUSER',
      'GET_INGELOGD_TOT_TIJD',
      'GET_ACCESS_TOKEN_TOT_TIJD',
      'GET_IS_SUPERUSER',
    ]),

    appLock: {
      get() {
        // console.log("drawer.get => ", !!this.GET_DRAWER)
        return this.GET_LOCK
      },
      set(val) {
        // console.log("drawer.set => ", !!val)
        // this.SET_LOCK(val)
        this.$store.commit('app/SET_LOCK', val)
      }
    }
  } // computed
}
</script>

<style lang="css" scoped>

.pointer {
  cursor: pointer;
}

.shadow {
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.50));
}

.dark-shadow {
  filter: drop-shadow(2px 2px 8px rgba(0, 200, 0, 0.30));
}

.clock-board {
  /*backdrop-filter: blur(50px);*/
  /*filter: blur(60px);*/
}

</style>
