// import axios from "axios";
// import api from "../../utils/api.js";
// import common from "../../utils/common";

// import store from "../index";

//     .d8888b.  88888888888        d8888 88888888888 8888888888
//   d88P  Y88b     888           d88888     888     888
//   Y88b.          888          d88P888     888     888
//    "Y888b.       888         d88P 888     888     8888888
//       "Y88b.     888        d88P  888     888     888
//         "888     888       d88P   888     888     888
//   Y88b  d88P     888      d8888888888     888     888
//    "Y8888P"      888     d88P     888     888     8888888888

const state = {
  plannerOrderFlatList: [
    {
      title: "Backlog",
      tasks: [
        {
          id: 1,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        },
        {
          id: 2,
          title: "Provide documentation on integrations",
          date: "Sep 12"
        },
        {
          id: 3,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 4,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        },
        {
          id: 5,
          title: "Test checkout flow",
          date: "Sep 15",
          type: "QA"
        }
      ]
    },
    {
      title: "In Progress",
      tasks: [
        {
          id: 6,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 7,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        },
        {
          id: 8,
          title: "Provide documentation on integrations",
          date: "Sep 12",
          type: "Backend"
        }
      ]
    },
    {
      title: "Review",
      tasks: [
        {
          id: 9,
          title: "Provide documentation on integrations",
          date: "Sep 12"
        },
        {
          id: 10,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 11,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        },
        {
          id: 12,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 13,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        }
      ]
    },
    {
      title: "Review 2",
      tasks: [
        {
          id: 209,
          title: "Provide documentation on integrations",
          date: "Sep 12"
        },
        {
          id: 210,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 211,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        },
        {
          id: 212,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 213,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        }
      ]
    },
    {
      title: "Review 3",
      tasks: [
        {
          id: 309,
          title: "Provide documentation on integrations",
          date: "Sep 12"
        },
        {
          id: 310,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 311,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        },
        {
          id: 312,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 313,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        }
      ]
    },
    {
      title: "Review 4",
      tasks: [
        {
          id: 409,
          title: "Provide documentation on integrations",
          date: "Sep 12"
        },
        {
          id: 410,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 411,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        },
        {
          id: 412,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 413,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        }
      ]
    },
    {
      title: "Review 5",
      tasks: [
        {
          id: 509,
          title: "Provide documentation on integrations",
          date: "Sep 12"
        },
        {
          id: 510,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 511,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        },
        {
          id: 512,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 513,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        }
      ]
    },
    {
      title: "Review 6",
      tasks: [
        {
          id: 609,
          title: "Provide documentation on integrations",
          date: "Sep 12"
        },
        {
          id: 610,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 611,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        },
        {
          id: 612,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 613,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        }
      ]
    },
    {
      title: "Review 7",
      tasks: [
        {
          id: 709,
          title: "Provide documentation on integrations",
          date: "Sep 12"
        },
        {
          id: 710,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 711,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        },
        {
          id: 712,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 713,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        }
      ]
    },
    {
      title: "Review 8",
      tasks: [
        {
          id: 809,
          title: "Provide documentation on integrations",
          date: "Sep 12"
        },
        {
          id: 810,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 811,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        },
        {
          id: 812,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 813,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        }
      ]
    },
    {
      title: "Done",
      tasks: [
        {
          id: 14,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        },
        {
          id: 15,
          title: "Design shopping cart dropdown",
          date: "Sep 9",
          type: "Design"
        },
        {
          id: 16,
          title: "Add discount code to checkout page",
          date: "Sep 14",
          type: "Feature Request"
        }
      ]
    }
  ],

};

//    .d8888b.  8888888888 88888888888 88888888888 8888888888 8888888b.   .d8888b.
//   d88P  Y88b 888            888         888     888        888   Y88b d88P  Y88b
//   888    888 888            888         888     888        888    888 Y88b.
//   888        8888888        888         888     8888888    888   d88P  "Y888b.
//   888  88888 888            888         888     888        8888888P"      "Y88b.
//   888    888 888            888         888     888        888 T88b         "888
//   Y88b  d88P 888            888         888     888        888  T88b  Y88b  d88P
//    "Y8888P88 8888888888     888         888     8888888888 888   T88b  "Y8888P"

const getters = {

  GET_PLANNER_SAMPLE: (state) => {
    return state.plannerOrderFlatList
  }

  // GET_ARTIKELEN_SAMPLE: (state) => {
  //   return state.artikelen || {}
  // },
  //
  // ABO_ERR_SAMPLE: (state) => state.errorMessage,

};

// actions, async
const actions = {

  // WRITE_ARTIKEL_BUSKET_VALUE_SAMPLE: async (context, {id, value}) => {
  //   // console.log('id', id)
  //   // console.log('value', value)
  //   // console.log('artikelen', context.getters.GET_ARTIKELEN)
  //
  //   const artikel_index = context.getters.GET_ARTIKELEN.findIndex((art) => art.id === id)
  //
  //   if (artikel_index >= 0) {
  //     context.commit('SET_ARTIKEL_BUSKET_OP_INDEX', {artikel_index, value})
  //   }
  // },

};

// ****************************    MUTATIONS    ****************************** //

// mutations, sync
const mutations = {
  SET_PLANNER_SAMPLE: (state, data) => {
    state.plannerOrderFlatList = data
  }
  // SET_SAMPEL: (state, data) => {
  //   state.sampel = data
  // },

};  // Mutations

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
