<template>
  <v-card
      class="mx-auto mt-10 align-end pa-4"
      max-width="800"
  >
    <v-img
        :src="require('../assets/DKG-logo-breed-path-m2.svg')"
        class="my-3"
        contain
        height="150px"
    >
    </v-img>
    <h2
        class="mx-12"
    >{{ GET_CURRENT_SNELSTART['naam'] }}</h2>
    <v-card-text>
      <!--            <v-list two-line subheader>-->
      <!--                <v-list-item-->
      <!--                        v-for="(value, name) in GET_CURRENT_SNELSTART['bedrijf_info']"-->
      <!--                        :key="name"-->
      <!--                        @click="false"-->
      <!--                >-->
      <!--                    <v-list-item-content>-->
      <!--                        <v-list-item-title>{{ name }}</v-list-item-title>-->
      <!--                        <v-list-item-subtitle>{{ value }}</v-list-item-subtitle>-->
      <!--                    </v-list-item-content>-->
      <!--                </v-list-item>-->
      <!--            </v-list>-->
      <v-treeview
          shaped
          dense
          hoverable
          :items="bedrijfInfo"
      ></v-treeview>
    </v-card-text>

    <v-card-text
      v-if="GET_IS_SUPERUSER"
    >
<!--      <p>{{ GET_CURRENT_PROFIEL.first_name }}</p>-->
<!--      <p>{{ GET_CURRENT_PROFIEL.last_name }}</p>-->
<!--      <p>{{ GET_CURRENT_PROFIEL.username }}</p>-->
<!--      <p v-if="'userprofile' in GET_CURRENT_PROFIEL">{{ GET_CURRENT_PROFIEL.userprofile }}</p>-->

      <p>Operator dict (GET_OPERATORS):</p>
      <v-treeview
          shaped
          dense
          hoverable
          :items="parseInfo2Tree(GET_OPERATORS)"
      ></v-treeview>
      <hr/>
      <p>Current operators list (GET_CURRENT_OPERATORS):</p>
      <v-treeview
          shaped
          dense
          hoverable
          :items="parseInfo2Tree(GET_CURRENT_OPERATORS)"
      ></v-treeview>
      <hr/>
      <p>Profiel:</p>
      <v-treeview
          shaped
          dense
          hoverable
          :items="parseInfo2Tree(GET_CURRENT_PROFIEL)"
      ></v-treeview>
      <hr/>
      <p>Operators:</p>
      <v-treeview
          shaped
          dense
          hoverable
          :items="parseInfo2Tree(GET_OPERATORS_LIST)"
      ></v-treeview>
      <hr/>
      <p>Modules:</p>
      <v-treeview
          shaped
          dense
          hoverable
          :items="parseInfo2Tree(GET_MODULES_LIST)"
      ></v-treeview>
      <hr/>
      <p>Klantgegevens:</p>
      <v-treeview
          shaped
          dense
          hoverable
          :items="parseInfo2Tree(GET_KLANTGEGEVENS_LIST)"
      ></v-treeview>
      <hr/>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Dashboard",
  computed: {
    ...mapGetters('user', [
        "GET_CURRENT_SNELSTART",
        "GET_CURRENT_PROFIEL",
        "GET_OPERATORS_LIST",
        "GET_MODULES_LIST",
        "GET_KLANTGEGEVENS_LIST",
        "GET_IS_SUPERUSER",
        "GET_OPERATORS",
        "GET_CURRENT_OPERATORS"
    ]),

    bedrijfInfo: function () {
      return this.parseInfo2Tree(this.GET_CURRENT_SNELSTART['bedrijf_info'])
    },
  },  // COMPUTED

  methods: {
    parseInfo2Tree(infoObject, maxDeep = 6, listObject = []) {
      if (infoObject instanceof Object) {
        for (const infoObjectElement of Object.keys(infoObject)) {
          if (maxDeep <= 0) {
            listObject.push(
                {
                  id: 'Deep limit',
                  name: '...',
                }
            )
            return listObject
          }
          if (infoObject[infoObjectElement] instanceof Object) {
            // console.log('Object item', infoObjectElement)

            listObject.push(
                {
                  id: infoObjectElement,
                  name: infoObjectElement,
                  children: this.parseInfo2Tree(infoObject[infoObjectElement], maxDeep - 1),
                }
            )
          } else {
            listObject.push(
                {
                  id: infoObjectElement,
                  name: infoObjectElement + ': ' + infoObject[infoObjectElement],
                }
            )
          }
        }
      }
      return listObject
    }
  }
}
</script>

<style scoped>

</style>
