<template>
  <div class="flex">
    <v-card
        class="mx-auto mt-6 align-end pa-2"
        max-width="36em"
    >
      <a
          href="https://www.snelstart.nl/ImplementatiePartners/dkg"
          target="_blank"
      >
        <v-img
            :src="require('@/assets/SnelStartPartner.svg')"
            :width="$store.getters['app/GET_GUI_COMPACT'] ? '20%': '25%'"
        >
        </v-img>
      </a>
      <!--      <p>v: 0.1</p>   -->
      <!--      LOGO  -->
      <v-img
          :src="require('@/assets/DKG-portal.svg')"
          class="mb-0 mt-n3"
          contain
          :height="$store.getters['app/GET_GUI_COMPACT'] ? '8em': '10em'"
      >
      </v-img>

      <!--      SnelStart Portal intro card   -->
      <v-card-text>
        <OverDKG msg="Welkom op DKG portaal"/>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import OverDKG from '@/views/OverDKG'

export default {
  name: 'Home',
  components: {
    OverDKG
  }
}
</script>

<style>

</style>