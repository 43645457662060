// Icons lijst voor
export const processStatusIconList = [
  "mdi-file-multiple-outline",  // 0
  "mdi-file-hidden",            // 1
  "mdi-file-eye-outline",       // 2
  "mdi-file-edit-outline",      // 3
  "mdi-file-cog-outline",       // 4
  "mdi-file-document-outline",  // 5
  "mdi-file-export-outline",    // 6
  "mdi-receipt-text-outline",   // 7
  "mdi-file-check-outline",     // 8
];

export const processStatusSearchList = [
  'Onbekend',   // 0
  'Order',      // 1
  'Offerte',    // 2
  'Bevestiging',// 3
  'Werkbon',    // 4
  'Pakbon',     // 5
  'Afhaalbon',  // 6
  'Contantbon', // 7
  'Factuur',    // 8
];

export const processStatusListEnabled = [
  {id: 1, text: "Order", icon: "mdi-file-hidden",},
  {id: 2, text: "Offerte", icon: "mdi-file-eye-outline",},
  {id: 3, text: "Bevestiging", icon: "mdi-file-edit-outline",},
  {id: 4, text: "Werkbon", icon: "mdi-file-cog-outline",},
  {id: 5, text: "Pakbon", icon: "mdi-file-document-outline",},
  {id: 6, text: "Afhaalbon", icon: "mdi-file-export-outline",},
  {id: 7, text: "Contantbon", icon: "mdi-receipt-text-outline",},
  {id: 8, text: "Factuur", icon: "mdi-file-check-outline",},
  // {id: 9, text: "Test", icon: processStatusIconList[0],},
];

export const processStatusListFilter = [
  {id: 0, text: "<Alle>", icon: "mdi-file-multiple-outline",},
  {id: 1, text: "Order", icon: "mdi-file-hidden",},
  {id: 2, text: "Offerte", icon: "mdi-file-eye-outline",},
  {id: 3, text: "Bevestiging", icon: "mdi-file-edit-outline",},
  {id: 4, text: "Werkbon", icon: "mdi-file-cog-outline",},
  {id: 5, text: "Pakbon", icon: "mdi-file-document-outline",},
  {id: 6, text: "Afhaalbon", icon: "mdi-file-export-outline",},
  {id: 7, text: "Contantbon", icon: "mdi-receipt-text-outline",},
  {id: 8, text: "Factuur", icon: "mdi-file-check-outline",},
];

// "DocumentStatus van de order. Als deze niet is opgegeven wordt de default waarde Order gebruikt.
//  Contantbon en Factuur zijn niet beschikbaar om te maken",
export const processStatusList = [
  {id: 1, text: "Order", icon: "mdi-file-hidden",},
  {id: 2, text: "Offerte", icon: "mdi-file-eye-outline",},
  {id: 3, text: "Bevestiging", icon: "mdi-file-edit-outline",},
  {id: 4, text: "Werkbon", icon: "mdi-file-cog-outline",},
  {id: 5, text: "Pakbon", icon: "mdi-file-document-outline",},
  {id: 6, text: "Afhaalbon", icon: "mdi-file-export-outline",},
  {id: 7, text: "Contantbon", icon: "mdi-receipt", disabled: true},
  {id: 8, text: "Factuur", icon: "mdi-file-check-outline", disabled: true},
];

export default {
  processStatusIconList,
  processStatusList,
  processStatusListEnabled,
  processStatusListFilter,
  processStatusSearchList,
};