import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{staticClass:"mx-8",attrs:{"dense":_vm.GUI_COMPACT}},[_c(VListItemIcon,{staticClass:"mr-4"},[(_vm.link.icon)?_c(VIcon,{attrs:{"large":!_vm.GUI_COMPACT,"color":_vm.link.color}},[_vm._v(_vm._s(_vm.link.icon)+" ")]):_c(VImg,{attrs:{"src":_vm.link.svg,"contain":"","aspect-ratio":"1","max-width":_vm.GUI_COMPACT ? 24 : 36}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.link.text)}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }