<template>

  <v-container
      id="page-container"
      class="fill-height"
      fluid
  >
    <v-layout
        align-center
        justify-center
    >
      <v-flex
          class="login-form"
      >
                <v-card
            id="login-form"
            class="elevation-12"
        >
          <v-toolbar
              id="form-header"
              color="accent"
              dark
              flat
          >
            <v-toolbar-title
            >Inloggen in DKG portal</v-toolbar-title>

            <v-spacer/>

            <v-tooltip
                right
                color="#E8E8E8"
                open-delay="1000"
                close-delay="2000"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                    icon
                    large
                    href="https://www.snelstart.nl"
                    target="_blank"
                    max-width="30%"
                    v-on="on"
                >
                  <v-img
                      alt="SnelStart BV"
                      class="mx-2"
                      :src="require('@/assets/snelstart.svg')"
                      shrink
                      max-width="15%"
                  />
                </v-btn>
              </template>
              <v-img
                  width="200"
                  height="40"
                  contain
                  :src="require('@/assets/SnelStart_logo.svg')"
              >
              </v-img>
            </v-tooltip>
          </v-toolbar>
          <v-card-text
              id="form-inner"
          >
            <v-form
                v-model="formValid"
            >
              <v-text-field
                  v-model="name"
                  label="Login"
                  name="login"
                  :value="GET_USER_NAME"
                  prepend-icon="mdi-account"
                  :rules="[rules.min4]"
                  type="text"
                  maxlength=150
                  :disabled="GET_IS_INGELOGD"
                  @keydown.enter="loginEnter"
              />

              <v-text-field
                  id="pass-field"
                  ref="password"
                  v-model="password"
                  label="Wachtwoord"
                  name="password"
                  prepend-icon="mdi-lock"
                  :type="showEye ? 'text' : 'password'"
                  :append-icon="showEye ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="GET_IS_INGELOGD ? [] : [rules.min8]"
                  :disabled="GET_IS_INGELOGD"
                  maxlength=8192
                  @click:append="showEye = !showEye"
                  @keydown.enter="passwordEnter"
              />
              <!--                      hint="Ten minste 8 tekens"-->
              <!--                      :rules="[rules.required, rules.min8]"-->

              <!--              <v-checkbox-->
              <!--                      v-model="agree"-->
              <!--                      :rules="[v => !!v || 'Je moet ermee instemmen om door te gaan']"-->
              <!--                      label="Ben je het eens?"-->
              <!--              ></v-checkbox>-->

              <p class="ma-2">
                <a
                    href="https://www.dkg.nl/terms"
                    target="_blank"
                >algemene voorwaarden</a>
              </p>

            </v-form>
          </v-card-text>
          <v-card-actions
              id="buttons"
          >

<!--        LOGOUT  -->
            <v-btn
                id="logout"
                color="error"
                :disabled="!GET_IS_INGELOGD"
                @click="$store.dispatch('user/USER_LOGOUT')"
            >Logout</v-btn>

            <v-spacer />

<!--        LOGIN -->
            <v-btn
                id="inloggen"
                color="primary"
                @click="inloggen"
                :loading="inloggenRun"
                :disabled="!formValid || inloggenRun || GET_IS_INGELOGD"
            >Inloggen</v-btn>

          </v-card-actions>
        </v-card>
<!--        <v-btn-->
<!--            class="ma-8 align-center"-->
<!--            small-->
<!--        >Aanvragen</v-btn>-->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// import axios from "axios";
// import common from "../utils/common"

import {mapGetters} from "vuex";
import common from "@/utils/common";
// import router from "@/router";

export default {
  name: "Login",

  props: {
    source: String,
  },

  data() {
    return {
      showEye: false,
      name: '',
      password: '',
      formValid: false,
      agree: false,
      rules: {
        required: value => !!value || 'Dit veld is vereist',
        min8: v => v.length >= 8 || 'Ten minste 8 tekens',
        min4: v => v.length >= 4 || 'Ten minste 4 tekens',
        // emailMatch: () => ('The email and password you entered don\'t match'),
      },
      access: null,
      refresh: null,
      inloggenRun: false,
    }
  },  // DATA

  methods: {
    addLogBook(text, level, titel, popUp = false) {
      common.fn.addMessage2Drawer(text, level, titel, 'Login', popUp)
      // this.$store.dispatch(
      //     "app/ADD_LOG_MESSAGE",
      //     new common.ErrorMessage(
      //         text,
      //         level,
      //         titel,
      //         `Login`,
      //     )
      // )
    },

    loginEnter() {
      if (this.name.length >= 4) {
        this.$nextTick(() => this.$refs.password.focus())
      }
    },

    passwordEnter() {
      if (this.password.length >= 8 && !this.inloggenRun) {
        this.inloggen()
      }
    },

    async inloggen() {
      // Prevent double login
      this.inloggenRun = true

      this.addLogBook('Inlog knop is gedrukt', common.errorLevels.Debug, 'inloggen()')

      await this.$store.dispatch("user/USER_LOGIN", {
        "username": this.name,
        "password": this.password,
      })
      .catch((err) => {
        console.error('inloggen.catch: ', err)
        // this.addLogBook(err.message, common.errorLevels.Error, 'Inloggen')
      })
      .then(() => {
        // console.log('inloggen(): Then, GET_IS_INGELOGD:', this.GET_IS_INGELOGD)

        // if (this.GET_IS_INGELOGD) {
        //   setTimeout(() => {
        //     this.password = "********"
        //   }, 500)
        //
        //   console.log('Over 1.5 sec, Redirect naar Abonnement...')
        //   setTimeout(() => {
        //     if (this.GET_IS_INGELOGD) {
        //       router.push({name: 'Abonnement'})
        //     }
        //   }, 1500)
        // }
      })
      .finally(() => {
        // console.log('inloggen(): Finally')
        this.inloggenRun = false
      })
      // console.log('inloggen(): End')
    },
  }, //METHODS

  computed: {
    ...mapGetters({
      GET_API_CREATE_JWT_URI: "app/GET_CREATE_JWT_URI",
      GET_IS_INGELOGD: "user/GET_IS_INGELOGD",
      GET_USER_NAME: "user/GET_USER_NAME",
    })
  }, // COMPUTED
}
</script>

<style scoped>
  .login-form {
    max-width: 500px;
  }
</style>
