import axios from "axios";
import store from "../store/index"
// import common from "@/utils/common";

const api = {
  /**
   * GET multipage request
   * @param uri - API URI
   * @param params - extra parameters voor aanvraag
   * @param token - explicit token
   * @param maxCount - Maximaal records om te lezen / hard limit
   * @param commitTo - commit name: 'snelstart/SET_LANDEN'
   * @param progressGetter
   * @param progressSetter
   * @returns {Promise<{data: [], error: null, status: number}>}
   */
  getAPIRequestMultipage: async function (
    {
      uri,
      params = {},
      token = null,
      maxCount = Number.MAX_SAFE_INTEGER,
      commitTo = null,
      progressGetter = null,
      progressSetter = null,
    })
  {
    // Progress structuur
    let progress
    if (progressGetter) {
      progress = store.getters[progressGetter]
    } else {
      /**
       * API PROGRESS OBJECT
       * @type {{
       *  name:       string,
       *  loading:    boolean,
       *  apiTotal:   null,
       *  apiCount:   null,
       *  procent:    number,
       *  cancel:     boolean,
       *  status:     null,
       *  statusText: string,
       *  }}
       */
      progress = {
        name: 'Onbekend entity',
        loading: false,   // Bezig met laden:           true - laden in progress/ false - geen operatie
        apiTotal: null,   // Totaal records in api:     null - niet gelezen / 0 - geen data
        apiCount: null,   // All gelezen records:       null - niet gelezen / 0 - geen data
        procent: 0,       // Procent gelezen            0-100 %
        cancel: false,    // Moet geannuleerd:          true - operatie moet afbreken
        status: null,     // Laatste http status code:  200 - ok
        statusText: '',   // Laatste fout bericht van API of netwerk
      }
    }

    if (progress.loading) {
      throw 'Operatie in progress, kan niet weer uitvoeren'
    }

    // Init fase
    progress.loading = true
    progress.procent = 0
    progress.cancel = false
    progress.status = 0
    progress.apiCount = null
    progress.apiTotal = null
    progress.statusText = 'API Init'

    if (progressSetter) { store.commit(progressSetter, progress) }

    // TODO: Result reduceren
    /**
     * RESULT DATA
     * @type {{ data: [], error: null, status: number }}
     */

    let resultData = {
      status: 0,
      data: [],
      error: null,
    }

    let responseStepData
    let repeat = 0
    let currentCount = 0

    let configAuth = store.getters["user/GET_AXIOS_CONFIG"]

    // Explicit token?
    if (token) {
      configAuth.headers = {
        Authorization: 'JWT ' + token
      }
    }

    // Fout in param!!!
    console.log('params =', params)

    // Config voor eerste aanvraag
    let config = Object.assign({}, configAuth, {params})
    /*
      {
        headers: ...
        params: ...
      }
     */
    console.log('config (init)', config)
    // Config voor alle volgende aanvragen
    // let configRepeat = Object.assign({}, /* configAuth, */ config)

    // Eerste url - False / Volgende - True
    let repeatURL = false

    do {
      try {
        if (repeatURL) {
          delete config.params
        }

        console.log('GET: uri=', uri)
        console.log('config=', config)

        responseStepData = await axios.get(uri, config)
        console.log('responseStepData=', responseStepData)

        if (progressGetter) {
          progress = store.getters[progressGetter]
        }

        if (progress.cancel) {
          throw new Error('Canceling')
        }

        resultData.status = responseStepData.status
        progress.status = responseStepData.status
        progress.statusText = responseStepData.statusText

        // console.log("Response", responseStepData)

      } catch (error) {
        // Error 💀 of cancel ❌
        // TODO: Route naar fout melding en logboek
        console.error('Error obj =>', error);

        if (++repeat < 10 && error !== 'Canceling' && !progress.cancel) {
          console.log("RETRY ", repeat)
          if (error?.request?.status === 403) {
            await store.dispatch("user/REFRESH_TOKEN").then(() => {
              // Token opnieuw lezen van user profiel
              configAuth = store.getters["user/GET_AXIOS_CONFIG"]
              // config = configRepeat
              config = Object.assign({}, config, configAuth)
              console.log('config (after repeat)', config)
            })
          }
          // config = Object.assign({}, store.getters["user/GET_AXIOS_CONFIG"], params)
          continue
        }

        progress.statusText = error
        progress.loading = false

        if (progressSetter) { store.commit(progressSetter, progress) }

        resultData.error = error
        resultData.status = 500
        return resultData
      }

      if (responseStepData.status >= 200 && responseStepData.status <= 299) {
        // Success 🤘
        let respCount = responseStepData.data.results.length
        let nextCount = currentCount + respCount

        // console.log('responseStepData:')
        // console.dir(responseStepData)

        // Past in hard limit?
        if (nextCount <= maxCount) {
          uri = responseStepData.data.next
          resultData.data = resultData.data.concat(responseStepData.data.results)
          currentCount += respCount
        } else {
          // Past niet, knip resultaat tot maxCount
          resultData.data = resultData.data.concat(responseStepData.data.results.slice(0, maxCount - currentCount))
          uri = false
        }

        repeatURL = true

        console.log('next uri =', uri)

        progress.apiTotal = responseStepData.data.count
        progress.apiCount = resultData.data.length
        progress.loading = !!uri

        if (progress.apiTotal === 0) {
          progress.procent = 100
        } else {
          progress.procent = Math.floor(progress.apiCount * 100 / progress.apiTotal)
        }

        if (progressSetter) { store.commit(progressSetter, progress) }
        console.log('Request result:', resultData)
        if (commitTo) { store.commit(commitTo, resultData.data) }

      // Fout status
      } else {
        // TODO: Route naar fout melding en logboek
        console.error("resultData = ", responseStepData.data)

        progress.loading = false

        if (progressSetter) { store.commit(progressSetter, progress) }

        resultData.error = responseStepData.data
        return resultData
      }
      // console.log('configRepeat (while)', configRepeat)
      // config = configRepeat
    } while (uri)

    // return data

    return resultData
  },

  // // GET multipage request met token
  // getAPIRequestMultiToken: async function (uri, token) {
  //   // Hoofd URI
  //   // Current user
  //
  //   let resultData = []
  //   let respData
  //
  //   do {
  //     respData = await axios.get(uri,
  //       {
  //         headers: {
  //           Authorization: 'JWT ' + token
  //         },
  //         timeout: 15000
  //       })
  //
  //     if (respData.status === 200) {
  //       // Success 🎉
  //       resultData = resultData.concat(respData.data.results)
  //       uri = respData.data.next
  //
  //     } else {
  //       console.dir(respData)
  //       throw new Error("Request getMultiToken() error...")
  //     }
  //   } while (uri)
  //
  //   // return data
  //   return resultData
  // },

  async getRefreshToken(uri, token) {

    console.log("api:getRefreshToken()")
    console.log("Token:", token)

    if (token === null) {
      console.error("Token is Null, kan niet refreshen")
      return null
    }

    let refreshTokenResponse = await axios.post(
      uri,
      {refresh: token.jwt},
      {timeout: 15000},
    )
      .catch(error => {
        console.log("axios.post Error:")
        console.error(error)
        return null
      })

    console.log("getRefreshToken(): Response")
    console.dir(refreshTokenResponse)

    if (refreshTokenResponse && refreshTokenResponse.status === 200) {
      return refreshTokenResponse.data
    }
    return null
  },

} // API

export default api
