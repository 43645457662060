import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.GET_SNELSTART_LIST.length > 1)?_c(VSelect,{staticClass:"mx-1 mt-7 shrink",attrs:{"items":_vm.GET_SNELSTART_LIST,"item-value":"id","label":"Kiez administratie","prepend-inner-icon":"mdi-star","solo":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.actief_api)?_c(VIcon,{staticClass:"mr-2",attrs:{"color":"accent"}},[_vm._v("mdi-pipe")]):_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-pipe-disconnected")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v(_vm._s(item.naam))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(item.actief_api)?_c(VIcon,{staticClass:"mr-2",attrs:{"color":"accent"}},[_vm._v("mdi-pipe")]):_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-pipe-disconnected")]),_vm._v(" "+_vm._s(item.naam)+" ")]}},{key:"prepend-inner",fn:function(){return [_c(VFadeTransition,{attrs:{"leave-absolute":""}},[(false)?_c(VProgressCircular,{attrs:{"size":"24","color":"info","indeterminate":""}}):_c('img',{attrs:{"width":"24","height":"24","src":require('@/assets/snelstart.svg'),"alt":"SnelStart"}})],1)]},proxy:true}],null,false,871580886),model:{value:(_vm.selectedSnelStart),callback:function ($$v) {_vm.selectedSnelStart=$$v},expression:"selectedSnelStart"}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }