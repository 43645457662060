<template>
  <v-app-bar
      app
      clipped-left
  >
    <v-app-bar-nav-icon @click.stop="$store.dispatch('app/TOGGLE_DRAWER')"/>

    <v-img
        v-if="comp_show_DKG_logo"
        alt="DKG Automatisering"
        class="mx-2"
        :src="require('@/assets/DKG-logo-breed-path-m2.svg')"
        contain
        max-width="12%"
        min-width="12%"
        height="100%"
    />

    <v-toolbar-title
        class="mx-4"
        v-html="comp_getAppTitel"
        v-if="GET_SNELSTART_LIST.length === 1"
    ></v-toolbar-title>

    <drop-down-snel-start/>

    <!--      Ruimte tussen -->
    <v-spacer></v-spacer>

    <v-col
        cols="auto"
        style="max-width:40vh;"
        v-if="GET_IS_INGELOGD"
    >
      <!--      <v-row>-->
      <v-window
          v-show="showHelperText"
          v-model="helpItem"
          style="font-size: small;"
          class="justify-end"
          vertical
      >
        <v-window-item>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua.
        </v-window-item>
        <v-window-item>2. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat.
        </v-window-item>
        <v-window-item>3. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur.
        </v-window-item>
        <v-window-item>4. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
          id est laborum.
        </v-window-item>
      </v-window>
      <!--      </v-row>-->
    </v-col>

    <v-badge
        v-model="tips"
        content="Klik voor hint"
        color="secondary"
        offset-x="10em"
        class="hidden-sm-and-down"
    >
      <v-icon
          @mouseover="tips = true"
          @mouseleave="tips = false"
          @click="m_showTextTip"
          class="mx-2"
          :color="tips ? 'secondary' : ''"
      >mdi-led-{{ tips ? 'on' : 'outline' }}
      </v-icon>
    </v-badge>
    <!--    <popup-profile></popup-profile>-->

    <!--    Profile popup menu-->
    <popup-profile
        v-if="GET_IS_INGELOGD"
        :user-profile="GET_CURRENT_PROFIEL"
    ></popup-profile>

<!--    <v-icon v-if="GET_LOG_BOOK_COUNT === 0"-->
<!--    >mdi-bell-outline-->
<!--    </v-icon>-->

<!--    <template v-else>-->
      <v-hover v-model="hover"
               class="mr-4"
      >
        <v-badge
            :value="hover && GET_LOG_BOOK_COUNT"
            color="primary accent-1"
            :content="GET_LOG_BOOK_COUNT"
            transition="slide-y-transition"
            bordered
            overlap

        >
          <v-icon
              @click="$store.dispatch('app/TOGGLE_ERROR_DRAWER')"
          >{{ GET_LOG_BOOK_COUNT === 0 ? 'mdi-bell-outline' : 'mdi-bell-ring' }}
          </v-icon>
        </v-badge>
      </v-hover>
<!--    </template>-->
    <!--      Themas: light/dark switch    -->

    <brightness-switch
        class="ml-6 hidden-sm-and-down"
        :style="$vuetify.breakpoint.mdAndUp ? 'min-width: 8em;' : 'min-width: 3em;'"
    ></brightness-switch>

    <v-icon
        v-if="GET_GUI_COMPACT"
        @click="$store.commit('app/SET_GUI_COMPACT', false)"
    >mdi-desktop-classic
    </v-icon>
    <v-icon
        v-else
        @click="$store.commit('app/SET_GUI_COMPACT', true)"
    >mdi-tablet
    </v-icon>
    <!--  </v-toolbar>  -->
  </v-app-bar>
</template>

<script>
import PopupProfile from "./PopupProfile";
import DropDownSnelStart from "./DropDownSnelStart";
import {mapGetters, mapMutations} from "vuex";
import BrightnessSwitch from "../components/BrightnessSwitch";
import common from "../utils/common";

export default {
  name: "AppTopBar",

  data: () => ({
    hover: false,
    helpItem: 0,
    showHelperText: false,
    helperTextCount: 4,
    helperTextTimerID: null,
  }),

  components: {
    BrightnessSwitch,
    PopupProfile,
    DropDownSnelStart,
  },

  computed: {
    ...mapGetters('user', [
      'GET_IS_INGELOGD',
      'GET_CURRENT_PROFIEL',
      'GET_CURRENT_BEDRIJF_NAAM',
      'GET_SNELSTART_LIST',
    ]),

    ...mapGetters('app', [
      'GET_LOG_BOOK',
      'GET_LOG_BOOK_COUNT',
      'GET_TIPS',
      'GET_GUI_COMPACT'
    ]),

    comp_show_DKG_logo() {
      //  &&
      // if (this.GET_CURRENT_PROFIEL?.userprofile?.front_config) {
      //   return this.GET_CURRENT_PROFIEL.userprofile.front_config?.show_DKG_logo
      // }
      // return true
      return common.fn.deepGet(
          this.GET_CURRENT_PROFIEL,
          'userprofile.front_config.show_DKG_logo',
          true
      )
    },

    comp_getAppTitel() {
      return common.fn.deepGet(
          this.GET_CURRENT_PROFIEL,
          'userprofile.front_config.titel',
          this.GET_CURRENT_BEDRIJF_NAAM || 'DKG (β) Portal'
      )
    },

    tips: {
      get() {
        return this.GET_TIPS
      },
      set(val) {
        this.SET_TIPS(val)
      }
    }
  },  // computed

  methods: {
    ...mapMutations('app', [
      'SET_TIPS',
    ]),

    m_showTextTip() {
      // helperTextTimerID
      // Vorige timer loopt?
      if (this.helperTextTimerID !== null) {
        // Stop timer
        clearTimeout(this.helperTextTimerID)
        this.helperTextTimerID = null
      }

      this.helperTextTimerID = setTimeout(() => {
        this.showHelperText = false
        this.helpItem++
        if (this.helpItem >= this.helperTextCount) this.helpItem = 0
      }, 15000)
      this.showHelperText = true
      common.fn.addMessage2Drawer('Komt binnenkort...', 1, '😷 Hint', 'Test', true)
    },
  },

  watch: {
    // tips() {
    // }
  },
}
</script>

<style scoped>
</style>
