<template>
  <v-card class="ma-4">
    <v-toolbar
        color="primary"
        dense
    >
<!--  <v-app-bar-nav-icon></v-app-bar-nav-icon>-->
      <v-toolbar-title>
        Server logboek [aantal: <b>{{ GET_PROCESS_LIST.length }}</b>]
      </v-toolbar-title>
      <v-btn
          color="secondary"
          :loading="autoUpdate.isLoading"
          @click="processListUpdate"
          class="mx-2"
      >Vernieuwen
      </v-btn>

<!--  Auto update toggle button-->
<!--              color="primary"-->
      <v-btn-toggle
          v-model="autoUpdate.toggle"
          dense
          group
          multiple
      >
        <v-btn
            :value="autoUpdate.value"
        >
<!--                  :color="inProgress ? 'green' : 'gray'"-->
          <v-icon>mdi-refresh-circle</v-icon>
          Auto update
        </v-btn>
      </v-btn-toggle>

    </v-toolbar>
    <v-list
        subheader
    >
<!--          tag="v-list-item"-->
      <transition-group
          name="list"
          tag="span"
      >
<!--            v-for="item in testList"-->
        <v-list-item
            name="log-list-item"
            v-for="item in GET_PROCESS_LIST"
            :key="item.uuid"
            @click="{}"
            style="border-bottom: solid 1px lightgrey"
        >
          <v-list-item-content>
            <v-list-item-title>
              <b>{{ item.id }}:</b> {{ item.name }}
            </v-list-item-title>

            <v-list-item-subtitle
                class="my-2"
            >
              <b>UUID:</b>
                {{ item.uuid }}
            </v-list-item-subtitle>

            <v-list-item-subtitle
                class="my-2"
            >
              <b>Started:</b>
                {{ item.started | moment('DD-MM-YYYY HH:mm:ss') }} /
              <b> Stopped:</b>
                {{ item.stopped | moment('DD-MM-YYYY HH:mm:ss') }}
            </v-list-item-subtitle>

            <span v-html="formatLogMessage(item.result)"></span>

            <br />
          </v-list-item-content>
        </v-list-item>
      </transition-group>
    </v-list>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import {mixin as VueTimers} from "vue-timers";

export default {
  name: "Process",
  mixins: [VueTimers],
    // vue-timers module https://www.npmjs.com/package/vue-timers
  timers: {
    runTimerUpdate: {
      time: 10000,
      repeat: true,
      autostart: true,
    }
  },
  data: () => ({
    // Auto update button
    testList: [],
    testItem : {
      id: new Date(),
      name: "Testing",
      uuid: "0000-00000000...",
      result: `Test 1...
      Text 2 ...`,
    },
    autoUpdate: {
      isLoading: false,
      toggle: [],
      value: 0,
    },
  }),

  mounted() {
    this.processListUpdate()
  },

  computed: {
    ...mapGetters('user', [
      'GET_PROCESS_LIST',
    ]),
  },

  methods: {
    formatLogMessage(lines) {
      let line
      let classLine
      let page = ""

      for (line of lines ? lines.split('\n') : []) {
        classLine = this.getColorClass4Log(line)
        page += classLine ? `<span class="${classLine}">${line}</span><br />` : `${line}<br />`
      }
      return page
    },

    getColorClass4Log: (line) => {
      const translate = {
        'DEBUG: ': 'grey',
        'WARNING: ': 'orange',
        'ERROR: ': 'error',
        'INFO: ': 'info',
        'oData=': 'accent',
        'Ok: ': 'green',
      }
      for (let translate_key of Object.keys(translate)) {
          if (line.search(` ${translate_key}`) > 0) {
            return `${translate[translate_key]}--text`
          }
        }
    },

    async runTimerUpdate() {
        // Is Auto update on?
        if (this.autoUpdate.toggle.indexOf(this.autoUpdate.value) >= 0) {
          await this.processListUpdate()
      }
    },

    async processListUpdate() {
      if (!this.autoUpdate.isLoading) {
        // Loading indicator on
          this.autoUpdate.isLoading = true
          this.$store.dispatch('user/READ_PROCESS_LIST')
              .finally(() => {
                this.autoUpdate.isLoading = false
              })
      }
    }
  }
}
</script>

<style scoped>
.list-enter-active,
.list-leave-active,
.list-move {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.list-enter {
  opacity: 0;
  transform: translateX(50px) scaleY(0.5);
}

.list-enter-to {
  opacity: 1;
  transform: translateX(0) scaleY(1);
}

.list-leave-active {
  position: absolute;
}

.list-leave-to {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center top;
}
</style>
