import store from '../store'

export const errorLevels = {
  Debug: 0,
  Info: 1,
  Warning: 2,
  Error: 3,
  Fatal: 4,
}

export const errorIcons = [
  "mdi-bug",
  "mdi-information",
  "mdi-alert-circle",
  "mdi-alert",
  "mdi-alert-octagon",
]

export const errorColors = [
  "primary",      // 0 - Debug
  "secondary",    // 1 - Info (default)
  "warning",      // 2 - Warning
  "error",        // 3 - Error
  "pink",         // 4 - Fatal
]

export const errorColorsDark = [
  "primary",      // 0 - Debug
  "secondary",    // 1 - Info (default)
  "warning",      // 2 - Warning
  "error",        // 3 - Error
  "pink",         // 4 - Fatal
]

export const errorTitels = [
  "Debug",
  "Informatie",
  "Waarschuwing",
  "Fout",
  "Fataal",
]

// Euro filter voor geld formaat maken
export const euroFilter = new Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
});

class ErrorMessage {
  constructor(
    text,
    level = errorLevels.Info,
    titel = undefined,
    module = "main",
    popUp = false,
  ) {
    this.titel = titel || errorTitels[level]
    this.text = text
    this.level = level
    this.module = module
    this.popUp = popUp

    this.hidden = false
    this.time = Date.now()
  }

  send() {
    // actions.ADD_LOG_MESSAGE(this)
  }
}

const fn = {
// token: {     // Token
//       exp: 1594394874,  // Expire UNIX time
//       jti: "7800540007984bb1b1b0839n583c9b4b",  // Token key
//       jwt: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNTk0Mzk0ODc0LCJqdGkiOiI3ODAwNTQwMDA3NTg0YmIxYjFiMDgzOWQ1ODNjOWI0YiIsInVzZXJfaWQiOjF9.rVJG3zcqG4Z2_cPVQaMI1Rv2clTOSSsG1BDJnlDwyOc",
//       time: "Date Fri Jul 10 2020 17:27:54 GMT+0200 (Midden-Europese zomertijd)", // Expire time, JavaScript Date()
//       token_type: "access", // JWT soort
//       user_id: 1, // Django user ID
//     },

  multiline2HTML(text) {
    // Replace all \r\n -> <br/>
    if (typeof text === 'string') {
      return text.replace(/\r?\n/g, '<br/>')
    } else {
      return JSON.stringify(text)
    }
  },

  copyElementToClipboard(event) {
    console.log('common.fn.copyElementToClipboard()')
    console.dir(event)

    let elem = event.target
    const text = elem.value.substring(elem.selectionStart, elem.selectionEnd)
    console.log("Select =>", text)
    document.execCommand('copy')
  },

  parseJwt(token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
    const jsonData = JSON.parse(jsonPayload)
    // add expired time
    jsonData.time = new Date(jsonData.exp * 1000)
    // source token
    jsonData.jwt = token
    return jsonData
  },

  getRemainderTime(token) {
    // console.log("Now =>", typeof now, now)
    // console.log("token.now =>", typeof tokenTime, tokenTime)
    // console.log("remain => ", tokenTime - now)

    return new Date() - token.time
  },

  msToHuman: function (duration) {
    // var milliseconds = parseInt((duration%1000)/100)
    let negatief = ''

    if (duration < 0) {
      negatief = "- "
      duration = -duration
    }

    let seconds = Math.floor((duration / 1000) % 60)
    let minutes = Math.floor((duration / (1000 * 60)) % 60)
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

    seconds = seconds < 10 ? "0" + seconds : seconds.toString()
    minutes = minutes < 10 ? "0" + minutes : minutes.toString()
    hours = hours < 10 ? "0" + hours : hours.toString()

    return negatief + hours + ":" + minutes + ":" + seconds
  },
  /**
   * * @category Object
   * @param {Object} obj The object to query.
   * @param {Array|string} key The path of the property to get.
   * @param {*} [def] The value returned for `undefined` resolved values.
   * @param {int} p intern loop
   * @param {undefined} undef intern
   * @returns {*} Returns the resolved value.
   * @see has, hasIn, set, unset
   * @example
   *   //use string dot notation for keys
   *   get(obj, 'a.b.c') === 1;
   *
   *   //or use an array key
   *   get(obj, ['a', 'b', 'c']) === 1;
   *
   *   get(obj, 'a.b') === obj.a.b;
   *
   *   //returns undefined if the full key path does not exist and no default is specified
   *   get(obj, 'a.b.f') === undefined;
   *
   *   //optional third parameter for default if the full key in path is missing
   *   get(obj, 'a.b.f', 'foo') === 'foo';
   *
   *   //or if the key exists but the value is undefined
   *   get(obj, 'a.b.d', 'foo') === 'foo';
   *
   *   //Non-truthy defined values are still returned if they exist at the full keypath
   *   get(obj, 'a.b.e', 'foo') === null;
   *
   *   //undefined obj or key returns undefined, unless a default is supplied
   *   get(undefined, 'a.b.c') === undefined;
   *   get(undefined, 'a.b.c', 'foo') === 'foo';
   *   get(obj, undefined, 'foo') === 'foo';
   */
  deepGet: function (obj, key, def = null, p = 0, undef = undefined) {
    key = key.split ? key.split('.') : key;
    for (p = 0; p < key.length; p++) {
      obj = obj ? obj[key[p]] : undef;
    }
    return obj === undef ? def : obj;
  },

  /**
   * Bericht op logboek toevoegen
   *
   * @param text    - tekst bericht
   * @param level   - zie: common.errorLevel
   * @param titel   - fout titel
   * @param module  - module naam
   * @param popUp   - true/false, popup bericht
   */
  addMessage2Drawer(text, level, titel, module, popUp = false) {
    store.dispatch(
      "app/ADD_LOG_MESSAGE",
      new ErrorMessage(
        text,
        level,
        titel,
        module,
        popUp,
      )
    ).finally(() => {
      if (popUp) {
        console.log("POPUP:", text)
      }
    })
  },

  parseInfo2Tree(infoObject, maxDeep = 6, listObject = []) {
    if (infoObject instanceof Object) {
      for (const infoObjectElement of Object.keys(infoObject)) {
        if (maxDeep <= 0) {
          listObject.push(
            {
              id: 'Deep limit',
              name: '...',
            }
          )
          return listObject
        }
        if (infoObject[infoObjectElement] instanceof Object) {
          // console.log('Object item', infoObjectElement)

          listObject.push(
            {
              id: infoObjectElement,
              name: infoObjectElement,
              children: this.parseInfo2Tree(infoObject[infoObjectElement], maxDeep - 1),
            }
          )
        } else {
          listObject.push(
            {
              id: infoObjectElement,
              name: infoObjectElement + ': ' + infoObject[infoObjectElement],
            }
          )
        }
      }
    }
    return listObject
  },  // parseInfo2Tree

  prettyJSON: function (json) {
    if (json) {
      json = JSON.stringify(json, undefined, 4);
      json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>');
      return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match) {
        let cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
      });
    }
  }, // prettyJSON

  getFirstOfNull(data) {
    if (Array.isArray(data) && data.length > 0) {
      return data[0]
    } else {
      return null
    }
  },

  // Remove item van autocomplete lijst
  autocompleteRemove(id, selectedList) {
    // console.log("Autocomplete selected list:", selectedList)
    const index = selectedList.indexOf(id)
    if (index >= 0) selectedList.splice(index, 1)
  },

  deepCopy(data) {
    try {
      return JSON.parse(JSON.stringify(data))
    } catch (error) {
      console.error('deepCopy error:', error)
      return null
    }
  }
} // fn

export default {
  fn,
  ErrorMessage,
  errorTitels,
  errorLevels,
  errorIcons,
  errorColors,
  errorColorsDark,
  euroFilter,
};
