<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-toolbar
            :dense="appGuiCompact"
            flat
        >
<!--
 .d888888  dP                                                                          dP
d8'    88  88                                                                          88
88aaaaa88a 88d888b. .d8888b. 88d888b. 88d888b. .d8888b. 88d8b.d8b. .d8888b. 88d888b. d8888P
88     88  88'  `88 88'  `88 88'  `88 88'  `88 88ooood8 88'`88'`88 88ooood8 88'  `88   88
88     88  88.  .88 88.  .88 88    88 88    88 88.  ... 88  88  88 88.  ... 88    88   88
88     88  88Y8888' `88888P' dP    dP dP    dP `88888P' dP  dP  dP `88888P' dP    dP   dP

  dP                     dP dP
  88                     88 88
d8888P .d8888b. .d8888b. 88 88d888b. .d8888b. 88d888b.
  88   88'  `88 88'  `88 88 88'  `88 88'  `88 88'  `88
  88   88.  .88 88.  .88 88 88.  .88 88.  .88 88
  dP   `88888P' `88888P' dP 88Y8888' `88888P8 dP
-->
          <v-toolbar-title class="mr-2">Abonnement</v-toolbar-title>

<!--
       d8888          888    d8b           .d888                              d8b 888             888
      d88888          888    Y8P          d88P"                               Y8P 888             888
     d88P888          888                 888                                     888             888
    d88P 888  .d8888b 888888 888  .d88b.  888888       .d8888b  888  888  888 888 888888  .d8888b 88888b.
   d88P  888 d88P"    888    888 d8P  Y8b 888          88K      888  888  888 888 888    d88P"    888 "88b
  d88P   888 888      888    888 88888888 888          "Y8888b. 888  888  888 888 888    888      888  888
 d8888888888 Y88b.    Y88b.  888 Y8b.     888               X88 Y88b 888 d88P 888 Y88b.  Y88b.    888  888
d88P     888  "Y8888P  "Y888 888  "Y8888  888           88888P'  "Y8888888P"  888  "Y888  "Y8888P 888  888
-->
          <v-switch
              class="mx-4"
              v-model="aleenActief"
              hide-details
              inset
              label="Actief"
          ></v-switch>

          <v-divider
              class="mx-2"
              vertical
              inset
          ></v-divider>

          <!--
          888                                                888 888
888                                                888 888
888                                                888 888
888       .d88b.   .d88b.  .d8888b         8888b.  888 888  .d88b.  .d8888b
888      d8P  Y8b d8P  Y8b 88K                "88b 888 888 d8P  Y8b 88K
888      88888888 88888888 "Y8888b.       .d888888 888 888 88888888 "Y8888b.
888      Y8b.     Y8b.          X88       888  888 888 888 Y8b.          X88
88888888  "Y8888   "Y8888   88888P'       "Y888888 888 888  "Y8888   88888P'
          -->
          <v-tooltip
              open-delay="1000"
              bottom
          >
            <template v-slot:activator="{ on, attrs}">
              <v-btn
                  @click="getAboData"
                  :loading="inProgress"
                  :disabled="inProgress"
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  :large="!appGuiCompact"
              >
                <v-icon>mdi-database-refresh</v-icon>
              </v-btn>
            </template>
            <span>Lees alles opnieuw</span>
          </v-tooltip>
<!--
88888888888                                d8b    d8b
    888                                    Y8P    Y8P
    888
    888      .d88b.  888d888 88888b.d88b.  888   8888 88888b.
    888     d8P  Y8b 888P"   888 "888 "88b 888   "888 888 "88b
    888     88888888 888     888  888  888 888    888 888  888
    888     Y8b.     888     888  888  888 888    888 888  888
    888      "Y8888  888     888  888  888 888    888 888  888
                                                  888
                                                 d88P
                                               888P"
-->
          <v-menu
              rounded="b-xl"
              offset-y
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip
                  open-delay="1000"
                  bottom
              >
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                      class="white--text mx-4"
                      color="accent"
                      v-bind="attrs"
                      :large="!appGuiCompact"
                      v-on="{ ...tooltip, ...menu }"
                  >
                    <v-icon class="mr-3">mdi-calendar-clock</v-icon>
                    {{ selectedAboTermijn.name }}
                  </v-btn>
                </template>
                <span>Termijn</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item
                  v-for="item in termijnDropMenuItems"
                  :key="item.index"
                  link
                  @click="changeTermijn(item)"
              >
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-divider
              class="mx-2"
              vertical
              inset
          ></v-divider>

<!--
8888888b.           888                                     888                   888
888  "Y88b          888                                     888                   888
888    888          888                                     888                   888
888    888  8888b.  888888  .d88b.        .d8888b   .d88b.  888  .d88b.   .d8888b 888888
888    888     "88b 888    d8P  Y8b       88K      d8P  Y8b 888 d8P  Y8b d88P"    888
888    888 .d888888 888    88888888       "Y8888b. 88888888 888 88888888 888      888
888  .d88P 888  888 Y88b.  Y8b.                X88 Y8b.     888 Y8b.     Y88b.    Y88b.
8888888P"  "Y888888  "Y888  "Y8888         88888P'  "Y8888  888  "Y8888   "Y8888P  "Y888
-->
          <!--      menu -> PopUp Calender    -->
          <v-menu
              v-model="dateSelector"
              transition="fade-transition"
              offset-y
              min-width="250px"
              max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  class="mt-5 ml-1 shrink"
                  v-model="aboDate"
                  readonly
                  label="Factuur datum"
                  prepend-icon="mdi-calendar"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="aboDate"
                @input="dateSelector = false">
            </v-date-picker>
          </v-menu>
<!--
88888888888                   888                            888
    888                       888                            888
    888                       888                            888
    888      .d88b.  .d8888b  888888        .d88b.  88888b.  888 888  888
    888     d8P  Y8b 88K      888          d88""88b 888 "88b 888 888  888
    888     88888888 "Y8888b. 888          888  888 888  888 888 888  888
    888     Y8b.          X88 Y88b.        Y88..88P 888  888 888 Y88b 888
    888      "Y8888   88888P'  "Y888        "Y88P"  888  888 888  "Y88888
                                                                      888
                                                                 Y8b d88P
                                                                  "Y88P"
-->
          <v-switch
              class="mx-4"
              v-model="testOnly"
              hide-details
              inset
              label="Test"
          ></v-switch>
<!--
8888888888P                        888       888                        888      d8b
      d88P                         888       888                        888      Y8P
     d88P                          888       888                        888
    d88P     .d88b.  88888b.   .d88888       88888b.   .d88b.   .d88b.  888  888 888 88888b.   .d88b.
   d88P     d8P  Y8b 888 "88b d88" 888       888 "88b d88""88b d8P  Y8b 888 .88P 888 888 "88b d88P"88b
  d88P      88888888 888  888 888  888       888  888 888  888 88888888 888888K  888 888  888 888  888
 d88P       Y8b.     888  888 Y88b 888       888 d88P Y88..88P Y8b.     888 "88b 888 888  888 Y88b 888
d8888888888  "Y8888  888  888  "Y88888       88888P"   "Y88P"   "Y8888  888  888 888 888  888  "Y88888
                                                                                                   888
                                                                                              Y8b d88P
                                                                                               "Y88P"
-->
          <v-tooltip
              open-delay="1000"
              bottom
          >
            <template v-slot:activator="{ on, attrs}">
              <v-btn
                  @click="putAboZenden"
                  :disabled="inProgress || selectedAboTermijn.index === -1 || !filteredAbonnementen.length"
                  v-bind="attrs"
                  v-on="on"
                  fab
                  :x-large="!appGuiCompact"
                  :large="appGuiCompact"
                  color="secondary"
                  class="mx-2"
              >
                <v-icon
                    :large="!appGuiCompact"
                >mdi-credit-card-plus</v-icon>
              </v-btn>
            </template>
            <span>Zend boeking in SnelStart</span>
          </v-tooltip>

          <v-spacer></v-spacer>
<!--          <v-btn-->
<!--              small-->
<!--              @click="refreshToken"-->
<!--              class="mx-4"-->
<!--          >Refresh token-->
<!--          </v-btn>-->

<!--
8888888888P                   888                                                888      888
      d88P                    888                                                888      888
     d88P                     888                                                888      888
    d88P     .d88b.   .d88b.  888  888  .d88b.  88888b.        888  888  .d88b.  888  .d88888
   d88P     d88""88b d8P  Y8b 888 .88P d8P  Y8b 888 "88b       888  888 d8P  Y8b 888 d88" 888
  d88P      888  888 88888888 888888K  88888888 888  888       Y88  88P 88888888 888 888  888
 d88P       Y88..88P Y8b.     888 "88b Y8b.     888  888        Y8bd8P  Y8b.     888 Y88b 888
d8888888888  "Y88P"   "Y8888  888  888  "Y8888  888  888         Y88P    "Y8888  888  "Y88888
-->
          <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Zoeken"
              single-line
              hide-details
              clearable
              class="shrink"
          ></v-text-field>

        </v-toolbar>
      </v-card-title>

<!--
8888888b.                                                                     888888b.
888   Y88b                                                                    888  "88b
888    888                                                                    888  .88P
888   d88P 888d888  .d88b.   .d88b.  888d888  .d88b.  .d8888b  .d8888b        8888888K.   8888b.  888d888
8888888P"  888P"   d88""88b d88P"88b 888P"   d8P  Y8b 88K      88K            888  "Y88b     "88b 888P"
888        888     888  888 888  888 888     88888888 "Y8888b. "Y8888b.       888    888 .d888888 888
888        888     Y88..88P Y88b 888 888     Y8b.          X88      X88       888   d88P 888  888 888
888        888      "Y88P"   "Y88888 888      "Y8888   88888P'  88888P'       8888888P"  "Y888888 888
                                 888
                            Y8b d88P
                             "Y88P"
-->
      <v-progress-linear
          :active="isLoadProgress"
          background-opacity="0.3"
          height="3"
          :value="getProgressValue"
          :color="getProgressColor"
      ></v-progress-linear>

<!--
8888888b.           888                   888             888               888
888  "Y88b          888                   888             888               888
888    888          888                   888             888               888
888    888  8888b.  888888  8888b.        888888  8888b.  88888b.   .d88b.  888
888    888     "88b 888        "88b       888        "88b 888 "88b d8P  Y8b 888
888    888 .d888888 888    .d888888       888    .d888888 888  888 88888888 888
888  .d88P 888  888 Y88b.  888  888       Y88b.  888  888 888 d88P Y8b.     888
8888888P"  "Y888888  "Y888 "Y888888        "Y888 "Y888888 88888P"   "Y8888  888
-->
      <v-data-table
          v-model="selected"
          show-select
          class="elevation-3"
          :headers="headers"
          :items="filteredAbonnementen"
          :dense="appGuiCompact"
          :search="search"
          :items-per-page="25"
          :custom-filter="customFilter"
          :footer-props="{'items-per-page-options': [5, 15, 25, 100]}"
          single-expand
          :expanded.sync="dataExpanded"
          show-expand
          item-key="id"
      >
        <!--    for v-checkbox, it's input-value and @change    -->
        <!--    for v-simple-checkbox, it's value and @input    -->
        <!--        <template v-slot:item.data-table-select="{ isSelected, select }">-->
        <!--          <v-simple-checkbox :value="isSelected" @input="select($event)" :disabled="false"></v-simple-checkbox>-->
        <!--        </template>-->

        <!--    Totaal regel onder tabel  -->
        <template v-slot:[`body.append`]>
          <tr id="table-total">
            <th class="text-center">{{ selected.length }}</th>
            <th>{{ filteredAbonnementen.length }}</th>
            <th>{{ filteredAbonnementen.filter(item => (item.extra_velden.Actief || item.extra_velden.Actief === '1')).length }}</th>
            <th></th>
            <th></th>
<!--            <th></th>-->
            <th></th>
            <th class="text-right">
              {{
                filteredAbonnementen.reduce((som, value) => som + (parseFloat(value.artikel.verkoopprijs) || 0), 0) |
                    getEuro
              }}
            </th>
            <th class="text-right">
              {{
                filteredAbonnementen.reduce((som, value) => som + (parseFloat(value.extra_velden.Bedrag) || 0), 0) |
                    getEuro
              }}
            </th>
            <th class="text-left" @click="checkSelected">
              <div v-if="selected.length" class="d-inline-block">
                <v-icon>mdi-checkbox-marked</v-icon>
                {{
                  selected.reduce(
                      (som, value) => som + (parseFloat(value.extra_velden.Bedrag) || parseFloat(value.artikel.verkoopprijs)
                          || 0), 0
                  ) | getEuro
                }}
              </div>
              <div v-else>
                <v-icon>mdi-checkbox-blank-outline</v-icon>
                <span class="text-caption">Niet geselecteerd</span>
              </div>
            </th>
            <th class="text-right">Totaal:</th>
            <th>
              {{
                filteredAbonnementen.reduce(
                    (som, value) => som + (parseFloat(value.extra_velden.Bedrag) || parseFloat(value.artikel.verkoopprijs) ||
                        0), 0
                ) | getEuro
              }}
            </th>
            <th></th>
          </tr>
        </template>

        <!--    Ordernummer tag    -->
        <template v-slot:[`item.relatiecode`]="{ item }">
          <v-chip
              x-small
              v-model="item.relatiecode"
          >
            {{ item.relatiecode }}
          </v-chip>
        </template>

        <!--    Verzonden vinkje    -->
        <template v-slot:[`item.verzonden`]="{ item }">
          <v-simple-checkbox
              v-model="item.verzonden"
              disabled
              v-if="item.verzonden === true"
          >
          </v-simple-checkbox>
        </template>

        <!--    Abo geselecteerd    -->
        <template v-slot:[`item.selected`]="{ item }">
          <div>
            <v-checkbox v-model="item.selected" hide-details class="mt-0"></v-checkbox>
          </div>
        </template>

        <!--    Voorraad controle vinkje    -->
        <!--        <template v-slot:item.voorraad_controle="{ item }">-->
        <!--          <v-simple-checkbox v-model="item.voorraad_controle" disabled></v-simple-checkbox>-->
        <!--        </template>-->

        <!--    Abo is actief-->
        <template v-slot:[`item.extra_velden.Actief`]="{ item }">
          <v-simple-checkbox
              :value="item.extra_velden.Actief === '1'"
              disabled
          >
          </v-simple-checkbox>
        </template>

        <!--    Abo termijn  -->
        <template v-slot:[`item.extra_velden.Termijn`]="{ item }">
          <v-chip
              x-small
              :color="statusColors[item.extra_velden.Termijn]"
          >
            {{ item.extra_velden.Termijn | getAboTermijnNaam }}
          </v-chip>
        </template>

        <!--    Totaal price  -->
        <template v-slot:[`item.extra_velden.Bedrag`]="{ item }">
          {{ item.extra_velden.Bedrag | getEuro }}
        </template>

        <!--    Artikel price  -->
        <template v-slot:[`item.artikel.verkoopprijs`]="{ item }">
          {{ item.artikel.verkoopprijs | getEuro }}
        </template>

        <!--    Begin  -->
        <template v-slot:[`item.extra_velden.Begin`]="{ item }">
          {{ item.extra_velden.Begin | moment('calendar') }}
        </template>

        <!--    Eind  -->
        <template v-slot:[`item.extra_velden.Eind`]="{ item }">
          {{ item.extra_velden.Eind | moment('calendar') }}
        </template>

        <!--        <template v-slot:item.actions="{ item }">-->
        <!--          <v-icon-->
        <!--                  class="mr-2"-->
        <!--                  disabled-->
        <!--                  @click="viewItem(item)"-->
        <!--          >-->
        <!--            mdi-eye-->
        <!--          </v-icon>-->
        <!--        </template>-->

        <!--    "Meer info" block    -->
        <template v-slot:expanded-item="{ headers, item }">
          <td
              :colspan="headers.length"
          >
            <span
                class="heading"
            >
              Meer informatie over {{ item.nummer }}
            </span>
            <v-form v-model="formTest2" max-width="500px">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="item.telefoon" label="Telefoon"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="item.iban" label="IBAN"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="item.vestigings_postcode" label="Postcode"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="item.memo" label="Memo"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="item.extra_velden.Status" label="Status"></v-text-field>
                </v-col>
              </v-row>

              <!--      https://codepen.io/pen/?editors=1010    -->
            </v-form>
          </td>
        </template>

      </v-data-table>
    </v-card>

    <!--    <v-dialog-->
    <!--            v-model="dialog"-->
    <!--            width="500"-->
    <!--    >-->
    <!--      <template v-slot:activator="{ on }">-->
    <!--        <v-btn-->
    <!--                color="red lighten-2"-->
    <!--                dark-->
    <!--                small-->
    <!--                v-on="on"-->
    <!--        >-->
    <!--          Policy-->
    <!--        </v-btn>-->
    <!--      </template>-->

    <!--      <v-card>-->
    <!--        <v-card-title-->
    <!--                class="headline grey lighten-2"-->
    <!--                primary-title-->
    <!--        >-->
    <!--          Privacy Policy-->
    <!--        </v-card-title>-->

    <!--        <v-card-text>-->
    <!--          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore-->
    <!--          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo-->
    <!--          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla-->
    <!--          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id-->
    <!--          est laborum. Bla bla bla...-->
    <!--        </v-card-text>-->

    <!--        <v-divider></v-divider>-->

    <!--        <v-card-actions>-->
    <!--          <v-spacer></v-spacer>-->
    <!--          <v-btn-->
    <!--                  color="primary"-->
    <!--                  text-->
    <!--                  @click="dialog = false"-->
    <!--          >-->
    <!--            Close-->
    <!--          </v-btn>-->
    <!--        </v-card-actions>-->
    <!--      </v-card>-->
    <!--    </v-dialog>-->

    <!--    Message controle -->
    <v-snackbar
        v-model="snack.on"
        :timeout="snack.timeout"
        :color="snack.color"
        :top="snack.pos.v === 'top'"
        :bottom="snack.pos.v === 'bottom'"
        :right="snack.pos.h === 'right'"
        :left="snack.pos.h === 'left'"
    >
      {{ snack.text }}
      <v-btn text @click="snack.on = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
//
//      d88P                                 d8b          888          Y88b
//    d88P                                  Y8P          888           Y88b
//   d88P                                                888            Y88b
//  d88P          .d8888b   .d8888b 888d888 888 88888b.  888888          Y88b
//  Y88b          88K      d88P"    888P"   888 888 "88b 888             d88P
//   Y88b         "Y8888b. 888      888     888 888  888 888            d88P
//    Y88b             X88 Y88b.    888     888 888 d88P Y88b.         d88P
//     Y88b        88888P'  "Y8888P 888     888 88888P"   "Y888       d88P
//                                              888
//                                              888
//                                              888

import {mixin as VueTimers} from 'vue-timers'
import {mapGetters} from "vuex"
import common from "../utils/common";

// Vue.use(require('vue-moment'));

// GLOBAL sectie
// Euro filter voor geld formaat maken
const euroFilter = new Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
});

const statusColors = [
  'error',            // 0 Op maat
  'primary',          // 1 Maand    '#2087c8'
  'purple accent-1',  // 2
  'secondary',        // 3 Kwartaal '#1ead64'
  'brown',            // 4
  'light-blue',       // 5
  'orange',           // 6 Halfjaar
  'info',             // 7
  'green lighten-1',  // 8
  'green lighten-2',  // 9
  'green lighten-3',  // 10
  'green lighten-4',  // 11
  'teal',             // 12 Jaar
];

const termijnNamenLijst = [
  'op maat',      // 0
  'maand',        // 1
  '2',            // 2
  'kwartaal',     // 3
  '4',            // 4
  '5',            // 5
  'halfjaar',     // 6
  '7',            // 7
  '8',            // 8
  '9',            // 9
  '10',           // 10
  '11',           // 11
  'jaar',         // 12
];

export default {
  name: "abo-lijst",

  mixins: [VueTimers],
  // vue-timers module https://www.npmjs.com/package/vue-timers
  timers: {
    runTimerUpdate: {
      time: 60000,
      repeat: true,
      autostart: true,
    }
  },

//        888          888
//        888          888
//        888          888
//    .d88888  8888b.  888888  8888b.
//   d88" 888     "88b 888        "88b
//   888  888 .d888888 888    .d888888
//   Y88b 888 888  888 Y88b.  888  888
//    "Y88888 "Y888888  "Y888 "Y888888


  data() {
    return {
      // TEST DATA

      menuTermijn: null,

      // TEST DATA

      dialog: false,
      formTest2: true,
      sw_active: true,
      search: '',
      selected: [],
      // dark: false,
      drawer: true,
      dense: true,
      // Factuur datum
      aboDate: new Date().toISOString().substr(0, 10),  // substring(0, 10)
      dateSelector: false,
      datePickerExtended: false,
      showReadMessage: false,

      aleenActief: true,
      testOnly: true,

      // Snack (popup info block)
      snack: {
        on: false,
        color: '',
        text: '',
        timeout: 7000,
        pos:
            {
              v: 'top', // 'top' or 'bottom'
              h: '',    // 'left', 'right' or '' for center
            },
      },

      termijnDropMenuItems: [
        {name: '<Alles>', index: -1, value: '-1'},
        {name: 'Op maat', index: 0, value: '0'},
        {name: 'Maand', index: 1, value: '1'},
        {name: 'Kwartaal', index: 3, value: '3'},
        {name: 'Halfjaar', index: 6, value: '6'},
        {name: 'Jaar', index: 12, value: '12'},
      ],
      statusColors,

      selectedAboTermijn: {name: '<Alles>', index: -1, value: '-1'},

      // // Auto update button
      // autoUpdate: {
      //   isLoading: false,
      //   toggle: [],
      //   value: 1,
      // },

      dataExpanded: [],

      // Begin data voor Order model
      // tableData: [
      //   {
      //     relatiecode: 0,
      //     naam: "",
      //     vestigings_postcode: null,
      //     telefoon: null,
      //     email: null,
      //     memo: "",
      //     snelstart_id: 1,
      //     iban: "GB33BUKB20201555555555",
      //     "incasso_soort": 1,
      //     extra_velden: {
      //       Eind: null,
      //       Begin: "2000-01-01T00:00:00",
      //       Actief: true,
      //       Bedrag: 0.00,
      //       Status: null,
      //       Termijn: 3,
      //       ArtikelCodeExtra: null,
      //     },
      //     artikel: {
      //       actikelcode: null,
      //       verkoopprijs: null,
      //     }
      //   }
      // ],
      headers: [
        {text: 'Rel#', value: 'relatiecode'},
        {text: 'Actief', value: 'extra_velden.Actief'},
        // {text: 'Postcode', value: 'vestigings_postcode', soort: String},
        {text: 'Naam', value: 'naam'},
        {text: 'Status', value: 'extra_velden.Status'},
        // {text: 'Email', value: 'email'},
        // { text: 'Betalingskenmerk', value: 'betalingskenmerk' },
        {text: 'Code', value: 'extra_velden.ArtikelCodeExtra', align: 'right'},
        {text: 'Artikel prijs', value: 'artikel.verkoopprijs', align: 'right'},
        {text: 'Bedrag', value: 'extra_velden.Bedrag', align: 'right'},
        // { text: 'Memo', value: 'memo', align: 'right'},
        {text: 'Begin', value: 'extra_velden.Begin', align: 'left'},
        {text: 'Eind', value: 'extra_velden.Eind', align: 'left'},
        {text: 'Termijn', value: 'extra_velden.Termijn', align: 'left'},
        // {text: '', value: 'actions', sortable: false},
        {text: '', value: 'data-table-expand'},
      ],
      // API query naar doel administratie met betalingskenmerk filter
      bronData: [],

      // Verzondende facturen
      verzonden: [],

      // sound: new Audio()
    }
  },
  // data: sound:new Audio("http://s1download-universal-soundbank.com/wav/nudge.wav")
  // this.sound.play()
  // vue-timers module? https://www.npmjs.com/package/vue-timers

  created() {
    // this.$vuetify.theme.dark = false
  },
/**
     .d8888b.   .d88888b.  888b     d888 8888888b.  888     888 88888888888 8888888888 8888888b.
    d88P  Y88b d88P" "Y88b 8888b   d8888 888   Y88b 888     888     888     888        888  "Y88b
    888    888 888     888 88888b.d88888 888    888 888     888     888     888        888    888
    888        888     888 888Y88888P888 888   d88P 888     888     888     8888888    888    888
    888        888     888 888 Y888P 888 8888888P"  888     888     888     888        888    888
    888    888 888     888 888  Y8P  888 888        888     888     888     888        888    888
    Y88b  d88P Y88b. .d88P 888   "   888 888        Y88b. .d88P     888     888        888  .d88P
     "Y8888P"   "Y88888P"  888       888 888         "Y88888P"      888     8888888888 8888888P"
 */

  // COMPUTED
  computed: {
    ...mapGetters({
      ABOS: "snelstart/GET_ABO_PLUS_ARTIKEL",
      aboError: "snelstart/ABO_ERR",
      inProgress: "snelstart/ABO_PROGRESS",
      ss_GetArtikelenLijst: "snelstart/GET_ARTIKELEN",
      app_GetRootApiUri: "app/GET_ROOT_API_URI",
      selectedSnelStart: "user/GET_SELECTED_ADMINISTRATIE_ID",
      user_GetOperator: "user/GET_OPERATOR",
      user_GetCurrentOperators: "user/GET_CURRENT_OPERATORS",
      appGuiCompact: "app/GET_GUI_COMPACT",
    }),

    // ...mapState({
    //   aboReadErrorMessage: "snelstart/errorMessage",
    // }),

    isLoadProgress() {
      return this.$store.state.snelstart.progress
    },

    getProgressValue() {
      return this.ABOS.length * 100 / this.$store.state.snelstart.total
    },

    getProgressColor() {
      return this.aboError ? "error" : "light-blue"
    },

    isSelected() {
      console.log(this.selected);
      return this.selected.length > 0
    },

    isAutoUpdateOn() {
      return (this.autoUpdate.toggle.indexOf(this.autoUpdate.value) >= 0);
    },

    filteredAbonnementen() {
      // return this.tableData.filter(item => !this.sw_active || item.actief)
      const termijnSelectedValue = this.selectedAboTermijn.value;

      // console.log("Run Filter, actief =>", this.aleenActief, "Termijn =>", termijnSelectedindex)

      if (Array.isArray(this.ABOS)) {
        // console.log("filteredAbo =>", this.ABOS)

        return this.ABOS
          .filter(item => {
            if (item?.extra_velden === null) return false
            return item?.extra_velden?.Actief === '1' || !this.aleenActief
          })
          .filter(item => {
            if (item?.extra_velden === null) return false
            // == ipv === express gedaan
            return  item?.extra_velden?.Termijn === termijnSelectedValue || termijnSelectedValue === '-1'
          })
      } else {
        return []
      }
    },

    // TODO: naar User auth, als getter
    // getApiHeader() {
    //   return {
    //     headers: {
    //       Authorization: 'JWT ' + this.token
    //     }
    //   }
    // },
  },
/**
    888b     d888 8888888888 88888888888 888    888  .d88888b.  8888888b.   .d8888b.
    8888b   d8888 888            888     888    888 d88P" "Y88b 888  "Y88b d88P  Y88b
    88888b.d88888 888            888     888    888 888     888 888    888 Y88b.
    888Y88888P888 8888888        888     8888888888 888     888 888    888  "Y888b.
    888 Y888P 888 888            888     888    888 888     888 888    888     "Y88b.
    888  Y8P  888 888            888     888    888 888     888 888    888       "888
    888   "   888 888            888     888    888 Y88b. .d88P 888  .d88P Y88b  d88P
    888       888 8888888888     888     888    888  "Y88888P"  8888888P"   "Y8888P"
 */

  // METHODS
  methods: {
    // Update timer
    async runTimerUpdate() {
      // if (!this.autoUpdate.isLoading) {
      //   // Is Auto update on?
      //   // if (this.toggleAutoUpdate[0] === 1) {
      //   if (this.isAutoUpdateOn) {
      //     // Loading indicator on
      //     this.autoUpdate.isLoading = true;
      //     // Wait DOM and Run Order Update
      //     await this.$nextTick(() => this.getAboData())
      //   }
      // }
      console.log('Run: Timer');
    },
    // Zij panel bericht
    addLogBook(text, level, titel, popUp = true) {
      common.fn.addMessage2Drawer(text, level, titel, 'Abonnement', popUp)
    },

    // customFilter(value, search, item) {
    customFilter(value, search) {
      return value != null &&
          search != null &&
          value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
    },

    // Abo run
    putAboZenden: function () {
      let factuurDate = Date.parse(this.aboDate) // .format('YYYY-DD-YY')
      const cur_op = this.user_GetCurrentOperators // .filter(op => op.)

      console.log("Input =>", this.aboDate)
      console.log("Date() =>", factuurDate)
      console.log("Date().toISOString =>", new Date(factuurDate).toISOString().substr(0, 10))
      console.log("Current operator:", cur_op)

      if (Array.isArray(cur_op) && cur_op.length === 1) {
        const selectedOperator = cur_op[0].id

        console.log('OP =>', cur_op[0].id)
        console.log("URI => ", `${this.app_GetRootApiUri}api/operator/${selectedOperator}/`)

        this.$store.dispatch("user/REFRESH_TOKEN").then(() => {
          this.$store.dispatch(
              "snelstart/RUN_OPERATOR", {
                // uri: this.bronRunURI, // http://127.0.0.1:8000/api/operator/5/?bron_administratie=2&actief=true
                uri: `${this.app_GetRootApiUri}api/operator/${selectedOperator}/`,
                termijn: this.selectedAboTermijn.index,
                factuurDate,
                test: this.testOnly,
              }
          )
          this.showMessage("Taak is uitgevoerd op achtergrond...")
        })
      } else {
        this.showMessage("Taak ID is niet gevonden 😠...", "error")
      }

      // console.dir(selectedOperator)

    },

    // Lees ABOs
    getAboData: function () {
      // Can double for indirect call
      // this.autoUpdate.isLoading = false;

      console.log("Abonnement: getAboData() =>", this.selectedAboTermijn)

      // TODO: Resresh token in api.*, als 401 fout...
      this.$store.dispatch("user/REFRESH_TOKEN").then(() => {

        // this.$store.dispatch("snelstart/READ_ARTIKELEN", {uri: this.bronArtikelenURI})
        // const artikelAPI = `${this.rootURI}api/artikelen/?snelstart_id=${this.selectedSnelStart}`
        // this.$store.dispatch("snelstart/READ_ARTIKELEN", {uri: artikelAPI})
        this.$store.dispatch(
                  "snelstart/READ_ARTIKELEN_LIST",
                  {snelstart_id: this.selectedSnelStart}
              )

        // this.$store.dispatch("snelstart/READ_ABO", {uri: this.bronAboURI})
        const aboAPI = `${this.app_GetRootApiUri}api/abo/?snelstart_id=${this.selectedSnelStart}`
        this.$store.dispatch("snelstart/READ_ABO", {uri: aboAPI})

        this.$store.dispatch("user/READ_PROCESS_LIST")
      })

      // this.$store.dispatch("snelstart/READ_ARTIKELEN", {uri: this.bronArtikelenURI, auth: this.auth})
      // this.$store.dispatch("snelstart/READ_ABO", {uri: this.bronAboURI, auth: this.auth})
      // .finally(() => {
      //   setTimeout(() => this.autoUpdate.isLoading = false, 500);
      // })
    },

    refreshToken() {
      this.$store.dispatch("user/REFRESH_TOKEN")
    },

    showMessageError(text) {
      this.showMessage(text, 'error')
    },

    showMessage(text, color = 'success', posV = 'top', posH = '') {
      this.snack.color = color;
      this.snack.text = text;
      this.snack.pos.v = posV;
      this.snack.pos.h = posH;
      this.snack.on = true;
    },

    changeTermijn(termijn) {
      console.log("Abo: ChangeTermijn =>", termijn.name)
      this.selectedAboTermijn = termijn
      this.selected = []
    },

    checkSelected() {
      console.log("inProgress =>", this.inProgress)
      console.log("selectedAboTermijn.index =>", this.selectedAboTermijn.index)
      console.log("filteredAbonnementen.length =>", this.filteredAbonnementen.length)
    },

  }, // methods

  // WATCHERS
  watch: {
    aboError: function (newValue, oldValue) {

      console.log("new=>")
      console.dir(newValue)
      console.log("old=>", oldValue)
      if (newValue && newValue !== oldValue) {
        this.showMessageError(newValue)
      }
    } // aboError
  }, // watch
  /**
    888b     d888  .d88888b.  888     888 888b    888 88888888888 8888888888 8888888b.
    8888b   d8888 d88P" "Y88b 888     888 8888b   888     888     888        888  "Y88b
    88888b.d88888 888     888 888     888 88888b  888     888     888        888    888
    888Y88888P888 888     888 888     888 888Y88b 888     888     8888888    888    888
    888 Y888P 888 888     888 888     888 888 Y88b888     888     888        888    888
    888  Y8P  888 888     888 888     888 888  Y88888     888     888        888    888
    888   "   888 Y88b. .d88P Y88b. .d88P 888   Y8888     888     888        888  .d88P
    888       888  "Y88888P"   "Y88888P"  888    Y888     888     8888888888 8888888P"
   */
  // MOUNTED
  mounted: function () {
    // Get init data
    // this.$moment.locale('nl')
    if (Array.isArray(this.ABOS) && this.ABOS.length > 0) {
      // Heb al ABOS, skip GET...
    } else {
      this.getAboData();
    }

    // this.sound = new Audio("http://s1download-universal-soundbank.com/mp3/sounds/21298.mp3")
    // this.sound.play()

    // this.$store.dispatch("snelstart/READ_ABO", {uri: this.bronAboURI, auth: this.auth})
    // this.$store.dispatch("snelstart/READ_ARTIKELEN", {uri: this.bronArtikelenURI, auth: this.auth})

    // this.GET_ABO()
  },
  // mounted

  /**
   *
     .d888 d8b 888 888
    d88P"  Y8P 888 888
    888        888 888
    888888 888 888 888888  .d88b.  888d888 .d8888b
    888    888 888 888    d8P  Y8b 888P"   88K
    888    888 888 888    88888888 888     "Y8888b.
    888    888 888 Y88b.  Y8b.     888          X88
    888    888 888  "Y888  "Y8888  888      88888P'
   */

  // !! FILTERS HEBBEN GEEN 'this' CONTEXT !!
  filters: {
    // Convert cijfer naar Euro formaat: 1000.9500 -> '€ 1.000,95'
    getEuro: function (value) {
      value = parseFloat(value)
      return value && typeof (value) == 'number' ? euroFilter.format(value) : null
    },

    // Zoekt naam van termijn op index
    getAboTermijnNaam: function (value) {
      // console.log("getAboTermijn => ", value, termijnNamenLijst[value]);
      return termijnNamenLijst[value] || 'geen';
    }
  },  // filters
};  // export default

</script>

<style lang="scss" scoped>
#table-total th {
  font-size: 85%;
}
</style>
